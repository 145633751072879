.input{
border-radius: 320px;
width: 80%;
height:25px;
padding-left: 5px;
border: 1px solid #404952;
margin-top:15px ;
margin-bottom: 15px;
    
}

.input:focus{
    outline: none;
    border: 1px solid #33C0D1;
}

.input::placeholder{
    color: #404952;
    background-color: #fff;
    height: auto;
    /* width: auto; */
    position: absolute;
    top: -50%;
    WIDTH: 3%;
    /* display: flex; */
    text-align: center;
}

