.metricsHomepage {
    width: 98%;
    height: 97%;
    display: flex;
    flex-direction: column;
    margin-left: 1%;
    align-items: center;
    justify-content: center;
    position: relative;
    .addnewMetricsSection {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        .addNewMetricSect {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            flex-direction: row;
            .addNewMetricBtn {
                width: 20%;
                height: 75%;
                padding: 10px;
                background-color: #D4912D;
                border: none;
                border-radius: 35px;
                color: #fff;
                font-weight: 700;
                font-size: 25px;
                cursor: pointer;
            }
        }
        .viewMetricByTypeSect {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            flex-direction: row;
            .forecastTab {
                width: 15%;
                background: #ffffff;
                border-radius: 35px;
                height: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                color: #213038;
                padding: 10px;
                margin-right: 1%;
                cursor: pointer;
                .typeName {
                    font-weight: 300;
                    font-size: 25px;
                    line-height: 30.26px;
                }
                .typeValue {
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 30.26px;
                }
            }
        }
    }
    .availMetricsMapped {
        width: 99%;
        height: 70%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        .metricsHeaderSection {
            display: flex;
            align-items: center;
            padding: 5px;
            width: 99%;
            flex-direction: row;
            height: 9%;
            color: #213038;
            .typeMetricSec {
                width: 20%;
                margin-left: 1%;
                font-weight: 700;
                font-size: 20px;
                line-height: 24.2px;
            }
            .dateMetricSect {
                width: 20%;
                margin-left: 1%;
                font-weight: 700;
                font-size: 20px;
                line-height: 24.2px;
            }
        }
        .metricsSec {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 90%;
            overflow-y: scroll;
            align-items: center;
            .metircCard {
                width: 100%;
                height: 10%;
                background-color: #ffffff;
                display: flex;
                flex-direction: row;
                border-radius: 35px;
                margin-bottom: 1%;
                color: #213038;
                align-items: center;
                justify-content: space-between;
                .metricDetailsSect {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    padding: 5px;
                    justify-content: flex-start;
                    margin-left: 1%;
                    .metricType {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24.2px;
                        color: #213038;
                        width: 41.5%;
                    }
                    .metricDate {
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 24.2px;
                        color: #213038;
                    }
                }
                .actionsSection {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    margin-left: 1%;
                    padding: 5px;
                    img {
                        width: 20%;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .addNewMetricCard {
        position: absolute;
        width: 100%;
        height: 92%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 35px;
        left: -1.5px;
        align-items: center;
        justify-content: flex-start;
        .closeCardSect {
            position: absolute;
            right: 1.5%;
            top: 2%;
        }
        .dateAndTypeSect {
            width: 95%;
            height: 15%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            .changeStatusHover {
                width: 15%;
                display: flex;
                height: 45%;
                justify-content: center;
                border-radius: 35px;
                align-items: center;
                position: relative;
                padding-left: 15px;
                padding-right: 15px;
                margin-right: 1%;
                font-weight: 700;
                color: #213038;
                font-size: 20px;
                border: solid 1px #213038;
                position: relative;
                .placeHolder {
                    position: absolute;
                    bottom: 30%;
                    left: 20%;
                    width: 55%;
                    background-color: #fff;
                    padding-left: 5px;
                    font-weight: 300;
                }
                .estimatedCredsCard {
                    border: none;
                    background-color: transparent;
                    font-size: 25px;
                    width: 25%;
                    display: flex;
                    font-weight: 700;
                }
                .updateDeliveryDate {
                    border: none;
                    background-color: transparent;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 30.26px;
                    outline: none;
                }
                .statusChangePointer {
                    position: absolute;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 22px solid #ffffff;
                    z-index: 7;
                    top: 65%;
                    right: 40%;
                }
                .statusChanageContainer {
                    position: absolute;
                    background-color: #ffffff;
                    width: 200px;
                    height: 150px;
                    top: 115%;
                    right: -5%;
                    display: flex;
                    border-radius: 25px;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                    z-index: 5;
                    .statusChangeOptions {
                        width: 90%;
                        border-radius: 25px;
                        background-color: #eaeaea;
                        color: #213038;
                        height: 28%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        justify-content: center;

                        .statusValue {
                            font-size: 20px;
                            font-weight: 300;
                            color: #213038;
                            line-height: 24.2px;
                        }
                    }
                }
            }
            .departmentInput {
                position: relative;
                min-width: 268.22px;
                border: 1px solid #213038;
                height: 40px;
                justify-content: center;
                display: flex;
                align-items: center;
                border-radius: 35px;
                flex-direction: column;
                margin-top: 1%;
                margin-left: 1%;
                margin-right: 1%;
            }
            .monthTypeContainer {
                width: 20%;
                height: 50%;
                border: 1px solid #213038;
                display: flex;
                flex-direction: row;
                position: relative;
                color: #213038;
                border-radius: 35px;
                align-items: center;
                justify-content: center;
                .placeHolder {
                    position: absolute;
                    bottom: 40%;
                    left: 15%;
                    padding-left: 2%;
                    width: 40%;
                    background-color: #fff;
                    font-weight: 300;
                    font-size: 18px;
                }
                .values{
                    font-weight: 700;
                    font-size: 22px;
                }
            }
        }
        .categoryMetricsSection {
            width: 93%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            overflow-y: scroll;
            .incomeMetricCard {
                width: 100%;
                min-height: 465px;
                background-color: #fff;
                border-radius: 35px;
                border: solid 1px #213038;
                display: flex;
                flex-direction: column;
                color: #213038;
                align-items: center;
                font-weight: 700;
                font-size: 23px;
                margin-bottom: 1%;
                .metricNameSect {
                    display: flex;
                    justify-content: space-between;
                    width: 97%;
                    height: 5%;
                    align-content: center;
                    flex-direction: row;
                    align-items: center;
                    padding: 1%;
                    .totalAmountAndDropdown {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                .metricCategoriesSect {
                    width: 97%;
                    height: 75%;
                    padding: 1%;
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;
                    align-items: center;
                    .subCatsContainer {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 75%;
                        overflow-y: scroll !important;
                    }
                    .categoryHeaderSect {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        height: 15%;
                        align-items: center;
                        justify-content: space-between;
                        .totalAmountAndDropdown {
                            width: 10%;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                }
                .subCategoryContainer {
                    width: 100%;
                    display: flex;
                    flex-direction: rosw;
                    align-items: center;
                    height: 15%;
                    justify-content: space-between;
                    input {
                        border: none;
                        border-bottom: 2px solid #898989;
                        color: #213038;
                        font-weight: 700;
                        font-size: 20px;
                        outline: none;
                    }
                    input::placeholder {
                        color: #213038;
                        font-weight: 700;
                        font-size: 20px;
                    }
                    .totalAmountAndDropdown {
                        width: 17%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                button {
                    background-color: #D4912D;
                    width: 20%;
                    height: 15%;
                    border: none;
                    border-radius: 35px;
                    color: #ffffff;
                    cursor: pointer;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
        .btnSection {
            width: 100%;
            height: 8%;
            display: flex;
            align-items: center;
            justify-content: center;

            .saveBtn {
                background-color: #D4912D;
                width: 20%;
                height: 90%;
                border: none;
                border-radius: 35px;
                color: #ffffff;
                cursor: pointer;
                font-size: 25px;
                font-weight: 700;
            }
        }
    }
}
.addCredsMonthSelector {
    position: absolute;
    width: 270px;
    height: 250px;
    background-color: #FFFFFF;
    border-radius: 35px;
    z-index: 5;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    top: 1%;
}
.testing {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.incomeConatiner {
    width: 90%;
    height: 3%;
    display: flex;
    flex-direction: row;
    padding: 1%;
    border: 1px solid #213038;
    margin-bottom: 1%;
    border-radius: 35px;
    color: #213038;
    font-weight: 300;
    font-size: 20px;
    align-items: center;
    justify-content: space-between;

    input {
        width: 10%;
        border: none;
        border-bottom: 2px solid #898989;
        color: #213038;
        font-weight: 700;
        font-size: 20px;
        outline: none;
    }
    input::placeholder {
        color: #213038;
        font-weight: 700;
        font-size: 20px;
    }
}
.viewReportMetricsBtn{
    width: 15%;
    height: 90%;
    border-radius: 35px;
    border: none;
    background-color: #D4912D;
    color: #213038;
    font-weight: 300;
    font-size: 18px;
    cursor: pointer;
}