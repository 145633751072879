.investeeHomePage{
    display: flex;
    flex-direction: row;
    background-color: #E4E4E4;
}
.investeeBody{
    display: flex;
    flex-direction: column;
    width: 93%;
    height: 100%;
    padding: 10px;
}
.investeeSidebar{
    display: flex;
    flex-direction: column;
    width: 7%;
    height: 100%;
}
.hr{
    width: 100%;
    height: 2px;
    background-color: #213038;
    border: none;
}