.descriptionDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.redColor{
    color: rgb(255,0,0);
}
.greenColor{
    color: rgb(0,255,0);
}
.graphSummaryCard{
    margin-top: -38%;
    margin-left: 93%;
    width: 100%;
    height: 10%;
}

.periodDropDown{
    width: 100%;
    height: 50%;
    margin-left: 1%;
    border-radius: 14px;
    cursor: pointer;
}