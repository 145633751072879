.card{
    display:flex;
    position: relative;
    border-radius: 25px;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 15px;
    color: black;
}
