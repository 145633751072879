.usersCardWithUpdateButton{
    display: flex;
    width: 94%;
    border-radius: 5px;
    height: 50px;
    background-color: #373f46;
    margin-left: 1%;
    margin-bottom: 1%;
}
.AddUserDiv{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.UserPermsDiv{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.addNewUserButton{
    background-color: #81d6f1;
    color: white;
    border-radius: 5px;
    width: 30%;
    border: none;
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.addNewUserInputs{
    border-radius: 10px;
    width: 80%;
    height: 45px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}