.AlkemiaBriefingBtn {
    background: linear-gradient(to right, rgb(227, 54, 126), rgb(212, 89, 124));
    color: white;
    border-radius: 10px;
    width: 30%;
    border: none;
    height: 100%;
    margin-top: 15px;
    margin-left: 1%;
    margin-bottom: 15px;
    cursor: pointer;
}
