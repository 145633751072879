.dashboardTopBar {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: space-between;
    /* position: fixed; */

    /* align-items: center; */
}
.dashboardTopBarMobile {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: space-between;

    /* align-items: center; */
}
.sidebarItemLogOut {
    color: #fff;
    width: 150px;
    height: 25px;
    display: flex;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #33C0D1;
    margin-bottom: 15px;
    cursor: pointer;
    bottom: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.sidebarItemLogOut:hover {
    box-shadow: 0 8px 16px 0 rgba(40, 168, 166, 0.826);
}

.logOutButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.dashboardTopBar p {
    margin-right: 30px;
    
}

.dashboardContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    position: absolute;
    left: -3vw;
    top: 60px;
}

.dashboardContainerRight {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    position: absolute;
    left: -10vw;
    top: 60px;
}
.menuIcon{
    width:7%;
     height:50%;
     margin-top: 7px;
     margin-left: 7px;
}
.dashboardSidebar {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
    display: flex;
    flex-direction: column;
    width: 65vw;
    position: fixed;
    left: 0;
    top: 60px;
    transition: all 0.5s;
    z-index: 100;
    background-color: #404952;
    align-items: center;
}

.dashboardSidebarMobile {
    display: flex;
    flex-direction: column;
    width: 65vw;
    position: absolute;
    left: 0;
    top: 60px;
    transition: all 0.5s;
    z-index: 100;
    background-color: #404952;
    align-items: center;
}
.dashboardSidebarHiddenMobile {
    transition: all 0.5s;
    left: -65vw !important;
}
.hrStyle{
width: 70%;
box-shadow: 0 2px 3px 1px rgb(43, 169, 163);
}
.dashboard{
    width: 20vw;
    position: fixed;
    overflow-y: scroll;
    left: 0;
    top: 60px;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    background-color:#404952;
    }
.toggleBar{
    display: flex;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.dashboardSidebarHidden {
    width: 20vw;
    position: fixed;
    left: -20vw;
    top: 60px;
    transition: all 0.5s;
    z-index: 1000;
    background-color:#404952;
}
.sidebarToggleMobile {
    width: 10px;
    height: 15px;
    border: 1px solid white;
    position: fixed;
    cursor: pointer;
    top: 0px;
}
.sidebarAdminMobile{
    display: flex;
    flex-direction: column;
    align-items: center; 
    height: auto;
    width: 100%;
}
.sideBarCompanies {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sideBarCompaniesMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.sidebarItem {
    color: #fff;
    width: 75%;
    height: 25px;
    display: flex;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #33C0D1;
    margin-bottom: 15px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.sidebarItemSelected {
    background-color: #33c1d179;
}

.sidebarItem:hover {
    background-color: #5c8d96;
    box-shadow: 0 8px 16px 0 rgba(40, 168, 166, 0.826);
}

.dashboardContent {
    width: 75vw;
    overflow: hidden;
    /* position: fixed; */
    left: 20vw;
    transition: all 0.5s;
    top: 60px;
}

.dashboardContentFull {
    width: 98vw;
    overflow: hidden;
    /* position: fixed; */
    /* left: 5vw; */
    transition: all 0.5s;
    /* top: 60px; */
    
}

.dashboardContentMobile {
    width: 100%;
    overflow: hidden;

    left: 20vw;
    transition: all 0.5s;
    top: 60px;
}

.dashboardContentFullMobile {
    width: 90;
    overflow: hidden;
    left: 5vw;
    transition: all 0.5s;
    top: 60px;
}

.topBarImage {
    width: 12vw;
    height: 60px;
    left: 30px;

}

.topBarImageMobile {
    width: 17vw;
    height: 80px;
    display: flex;
    position: absolute;
    left: 80%;
    top: -4vw;
}

.cogUserSetting{
    height: 50%;
    width: 101%;
    margin-left: -30%;
    margin-top: 20%;
}