

.loginCard {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.Images{

   height:150px;
}

.loginInput{
    display: flex;
    flex-direction: row;
}

.formError{
    color: red;
    font-size: 14px;
    margin: 0px;
}
.buttonStyle{
    background-color:#81d6f1;
    width: 100px;

}
.forgotPasswordCard{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 60%;
    margin-top: -20%;
    background-color: rgb(40, 44, 52);
    border-radius: 25px;
    color: white;
}
.forgotPasswordHeader{
    width: 100%;
    height: 20%;
}
.forgotPasswordBody{
    width: 100%;
    height: 80%;
}


