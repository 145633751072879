.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Arial, sans-serif;
    color: #213038;
    width: 250px;
    height: 230px;
    border-radius: 25px;
}
.calendar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: #213038;
}
.calendar-header button {
    background-color: #D4912D;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 10px;
    width: 35px;
    height: 40px;
}
.calendar-header span {
    font-size: 24px;
    font-weight: bold;
    color: #213038;
}
.calendar-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.calendar-month {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 45px;
    margin: 5px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;;
    color: #213038;
}
.calendar-month:hover {
    background-color: #D4912D;
    color: #213038;
}
