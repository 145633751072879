.changePasswordCard{
    margin-left: 25%;
    width: 50%;
    height: 50%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
}

.changePasswordCardMobile{
    margin-left: 3%;
    width: 87%;
    height: 50%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
}