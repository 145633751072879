.userCardHeader {
  font-size: 20px;
  font-weight: bold;
}

.companyInnerSect {
  border: 1px solid #33C0D1;
  border-radius: 15px;
  /* width: 50px; */
}
.devDivCardsMore{
display: flex;
padding: 10px;
width: 65%;
height: 40%;
top: 45%;
  position: absolute;
}
.devDivCardsToDo{
display: flex;
padding: 10px;
width: 65%;
height: 40%;
position: absolute;
left: 50%;
top: 45%;
}
.devDivCardsMoreMobile{
display: flex;
width: 75%;
padding: 10px;
align-items: center;
position: absolute;
right: 1px;
}
.JobAtIndexDetails{
  width: 50%;
  display: flex;
  flex-direction: column;
}
.viewCompanyJob{
  position: absolute;
  top: 0%;
  right: 2%;
  width: 95%;
  height: 95%;
  display: flex;
  color: white;
  background-color: rgb(40, 44, 52);
  box-shadow: 1px 3px 4px 3px rgba(0, 0, 0, 0.604);
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.2s;
  z-index: 1000;
}
.devDivSection{
display: flex;
width: 100%;
padding: 10px;
align-items: center;
flex-direction: column;
}

.companyMobile{
display: flex;
flex-direction: column;
width: 100%;

}

.devDivCardsDoing{
display: flex;
  padding: 10px;
  width: 65%;
  height: 40%;
  top: 1%;
  position: absolute;
  left: 50%;
}
.devDivCardsDone{
display: flex;
padding: 10px;
width: 65%;
height: 40%;
position: absolute;
}

.sectionNumber1b {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionNumber3a {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sectionNumber3b {
  width: 660%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.setPStyleJob{
margin-bottom: 0px;
width: 100%;
right:3%;
}
.setPStyleForJob{
margin-bottom: 0px;
width: 100%;
left:3%;
}

.pageScroll{
overflow: scroll;
}
.homeCardsMobile {
display: flex;
width: 65%;
position: relative;
top: 35px;
border-radius: 25px;
background-color: #ffffff;
align-items: center;
padding: 15px;
color: black;
flex-direction: column !important;
color: rgb(220, 218, 218);
background-color: #404952;
transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
}

.homeCards {
display: flex;
width: 65%;
position: relative;
top: 5px;
border-radius: 25px;
background-color: #ffffff;
align-items: center;
padding: 15px;
color: black;
flex-direction: column !important;
color: rgb(220, 218, 218);
background-color: rgb(40, 44, 52);
box-shadow: 0 2px 3px 1px rgb(47, 135, 130);
transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
}
.designHomePageCards{
  display: flex;
    width: 48%;
    height: 50%;
    border-radius: 25px;
    align-items: center;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    margin:0.3%
}
.rejectionCard{
  display: flex;
    width: 85%;
    height: 70%;
    border-radius: 25px;
    align-items: center;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    margin: 0.3%;
}
.homeCardRequestedJob {
  display: flex;
  border-radius: 25px;
  width: 49%;
  margin-left: 0.3vw;
  background-color: #ffffff;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  color: black;
  flex-direction: column !important;
  color: rgb(220, 218, 218);
  background-color: rgb(40, 44, 52);
  box-shadow: 0 2px 3px 1px rgb(47, 135, 130);
  transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
  margin-bottom: 10px;
  }
  .designHomePage{
    display:flex;
    flex-direction: row;
    padding-left: 5vw;
  }

  .dueDateDiv{
margin-bottom: 0%;
  }

  .requestJobDiv{
    display: flex;
    flex-direction: column;
    top: 2%;
  }
.figureCards {
  margin-right: 5%;
  margin-left: 1%;
  height: 12vw;
  margin-bottom: 2vw;
  color: rgb(0 255 0 / 44%);
  background-color: rgb(40, 44, 52);
  box-shadow: 0 2px 3px 1px rgb(24, 133, 0);
  transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
}
.figureCardsMobile {
  overflow-x: scroll;
  display: flex;
  border-radius: 17px;
  width: 9%;
  margin-right: 3%;
  margin-top: 8%;
  padding-right: 23%;
  margin-bottom: 2%;
  color: rgb(0 255 0 / 44%);
  background-color: rgb(40, 44, 52);
  box-shadow: 0 2px 3px 1px rgb(24, 133, 0);
  justify-content: flex-start;
}
.figureCardsMobileNoData {
  margin-left: 1%;
  height: 30%;
  border-radius: 17px;
  width: 91%;
  margin-right: 3%;
  margin-top: 8%;
  align-items: center;
  margin-bottom: 2%;
  color: rgb(255,0,0);
  background-color: rgb(40, 44, 52);
  box-shadow: 0 2px 3px 1px rgb(255,0,0);
}
.homeCardsPrevJobs {
display: flex;
max-height: 40%;
width: 49%;
margin-bottom: 15px;
margin-top: 1px;
margin-right: 2%;
border-radius: 25px;
background-color: #ffffff;
align-items: center;
justify-content: flex-start;
padding: 15px;
color: black;
flex-direction: column !important;
color: rgb(220, 218, 218);
background-color: rgb(40, 44, 52);
box-shadow: 0 2px 3px 1px rgb(47, 135, 130);
transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
overflow: scroll;
}

::-webkit-scrollbar {
width: 0;
height: 0;
}
.prevCard{
position: inherit;
overflow: scroll;
height: 75%;
margin-top: -1vw;
}
.existingjobDiv{
display: flex;
justify-content: space-between;
background-color: #e1f4f6;
margin-top: 10px;
margin-bottom: 10px;
width: 95%;
height: 55px;
border-radius: 9px;
padding: 5px;
color: black;
}

.existingJobDivMobile{
display: flex;
justify-content: space-between;
background-color: #e1f4f6;
margin-top: 10px;
margin-bottom: 10px;
width: 71vw;
height: 49px;
border-radius: 9px;
padding: 5px;
color: black;
}
.existingJobDivMobile{
display: flex;
background-color: #e1f4f6;
border-radius: 9px;
color: black;
}

.existingjobDiv Button{
width: 50px;
}
.EditJobCardMobile{
  position: absolute;
  top: 0%;
  right: 2%;
  width: 80%;
  height: 65%;
  display: flex;
  color: white;
  background-color:rgb(40, 44, 52);
  box-shadow: 01px 03px 04px 03px rgba(0, 0, 0, 0.604);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.2s;
  z-index: 1000;
  }
.EditJobCard{
position: absolute;
top: 0%;
right: 2%;
width: 95%;
height: 95%;
display: flex;
color: white;
background-color:rgb(40, 44, 52);
box-shadow: 01px 03px 04px 03px rgba(0, 0, 0, 0.604);
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
transition: all 0.2s;
z-index: 1000;
}
.companyName {
  display: flex;
  position: absolute;
  right: 2%;
}
.updatejobButton {
background-color: #81d6f1;
color: white;
border-radius: 120px;
width: 30%;
position: absolute;
top: 19vw;
right: 7vw;
border: none;
height: 30px;
margin-top: 15px;
margin-bottom: 15px;
cursor: pointer;
}
.homeCardsCompany {
  display: flex;
  width: 50%;
  position: relative;
  height: 50%;
  top: 5px;
  margin-bottom: 10px;
  border-radius: 25px;
  background-color: #ffffff;
  align-items: center;
  padding: 15px;
  color: black;
  flex-direction: column !important;
  color: rgb(220, 218, 218);
  background-color: rgb(40, 44, 52);
  box-shadow: 0 2px 3px 1px rgb(47, 135, 130);
  transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
  }
  .textAreaDiv{
    display: flex;
    flex-direction: column;
  }

  .textAreaDiv{
    display: flex;
    width: 100%;
    align-items: center;
  }
  .gaugeSection{
    display: flex;
    flex-direction: row-reverse;
  }
  .homeCardsCompanyGreen {
    display: flex;
    width: 50%;
    position: relative;
    height: 50%;
    top: 5px;
    margin-bottom: 10px;
    border-radius: 25px;
    background-color: #ffffff;
    align-items: center;
    padding: 15px;
    color: black;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    box-shadow: 0 2px 3px 1px green;
    transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
    }
    .homeCardsCompanyBlue{
      display: flex;
      width: 50%;
      position: relative;
      height: 50%;
      top: 5px;
      margin-bottom: 10px;
      border-radius: 25px;
      background-color: #ffffff;
      align-items: center;
      padding: 15px;
      color: black;
      flex-direction: column !important;
      color: rgb(220, 218, 218);
      background-color: rgb(40, 44, 52);
      box-shadow: 0 2px 3px 1px rgb(3 45 90);
      transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
      }
    .homeCardsCompanyOrange {
      display: flex;
      width: 50%;
      position: relative;
      height: 50%;
      top: 5px;
      margin-bottom: 10px;
      border-radius: 25px;
      background-color: #ffffff;
      align-items: center;
      padding: 15px;
      color: black;
      flex-direction: column !important;
      color: rgb(220, 218, 218);
      background-color: rgb(40, 44, 52);
      box-shadow: 0 2px 3px 1px orange;
      transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
      }

      .homeCardsCompanyRed {
        display: flex;
        width: 50%;
        position: relative;
        height: 50%;
        top: 5px;
        margin-bottom: 10px;
        border-radius: 25px;
        background-color: #ffffff;
        align-items: center;
        padding: 15px;
        color: black;
        flex-direction: column !important;
        color: rgb(220, 218, 218);
        background-color: rgb(40, 44, 52);
        box-shadow: 0 2px 3px 1px red;
        transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
        }