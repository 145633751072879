.descriptionDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.redColor{
    color: rgb(255,0,0);
}
.greenColor{
    color: rgb(0,255,0);
}
#smallGraph{
    width: 100% !important;
    height: 90% !important;
}