.closeViewTaskCard {
    display: flex;
    flex-direction: row-reverse;
    top: 1%;
    height: 5%;
    position: absolute;
    width: 5;
    cursor: pointer;
    left: 95%;
}
.viewTaskInfoCard {
    display: flex;
    overflow-y: scroll;
    position: absolute;
    top: 10%;
    background-color: #404952;
    width: 50%;
    color: white;
    margin-left: 7%;
    height: 85%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.archiveButton{
    background-color:rgba(227,54,126,1);
    color: white;
    border-radius: 6px;
    width: 20%;
    border: none;
    margin-left: 1%;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.taskBottomContentDiv{
    margin-top: 3%;
    display: flex;
    width: 97%;
    overflow: scroll;
    margin-left: 1.5%;
}
.displayDepartmentCard{
    margin-left: 10%;
    width: 30%;
    margin-top: 3%;
    height: 9%;
    border-radius: 8px;
}

.collateralCard{
    width: 50%;
    overflow: scroll;
    margin-top: 3%;
    height: 9%;
    border-radius: 8px;
}

.rejectTaskButtonT{
    background-color: rgba(49,192,209,1);
    color: white;
    border-radius: 6px;
    width: 20%;
    border: none;
    margin-left: 1%;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.rejectTaskButton{
    background-color: rgba(227,54,126,1);
    color: white;
    border-radius: 6px;
    width: 20%;
    border: none;
    margin-left: 1%;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.dimensionsCards{
    width: 100%;
    border-radius: 8px;
    display: flex;
    border-radius: 10px;
    background-color: #ffffff;
    color: black;
    overflow: scroll;
}
.descCards{
    width: 100%;
    min-height: 50px;
    display: flex;
    border-radius: 10px;
    background-color: #ffffff;
    color: black;
    overflow: scroll;
}

.testAreaComment{
    width: 95%;
    height: 116px;
    border-radius: 8px;
    display: flex;
    border-radius: 10px;
    background-color: #ffffff;
    color: black;
}

.actualAndEstimatedCredits{
    border-radius: 9px;
    width: 67%;
    height: 40px;
    border: 1px solid #404952;
}
.updateStatusSelect{
        border-radius: 9px;
        width: 67%;
        height: 40px;
        border: 1px solid #404952;
    }

    .creditsCoin{
        height: 48%;
        margin-top: 5%;
        width: 29px;
    }
    .archivedTasksCard {
        display: flex;
        overflow-y: scroll;
        position: absolute;
        top: 10%;
        background-color: #404952;
        width: 35%;
        left: 37%;
        color: white;
        height: 85%;
        border-radius: 9px;
        box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
        flex-direction: column;
    }
    .unArchivebtn {
        display: flex;
        position: relative;
        background-color: rgb(255, 223, 0);
        border-radius: 10px;
        cursor: pointer;
        align-items: center;
        width: 15%;
        justify-content: center;
        padding: 10px;
        color: white;
    }
    .ArchivedTaskCards {
        display: flex;
        width: 99%;
        border-radius: 5px;
        height: 50px;
        background-color: #373f46;
        margin-bottom: 1%;
    }
    .dateInputCard{
        border-radius: 9px;
        width: 21%;
        height: 30px;
        padding-left: 10px;
        border: 1px solid #404952;
        margin-top: 15px;
        margin-bottom: 15px;
    }