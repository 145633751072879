.departmentAlkemiaBriefing {
  width: 98%;
  height: 97%;
  margin-left: 1%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  .tabSections {
    width: 100%;
    height: 12%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    .filterTasksCard {
      width: 25%;
      height: 35%;
      margin-right: 1%;
      border-radius: 35px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      background-color: #ffffff;
      color: #213038;
      p {
        margin-left: 1%;
        font-weight: 300;
        font-size: 25px;
        color: #213038;
        line-height: 30.26px;
      }
      b {
        font-weight: 700;
        font-size: 25px;
        color: #213038;
        line-height: 30.26px;
        margin-right: 1%;
      }
    }
    .selectedTaskCard {
      width: 25%;
      height: 35%;
      border-radius: 35px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      background-color: #213038;
      color: #ffffff;
      p {
        margin-left: 1%;
        font-weight: 300;
        font-size: 25px;
        color: #ffffff;
        line-height: 30.26px;
      }
      b {
        font-weight: 700;
        font-size: 25px;
        color: #ffffff;
        line-height: 30.26px;
        margin-right: 1%;
      }
    }
  }
  .title {
    width: 99%;
    height: 5%;
    display: flex;
    flex-direction: row;
    color: #213038;
    .companyNameField {
      margin-left: 3%;
      width: 30%;
      color: #213038;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.5px;
    }
    .titleField {
      width: 45%;
      color: #213038;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.5px;
    }
    .dateField {
      width: 18%;
      color: #213038;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.5px;
    }
  }
  .availableTasks {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 1%;
    .task {
      width: 99%;
      margin-top: 1%;
      height: 10%;
      display: flex;
      flex-direction: row;
      background-color: #ffffff;
      border-radius: 35px;
      align-items: center;
      color: black;
      .companyNameField {
        width: 30%;
        margin-left: 3%;
        color: #213038;
        font-size: 25px;
        font-weight: 700;
        line-height: 30.26px;
      }
      .titleField {
        width: 45%;
        color: #213038;
        font-size: 25px;
        font-weight: 300;
        line-height: 30.26px;
      }
      .dateField {
        width: 18%;
        color: #213038;
        font-size: 25px;
        font-weight: 300;
        line-height: 30.26px;
      }
      .archiveSection {
        width: 2%;
        margin-right: 15px;
      }
    }
  }
  .addMeetingContainer {
    background-color: #ffffff;
    aspect-ratio: 1 / 1;
    border-radius: 35px;
    width: 3.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
    bottom: 1%;
    right: 0.5%;
  }
  .viewTask {
    width: 90%;
    height: 90%;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    .closeCard {
      position: absolute;
      right: 1%;
      top: 2%;
      z-index: 5;
    }
    .taskNameAndCredits {
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 1%;
      .compName {
        font-weight: 700;
        font-size: 30px;
        line-height: 36.31px;
        color: #213038;
        width: 65%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .remsiningCreditsSection {
        background-color: #eaeaea;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 15%;
        height: 30%;
        border-radius: 35px;
        padding-right: 4px;
        .remainingCreds {
          font-weight: 300;
          font-size: 20px;
          line-height: 24.2px;
          color: #213038;
        }
        .numOfRemCreds {
          background-color: #efb86f;
          width: 20%;
          aspect-ratio: 1 / 1;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          border-radius: 35px;
        }
      }
    }
    .detailsContainer {
      width: 100%;
      height: 20%;
      display: flex;
      flex-direction: column;
      .nameSection {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
      }
      .categorySec {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
      }
    }

    .descriptionAndActions {
      width: 100%;
      height: 70%;
      display: flex;
      flex-direction: row;
      .descriptionSection {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .descriptionTitle {
          font-weight: 900;
          font-size: 15px;
          line-height: 18.15px;
          color: #213038;
        }
        .descriptionValue {
          font-weight: 300;
          font-size: 20px;
          line-height: 24.2px;
          color: #213038;
          display: flex;
          margin-top: 0px;
          margin-left: 20px;
          width: 90%;
        }
      }
      .actionSection {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1%;
        .actionValueCards {
          background: #eaeaea;
          width: 95%;
          display: flex;
          height: 12%;
          justify-content: space-between;
          border-radius: 35px;
          align-items: center;
          padding: unset;
          padding-left: 10px;
          padding-right: 3px;
          margin-top: 1%;

          .addCreditsSection {
            height: 85%;
            aspect-ratio: 1 / 1;
            background-color: #efb86f;
            border-radius: 35px;
            font-weight: 700;
            font-size: 25px;
            color: #213038;
            text-align: center;
            line-height: 30.26px;
            border: none;
            text-align: center;
            outline: none;
            .moreCreditsInfo {
              font-size: 25px;
              font-weight: 700;
              color: #213038;
              line-height: 30.26px;
            }
            .creditsInputValue {
              display: flex;
              border: none;
              background-color: transparent;
              width: 90%;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
            }
          }
          .categoryCArds {
            font-weight: 300;
            font-size: 25px;
            line-height: 30.26px;
            color: #000000;
          }
          .estimatedCredsCard {
            border: none;
            background-color: transparent;
            font-size: 25px;
            width: 25%;
            display: flex;
            font-weight: 700;
          }
        }
        .changeStatusHover {
          width: 25%;
          display: flex;
          height: 85%;
          justify-content: center;
          border-radius: 35px;
          align-items: center;
          background-color: #efb86f;
          position: relative;
          padding-left: 15px;
          padding-right: 15px;
          .estimatedCredsCard {
            border: none;
            background-color: transparent;
            font-size: 25px;
            width: 25%;
            display: flex;
            font-weight: 700;
          }
          .updateDeliveryDate {
            border: none;
            background-color: transparent;
            font-size: 18px;
            font-weight: 700;
            line-height: 30.26px;
            outline: none;
          }
          .statusChangePointer {
            position: absolute;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 22px solid #ffffff;
            z-index: 7;
            top: 65%;
            right: 40%;
          }
          .statusChanageContainer {
            position: absolute;
            background-color: #ffffff;
            width: 200px;
            height: 150px;
            top: 115%;
            right: -5%;
            display: flex;
            border-radius: 25px;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            z-index: 5;
            .statusChangeOptions {
              width: 90%;
              border-radius: 25px;
              background-color: #eaeaea;
              color: #213038;
              height: 28%;
              display: flex;
              align-items: center;
              cursor: pointer;
              justify-content: center;
              .statusValue {
                font-size: 20px;
                font-weight: 300;
                color: #213038;
                line-height: 24.2px;
              }
            }
          }
        }
      }
    }
  }
  .viewTasksCard {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 90%;
    left: 7.5%;
    border-radius: 35px;
    top: 5%;
    overflow: scroll;
    color: #213038;
    height: 90%;
    .closeViewTaskCArd {
      position: absolute;
      top: 3%;
      right: 2%;
      cursor: pointer;
      z-index: 5;
    }
    .companyDetails {
      width: 97%;
      height: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: start;
      justify-content: space-between;

      .selectedCompName {
        margin-left: 1%;
        font-weight: 700;
        color: #213038;
        font-size: 30px;
        line-height: 36.31px;
      }

      .remCredsContainer {
        background-color: #eaeaea;
        min-width: 23%;
        border-radius: 35px;
        padding-left: 15px;
        padding-right: 5px;
        display: flex;
        height: 90%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .remCredsTitle {
          font-weight: 300;
          color: #213038;
          font-size: 20px;
          line-height: 24.2px;
        }
        .credsContainer {
          background-color: #efb86f;
          display: flex;
          border-radius: 35px;
          aspect-ratio: 1 / 1;
          height: 90%;
          justify-content: center;
          align-content: center;
          align-items: center;
        }
      }
    }

    .taskDetails {
      width: 100%;
      display: flex;
      margin-top: 1%;
      flex-direction: row;
      .taskNameInput {
        width: 50%;
        display: flex;
        margin-left: 1%;
        margin-right: 1%;
      }
    }

    .taskCategoryDetails {
      width: 100%;
      display: flex;
      margin-top: 1%;
      flex-direction: row;
      .platFormSectionsInputs {
        width: 33%;
        margin-left: 1%;
        margin-right: auto;
      }
    }

    .taskDescription {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 60%;

      .descriptionSection {
        width: 50%;

        .titleLabel {
          margin-left: 2%;
          color: #213938;
          line-height: 18.15px;
          font-weight: 900;
          font-size: 15px;
          margin-bottom: 0%;
        }

        .titlleValue {
          margin-left: 6%;
          width: 90%;
          font-size: 20px;
          font-weight: 300;
          margin-top: 0px;
          line-height: 24.2px;
        }
      }
      .requestedActionsSection {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .acceptRejectBtn {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
          height: 70%;
          .acceptRequestedTask {
            width: 45%;
            height: 20%;
            border: none;
            border-radius: 35px;
            color: #ffffff;
            margin-bottom: 1%;
            font-size: 25px;
            font-weight: 700;
            display: flex;
            line-height: 42.36px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .credsXCard {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 5px;
          align-items: center;
          width: 95%;
          background-color: #eaeaea;
          border-radius: 35px;
          height: 15%;
          padding-bottom: unset;
          padding-top: unset;
          padding-left: 10px;
          .updateDeliveryDate {
            width: 30%;
            background-color: #efb86f;
            height: 90%;
            border-radius: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            border: none;
            font-size: 18px;
            color: #213038;
            line-height: 30.26px;
            .deliveryDateSelector {
              border: none;
              outline: none;
              background-color: transparent;
              color: #213038;
              font-size: 17px;
              font-weight: 700;
              line-height: 30.26px;
            }
          }
          .updateEstimatedCreds {
            height: 85%;
            aspect-ratio: 1 / 1;
            background-color: #efb86f;
            border-radius: 35px;
            font-weight: 700;
            font-size: 25px;
            color: #213038;
            text-align: center;
            line-height: 30.26px;
            border: none;
            text-align: center;
            outline: none;
          }
        }
      }
      .creditsAndDeliverySection {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .actionValueCards {
          background: #eaeaea;
          width: 95%;
          display: flex;
          height: 15%;
          justify-content: space-between;
          border-radius: 35px;
          align-items: center;
          padding: unset;
          padding-left: 10px;
          padding-right: 5px;
          margin-top: 1%;
          .Creds-Sec {
            width: 25%;
            background-color: pink;
          }

          .categoryCArds {
            font-weight: 300;
            font-size: 25px;
            line-height: 30.26px;
            color: #000000;
          }
          .credsContainer {
            width: 17%;
            height: 95%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .viewCreditsSection {
              height: 90%;
              aspect-ratio: 1 / 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: #efb86f;
              border-radius: 35px;
              .moreCreditsInfo {
                font-size: 25px;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
              }
              .creditsInputValue {
                display: flex;
                border: none;
                background-color: transparent;
                width: 90%;
                font-size: 25px;
                font-weight: 700;
                line-height: 30.26px;
              }
            }
            .addCreditsSection {
              height: 90%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: #efb86f;
              border-radius: 35px;
              border: none;
              aspect-ratio: 1 / 1;
              outline: none;
              text-align: center;
              font-size: 25px;
              color: #213038;
              line-height: 42.26px;
              font-weight: 700;
              .moreCreditsInfo {
                font-size: 25px;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
              }
              .creditsInputValue {
                display: flex;
                border: none;
                background-color: transparent;
                width: 90%;
                font-size: 25px;
                font-weight: 700;
                line-height: 30.26px;
              }
              .creditsUsedValue {
                width: 60%;
                border: none;
                display: flex;
                align-self: center;
                color: #213038;
                font-size: 25px;
                font-weight: 700;
                line-height: 30.26px;
                background-color: transparent;
                margin-left: 25%;
              }
            }
          }
        }
        .changeStatusHover {
          width: 30%;
          display: flex;
          height: 85%;
          justify-content: center;
          border-radius: 35px;
          align-items: center;
          background-color: #efb86f;
          position: relative;
          .estimatedCredsCard {
            border: none;
            background-color: transparent;
            font-size: 25px;
            width: 25%;
            display: flex;
            font-weight: 700;
          }
          .statusValue {
            font-size: 25px;
            font-weight: 700;
            line-height: 30.26px;
            color: #213038;
          }
          .updateDeliveryDate {
            border: none;
            background-color: transparent;
            font-size: 18px;
            font-weight: 700;
            line-height: 30.26px;
            outline: none;
            width: 85%;
          }
          .statusChangePointer {
            position: absolute;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 22px solid #ffffff;
            z-index: 7;
            top: 65%;
            right: 40%;
          }
          .statusChanageContainer {
            position: absolute;
            background-color: #ffffff;
            width: 200px;
            height: 150px;
            top: 115%;
            right: -5%;
            display: flex;
            border-radius: 25px;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            z-index: 5;
            .statusChangeOptions {
              width: 90%;
              border-radius: 25px;
              background-color: #eaeaea;
              color: #213038;
              height: 28%;
              display: flex;
              align-items: center;
              cursor: pointer;
              justify-content: center;
              .statusValue {
                font-size: 20px;
                font-weight: 300;
                color: #213038;
                line-height: 24.2px;
              }
            }
          }
        }
        .totalCredsCard {
          background-color: #eaeaea;
          border-radius: 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: unset;
          height: 15%;
          padding-left: 15px;
          margin-top: 1%;
          padding-right: 5px;
          width: 96%;
          color: #213038;

          .cardLabel {
            font-weight: 300;
            font-size: 20px;
            line-height: 30.26px;
            color: #000000;
          }
          .TotalCredsContainer {
            background-color: #efb86f;
            border-radius: 35px;
            display: flex;
            aspect-ratio: 1 / 1;
            height: 85%;
            justify-content: center;
            align-items: center;
            .statusValue {
              color: #213038;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
              align-self: center;
            }
          }
          .cardValue {
            background-color: #efb86f;
            border-radius: 35px;
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
            height: 90%;
            width: 25%;
            justify-content: center;
            align-items: center;
            .statusValue {
              color: #213038;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
              align-self: center;
            }
          }
        }
        .updateTaskStatus {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 50%;
          .btnSubmitTask {
            width: 45%;
            border: none;
            border-radius: 35px;
            color: #ffffff;
            background-color: #d5922d;
            font-size: 25px;
            font-weight: 700;
            line-height: 42.36px;
            height: 50px;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            margin-bottom: -15%;
          }
        }
        .archiveBtnSection {
          display: flex;
          height: 50%;
          justify-content: center;
          align-items: flex-end;
          .archiveBtn {
            width: 35%;
            height: 25%;
            border: none;
            cursor: pointer;
            border-radius: 35px;
            margin-bottom: 5%;
            color: #ffffff;
            background-color: #d5922d;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnValue {
              font-weight: 700;
              color: #ffffff;
              line-height: 42.36px;
              font-size: 35px;
            }
          }
        }
      }
    }
  }
  .view-ArchiveHeader {
    display: flex;
    height: 10%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
  .filterTasksCard {
    width: 25%;
    height: 35%;
    margin-right: 1%;
    border-radius: 35px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: #ffffff;
    color: #213038;
    p {
      margin-left: 1%;
      font-weight: 300;
      font-size: 25px;
      color: #213038;
      line-height: 30.26px;
    }
    b {
      font-weight: 700;
      font-size: 25px;
      color: #213038;
      line-height: 30.26px;
      margin-right: 1%;
    }
  }
  .viewArchive-taskHeaders {
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    color: #213038;
    align-items: center;
    margin-top: 1%;
    .companyNameField {
      margin-left: 3%;
      width: 28%;
      color: #213038;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.5px;
    }
    .titleField {
      width: 28%;
      color: #213038;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.5px;
    }
    .taskStatusField {
      width: 17%;
      color: #213038;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.5px;
    }
    .taskDateField {
      width: 18%;
      color: #213038;
      font-size: 20px;
      font-weight: 700;
      line-height: 24.5px;
    }
  }
  .mappedArchived-tasks {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 91%;
    overflow: scroll;
    .archived-tasks {
      margin-top: 1%;
      height: 5%;
      border-radius: 35px;
      margin-left: 1%;
      .companyNameValue {
        margin-left: 2%;
        width: 30%;
        font-size: 25px;
        font-weight: 700;
        line-height: 30.26px;
      }
      .titleValue {
        width: 30%;
        font-size: 25px;
        font-weight: 300;
        line-height: 30.26px;
      }
      .taskStatusValue {
        width: 18%;
        line-height: 30.26px;
        font-size: 25px;
        font-weight: 300;
      }
      .taskDateValue {
        width: 18%;
        line-height: 30.26px;
        font-size: 25px;
        font-weight: 300;
      }
    }
  }
  .viewSelectedTask {
    position: absolute;
    width: 90%;
    height: 95%;
    left: 7.5%;
    display: flex;
    flex-direction: column;
    color: #213038;
    align-items: center;
    justify-content: center;
    top: 1%;
    .closeCard {
      position: absolute;
      right: 1%;
      top: 1.5%;
    }
    .nameAndCredsSection {
      display: flex;
      width: 100%;
      height: 10%;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-top: 1.3%;
      .compName {
        font-weight: 700;
        font-size: 30px;
        line-height: 36.31px;
        color: #213038;
      }
      .remCredsContainer {
        background-color: #eaeaea;
        min-width: 23%;
        border-radius: 35px;
        padding-left: 15px;
        padding-right: 5px;
        display: flex;
        height: 90%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .remCredsTitle {
          font-weight: 300;
          color: #213038;
          font-size: 20px;
          line-height: 24.2px;
        }
        .credsContainer {
          background-color: #efb86f;
          display: flex;
          border-radius: 35px;
          aspect-ratio: 1 / 1;
          height: 90%;
          justify-content: center;
          align-content: center;
          align-items: center;
        }
      }
    }
    .taskNameSection {
      display: flex;
      width: 100%;
      height: 10%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-top: 1%;
      .taskInput {
        position: relative;
        width: 33.3%;
        border: 1px solid #213038;
        height: 40px;
        justify-content: center;
        border-radius: 35px;
        margin-left: 1%;
        display: flex;
      }
    }
    .categorySection {
      display: flex;
      width: 100%;
      height: 10%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-top: 0.5%;
      .taskInput {
        position: relative;
        width: 33.3%;
        border: 1px solid #213038;
        height: 40px;
        justify-content: center;
        border-radius: 35px;
        /* margin-top: 1.1%; */
        margin-left: 1%;
        display: flex;
      }
    }
    .taskActionSection {
      display: flex;
      width: 100%;
      height: 65%;
      flex-direction: row;
      .descBar {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .titleLabel {
          margin-left: 2%;
          color: #213938;
          line-height: 18.15px;
          font-weight: 900;
          font-size: 15px;
          margin-bottom: 0%;
        }
        .titlleValue {
          margin-left: 6%;
          width: 90%;
          font-size: 20px;
          font-weight: 300;
          margin-top: 0px;
          line-height: 24.2px;
          word-wrap: break-word;
        }
      }
      .ActionBar {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .actionValueCards {
          background: #eaeaea;
          width: 95%;
          display: flex;
          height: 12%;
          justify-content: space-between;
          border-radius: 35px;
          align-items: center;
          padding: unset;
          padding-left: 10px;
          padding-right: 5px;
          margin-top: 1%;
          .addCreditsSection {
            height: 85%;
            aspect-ratio: 1 / 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #efb86f;
            border-radius: 35px;
            .moreCreditsInfo {
              font-size: 25px;
              font-weight: 700;
              color: #213038;
              line-height: 30.26px;
            }
            .creditsInputValue {
              display: flex;
              border: none;
              background-color: transparent;
              width: 90%;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
            }
          }
          .categoryCArds {
            font-weight: 300;
            font-size: 25px;
            line-height: 30.26px;
            color: #000000;
          }
          .estimatedCredsCard {
            border: none;
            background-color: transparent;
            font-size: 25px;
            width: 25%;
            display: flex;
            font-weight: 700;
          }
          .credsContainer {
            width: 20%;
            height: 95%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .viewCreditsSection {
              height: 90%;
              aspect-ratio: 1 / 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: #efb86f;
              border-radius: 35px;
              .moreCreditsInfo {
                font-size: 25px;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
              }
              .creditsInputValue {
                display: flex;
                border: none;
                background-color: transparent;
                width: 90%;
                font-size: 25px;
                font-weight: 700;
                line-height: 30.26px;
              }
            }
            .addCreditsSection {
              height: 90%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: #efb86f;
              border-radius: 35px;
              border: none;
              aspect-ratio: 1 / 1;
              outline: none;
              text-align: center;
              font-size: 25px;
              color: #213038;
              line-height: 42.26px;
              font-weight: 700;
              .moreCreditsInfo {
                font-size: 25px;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
              }
              .creditsInputValue {
                display: flex;
                border: none;
                background-color: transparent;
                width: 90%;
                font-size: 25px;
                font-weight: 700;
                line-height: 30.26px;
              }
              .creditsUsedValue {
                width: 60%;
                border: none;
                display: flex;
                align-self: center;
                color: #213038;
                font-size: 25px;
                font-weight: 700;
                line-height: 30.26px;
                background-color: transparent;
                margin-left: 25%;
              }
            }
          }
          .changeStatusHover {
            width: 30%;
            display: flex;
            height: 85%;
            justify-content: center;
            border-radius: 35px;
            align-items: center;
            background-color: #efb86f;
            position: relative;
            .estimatedCredsCard {
              border: none;
              background-color: transparent;
              font-size: 25px;
              width: 25%;
              display: flex;
              font-weight: 700;
            }
            .statusValue {
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
              color: #213038;
            }
            .updateDeliveryDate {
              border: none;
              background-color: transparent;
              font-size: 18px;
              font-weight: 700;
              line-height: 30.26px;
              outline: none;
              width: 85%;
            }
            .addCredsMonthSelector {
              position: absolute;
              width: 270px;
              height: 250px;
              background-color: #FFFFFF;
              border-radius: 35px;
              z-index: 5;
              right: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              top: 1%;
            }
            .statusChangePointer {
              position: absolute;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-bottom: 22px solid #ffffff;
              z-index: 7;
              top: 65%;
              right: 40%;
            }
            .statusChanageContainer {
              position: absolute;
              background-color: #ffffff;
              width: 200px;
              height: 150px;
              top: 115%;
              right: -5%;
              display: flex;
              border-radius: 25px;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
              z-index: 5;
              .statusChangeOptions {
                width: 90%;
                border-radius: 25px;
                background-color: #eaeaea;
                color: #213038;
                height: 28%;
                display: flex;
                align-items: center;
                cursor: pointer;
                justify-content: center;
                .statusValue {
                  font-size: 20px;
                  font-weight: 300;
                  color: #213038;
                  line-height: 24.2px;
                }
              }
            }
          }
          .updateTaskStatus {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 50%;
            .btnSubmitTask {
              width: 45%;
              border: none;
              border-radius: 35px;
              color: #ffffff;
              background-color: #d5922d;
              font-size: 25px;
              font-weight: 700;
              line-height: 42.36px;
              height: 50px;
              display: flex;
              align-items: center;
              cursor: pointer;
              justify-content: center;
              margin-bottom: -15%;
            }
          }
        }
        .acceptRejectBtn {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
          height: 70%;
          .acceptRequestedTask {
            width: 45%;
            height: 20%;
            border: none;
            border-radius: 35px;
            color: #ffffff;
            margin-bottom: 1%;
            font-size: 25px;
            font-weight: 700;
            display: flex;
            line-height: 42.36px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .credsXCard {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 5px;
          align-items: center;
          width: 95%;
          background-color: #eaeaea;
          border-radius: 35px;
          height: 15%;
          padding-bottom: unset;
          padding-top: unset;
          padding-left: 10px;
          .updateDeliveryDate {
            width: 30%;
            background-color: #efb86f;
            height: 90%;
            border-radius: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            border: none;
            font-size: 18px;
            color: #213038;
            line-height: 30.26px;
            .deliveryDateSelector {
              border: none;
              outline: none;
              background-color: transparent;
              color: #213038;
              font-size: 17px;
              font-weight: 700;
              line-height: 30.26px;
            }
          }
          .updateEstimatedCreds {
            height: 85%;
            aspect-ratio: 1 / 1;
            background-color: #efb86f;
            border-radius: 35px;
            font-weight: 700;
            font-size: 25px;
            color: #213038;
            text-align: center;
            line-height: 30.26px;
            border: none;
            text-align: center;
            outline: none;
          }
        }
        .updateTaskStatus {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 50%;
          .btnSubmitTask {
            width: 45%;
            border: none;
            border-radius: 35px;
            color: #ffffff;
            background-color: #d5922d;
            font-size: 25px;
            font-weight: 700;
            line-height: 42.36px;
            height: 50px;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            margin-bottom: -15%;
          }
        }
      }
    }
    .taskDescription {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 60%;

      .descriptionSection {
        width: 50%;

        .titleLabel {
          margin-left: 2%;
          color: #213938;
          line-height: 18.15px;
          font-weight: 900;
          font-size: 15px;
          margin-bottom: 0%;
        }

        .titlleValue {
          margin-left: 6%;
          width: 90%;
          font-size: 20px;
          font-weight: 300;
          margin-top: 0px;
          line-height: 24.2px;
        }
      }

      .creditsAndDeliverySection {
        width: 50%;

        .totalCredsCard {
          background-color: #eaeaea;
          border-radius: 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: unset;
          height: 15%;
          padding-left: 15px;
          margin-top: 1%;
          padding-right: 5px;
          width: 96%;
          color: #213038;

          .cardLabel {
            font-weight: 300;
            font-size: 20px;
            line-height: 30.26px;
            color: #000000;
          }
          .TotalCredsContainer {
            background-color: #efb86f;
            border-radius: 35px;
            display: flex;
            aspect-ratio: 1 / 1;
            height: 85%;
            justify-content: center;
            align-items: center;
            .statusValue {
              color: #213038;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
              align-self: center;
            }
          }
          .cardValue {
            background-color: #efb86f;
            border-radius: 35px;
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
            height: 90%;
            width: 25%;
            justify-content: center;
            align-items: center;
            .statusValue {
              color: #213038;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
              align-self: center;
            }
          }
        }

        .archiveBtnSection {
          display: flex;
          height: 50%;
          justify-content: center;
          align-items: flex-end;
          .archiveBtn {
            width: 35%;
            height: 25%;
            border: none;
            cursor: pointer;
            border-radius: 35px;
            margin-bottom: 5%;
            color: #ffffff;
            background-color: #d5922d;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnValue {
              font-weight: 700;
              color: #ffffff;
              line-height: 42.36px;
              font-size: 35px;
            }
          }
        }
      }
    }
  }
  .monthlyCredsPointer {
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 22px solid #ffffff;
    z-index: 7;
    top: 66%;
  }
  .monthlyCredtsContainer {
    width: 350px;
    height: 265px;
    background-color: #ffffff;
    position: absolute;
    top: 97%;
    right: -3.5%;
    z-index: 4;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-weight: 300;
    .monthlyCredsHeader {
      width: 94%;
      height: 20%;
      border-radius: 25px;
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      align-items: center;
      justify-content: space-between;
    }
    .allocateCredsSection {
      width: 100%;
      height: 60%;
      position: relative;
      display: flex;
      overflow: scroll;
      flex-direction: column;
      .availableMonthlyCreds {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 92%;
        height: 25%;
        margin-left: 10px;
        font-size: 17px;
        font-weight: 300;
        line-height: 18.15px;
      }
    }
    .totalSection {
      width: 94%;
      height: 20%;
      border-radius: 25px;
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.estimatedCredsCard {
  width: 98%;
  border-radius: 35px;
  height: 15%;
  display: flex;
  background-color: #eaeaea;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.addNewAssignedTAskCreds {
  height: 85%;
  width: 47%;
  background-color: #efb86f;
  border-radius: 35px;
  font-weight: 700;
  font-size: 25px;
  color: #213038;
  text-align: center;
  line-height: 30.26px;
  border: none;
  text-align: center;
  outline: none;
}
.safariaddNewAssignedTAskCreds {
  height: 85%;
  aspect-ratio: 1 / 1;
  background-color: #efb86f;
  border-radius: 35px;
  font-weight: 700;
  font-size: 25px;
  color: #213038;
  text-align: center;
  line-height: 30.26px;
  border: none;
  text-align: center;
  outline: none;
}
.Txt {
  font-weight: 300;
  font-size: 20px;
  line-height: 24.2px;
  color: #213038;
  text-decoration: underline;
  cursor: pointer;
}
.viewArchiveDiv {
  position: absolute;
  width: 250px;
  top: 60%;
  right: 1%;
  border-radius: 35px;
  height: 40px;
  display: flex;
  padding: 5px;
  background-color: #ffffff;
  z-index: 6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  .viewArchivesBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #d5922d;
    cursor: pointer;
  }
}
.addNewMeeting {
    position: absolute;
    width: 90%;
    height: 92%;
    left: 7.5%;
    display: flex;
    flex-direction: column;
    color: #213038;
    align-items: center;
    justify-content: center;
    top: 2.5%;
  .closeCard {
    position: absolute;
    right: 1%;
    top: 2%;
    z-index: 5;
    cursor: pointer;
  }
  .credsSect {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    color: #213038;
    font-weight: 700;
    font-size: 25px;
    line-height: 36.31px;
    .credsCont {
        width: 22%;
        background: #eaeaea;
        border-radius: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 300;
        line-height: 24.2px;
        height: 75%;
        padding: 5px;
        margin-right: 18px;    
      .credsValue {
        height: 100%;
        background: #efb86f;
        aspect-ratio: 1 / 1;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        font-weight: 700;
        line-height: 30.26px;
        color: #213038;
      }
    }
  }
  .nameAndDateSec {
    width: 100%;
    height: 13%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    .meetingNameInput {
      position: relative;
      min-width: unset;
      border: 1px solid #213038;
      height: 45px;
      justify-content: center;
      border-radius: 35px;
      margin-top: 1%;
      width: 25%;
      margin-right: 1%;
      display: flex;
      .availComps {
        width: 95%;
        background-color: #eaeaea;
        margin-top: 10px;
        height: 50px;
        font-size: 20px;
        font-weight: 700;
        border-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .descriptionAndActionsSec {
    width: 100%;
    height: 72%;
    display: flex;
    align-items: center;
    align-content: center;
    .descSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      height: 100%;
      overflow: scroll;
    }
    .actionssection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      height: 100%;
      justify-content: space-between;
      .creditSection {
        width: 95%;
        background-color: #eaeaea;
        margin-top: 2%;
        border-radius: 35px;
        height: 49%;
        padding: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        font-size: 25px;
        font-weight: 300;
        input {
          margin: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          width: 29%;
          height: 100%;
          border-radius: 35px;
          border: none;
          background-color: #efb86f;
          text-align: center;
          font-size: 25px;
          font-weight: 700;
          color: #213038;
          outline: none;
        }
      }
      .statusSection {
        width: 95%;
        background-color: #eaeaea;
        margin-top: 2%;
        border-radius: 35px;
        height: 50%;
        padding: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        font-size: 25px;
        font-weight: 300;
        .archiveMessage {
          background-color: #efb86f;
          width: 30%;
          height: 100%;
          border-radius: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          .statusPointer {
            position: absolute;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid #ffffff;
            z-index: 7;
            bottom: 15%;
          }
          .optionContainer {
            width: 200px;
            height: 110px;
            right: 0.5%;
            display: flex;
            border-radius: 35px;
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            z-index: 5;
            position: absolute;
            top: 75%;
            padding: 2px;
            .options {
              width: 95%;
              display: flex;
              height: 45%;
              // margin-top: 2%;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 35px;
              background-color: #eaeaea;
              font-weight: 300;
              font-size: 20px;
              line-height: 24.2px;
              color: #213038;
              cursor: pointer;
              z-index: 9;
            }
          }
        }
      }
      .createBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 40%;
        height: 10%;
        border-radius: 35px;
        border: none;
        background-color: #d5922d;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  .addNewMeetingBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #d5922d;
    cursor: pointer;
  }
}
