.aionspl{
    width: 100%;
}
table, th, td {
    border: 1px solid white;
    border-collapse: collapse;
  }

.itemRow{
    width: 20%;
}

.dataRow{
    width: 13%;
}