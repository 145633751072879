.selectTag {
    position: relative;
    min-width: 200px;
    border: 1px solid #213038;
    min-height: 40px;
    justify-content: center;
    border-radius: 35px;
    padding: 2px;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
    .placeHolder{
        position: absolute;
        left: 45px;
        background-color: #ffffff;
        top: -36px;
        padding-left: 1%;
        padding-right: 1%;
        font-weight: 300;
        font-size: 20px;
        z-index: 10;
      }
    select{
        width: 95%;
        margin-left: 4%;
        padding-left: 2px;
        font-weight: 700;
        font-size: 25px;
        background-color: transparent !important;
        border: none;
        margin-top: 1%;
        color: #213038;
        outline: none;
        margin-bottom: 5px;
    }
  }
  