.compReportPage{
    width: 99%;
    height: 97%;
    display: flex;
    flex-direction: column;
    color: #213038;
    .lossSec{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .profitValues{
            display: flex;
            flex-direction: row;
            height: 80%;
            width: 30%;
            align-items: center;
            justify-content: space-between;
             .lossProfSection{
                width: 85%;
                background-color: #ffffff;
                height: 75%;
                border-radius: 35px;
                padding: 5px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                font-size: 20px;
                font-weight: 300;
                line-height: 24.2px;    
            .lossValue{
                background-color: #EFB86F;
                color: #213038;
                width: 65%;
                display: flex;
                height: 100%;
                flex-direction: row;
                align-items: center;
                border-radius: 35px;
                font-weight: 700;
                justify-content: center;
                font-size: 20px;
                line-height: 24.2px;
            }
        }
        }
        .viewByHoover{
            background-color: #ffffff;
            display: flex;
            width: 11%;
            height: 60%;
            padding: 5px;
            border-radius: 35px;
            font-size: 20px;
            font-weight: 300;
            line-height: 24.2px;
            align-items: center;
            justify-content: center;
            position: relative;
            bottom: 0%;
            .filterPointer{
                position: absolute;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid #ffffff;
                z-index: 7;
                top: 65%;
                right: 30%;
            }
            .filterContainer{
                width: 200px;
                height: 140px;
                position: absolute;
                right: 0.5%;
                display: flex;
                border-radius: 25px;
                background-color: #ffffff;
                top: 90%;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                z-index: 5;
                .filterOptions{
                    width: 95%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 35px;
                    background-color: #EAEAEA;
                    font-weight: 300;
                    font-size: 20px;
                    height: 30%;
                    line-height: 24.2px;
                    color: #213038;
                    cursor: pointer;
                }
            }
        }
    }
    .viewGraphsSection{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 90%;
        flex-wrap: wrap;
        align-items: center;
        overflow: scroll;
        .TempGraphCard{
            width: 49%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            background: #ffffff;
            font-size: 25px;
            margin: 0.5%;
            .dateSection{
                width: 95%;
                height: 10%;
                display: flex;
                flex-direction: row;
                border-radius: 35px;
                align-items: center;
                justify-content: space-between;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
            }
            .graphLegends{
                width: 100%;
                height: 5%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: 300;
                color: #213038;
                font-size: 15px;
                .forecastLabel{
                    width: 15%;
                    background-color: #213038;
                    padding: 5px;
                    margin-left: 3%;
                    margin-right: 1%;
                    border-radius: 35px;
                }
                .actualLabel{
                    width: 15%;
                    background-color: #D4912D;
                    padding: 5px;
                    margin-right: 1%;
                    border-radius: 35px;
                }
            }
            .graphSection{
                width:100%;
                height: 75%;
                display: flex;

            }
            .totalSection{
                width: 100%;
                display: flex;
                height: 10%;
                border-radius: 35px;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                font-weight: 700;
                color: #213038
            }
        }
        .otherMetrics{
            width: 100%;
            height: 8%;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            border-radius: 35px;
            font-size: 25px;
            font-weight: 300;
            line-height: 36.31px;
            margin: 0.4%;
            .metricValue{
                width: 20%;
                height: 100%;
                display: flex;
                background-color: #EAEAEA;
                font-weight: 700;
                border-radius: 35px;
                font-size: 20px;
                align-items: center;
                justify-content: center;
            }
        }
        .summaryLineChart {
            width: 98.5%;
            height: 85%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            background: #ffffff;
            padding: 5px;
            margin: 0.4%;
            font-size: 20px;
            font-weight: 700;
            color: #213038;

            .chartLegend {
                width: 100%;
                height: 20%;
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 24.2px;
                justify-content: center;
                font-weight: 300;
                font-size: 20px;
                .GaugesSection {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .gaugeConatiner{
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        justify-content: center;
                    }
                    .runwayGaugeValues{
                        width: 50%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        font-size: 20px;
                        line-height: 0px;
                        text-align: left;
                        justify-content: center;
                        align-items: flex-start;
                        p.runwayHeader {
                            font-weight: 700;
                            font-size: 25px;
                            color: #213038;
                        }
                    }
                }
                .legends {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .income-expenseSection {
                        height: 45%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .incomeLegend {
                            background-color: #EFB86F;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                        .expenseLegend {
                            background-color: #A62626;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                    }
                    .burn-cashSection {
                        height: 45%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .cashflowLegend {
                            background-color: #213038;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                        .burnrateLegend {
                            background-color: #7A8F9D;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                    }
                }

            }

            .chartContainer {
                width: 98%;
                height: 75%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 35px;
                background-color: #EAEAEA;
                margin-bottom: 0.2%;
            }
        }
        .VerticalGraphCard {
            width: 49.2%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            background: #ffffff;
            margin: 0.4%;
            font-size: 20px;
            font-weight: 700;

            .dateSection {
                width: 95%;
                height: 10%;
                display: flex;
                flex-direction: row;
                border-radius: 35px;
                align-items: center;
                justify-content: space-between;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
            }

            .graphLegends {
                width: 100%;
                height: 5%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: 300;
                color: #213038;
                font-size: 15px;

                .forecastLabel {
                    width: 15%;
                    background-color: #213038;
                    padding: 5px;
                    margin-left: 3%;
                    margin-right: 1%;
                    border-radius: 35px;
                }

                .actualLabel {
                    width: 15%;
                    background-color: #D4912D;
                    padding: 5px;
                    margin-right: 1%;
                    border-radius: 35px;
                }
            }

            .graphSection {
                width: 80%;
                height: 75%;
                display: flex;
                svg {
                    // margin: 20px;
                    width: 100%;
                }
            }

            .totalSection {
                width: 100%;
                display: flex;
                height: 10%;
                border-radius: 35px;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                font-weight: 700;
                color: #213038
            }
        }
    }
}

.syncBtn{
    background-color: #D4912D;
    width: 20%;
    height: 90%;
    border: none;
    border-radius: 35px;
    color: #ffffff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
}
.handlerAddMetrics {
    position: absolute;
    width: 91%;
    height: 95%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 35px;
    align-items: center;
    justify-content: flex-start;
    .closeCardSect {
        position: absolute;
        right: 1.5%;
        top: 2%;
    }

    .dateAndTypeSect {
        width: 95%;
        height: 15%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .changeStatusHover {
            width: 15%;
            display: flex;
            height: 45%;
            justify-content: center;
            border-radius: 35px;
            align-items: center;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: 1%;
            font-weight: 700;
            color: #213038;
            font-size: 20px;
            border: solid 1px #213038;
            position: relative;

            .placeHolder {
                position: absolute;
                bottom: 30%;
                left: 20%;
                width: 55%;
                background-color: #fff;
                padding-left: 5px;
                font-weight: 300;
            }

            .estimatedCredsCard {
                border: none;
                background-color: transparent;
                font-size: 25px;
                width: 25%;
                display: flex;
                font-weight: 700;
            }

            .updateDeliveryDate {
                border: none;
                background-color: transparent;
                font-size: 18px;
                font-weight: 700;
                line-height: 30.26px;
                outline: none;
            }

            .statusChangePointer {
                position: absolute;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 22px solid #ffffff;
                z-index: 7;
                top: 65%;
                right: 40%;
            }

            .statusChanageContainer {
                position: absolute;
                background-color: #ffffff;
                width: 200px;
                height: 150px;
                top: 115%;
                right: -5%;
                display: flex;
                border-radius: 25px;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                z-index: 5;

                .statusChangeOptions {
                    width: 90%;
                    border-radius: 25px;
                    background-color: #eaeaea;
                    color: #213038;
                    height: 28%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    justify-content: center;

                    .statusValue {
                        font-size: 20px;
                        font-weight: 300;
                        color: #213038;
                        line-height: 24.2px;
                    }
                }
            }
        }

        .departmentInput {
            position: relative;
            min-width: 268.22px;
            border: 1px solid #213038;
            height: 40px;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 35px;
            flex-direction: column;
            margin-top: 1%;
            margin-left: 1%;
            margin-right: 1%;
        }

        .monthTypeContainer {
            width: 20%;
            height: 50%;
            border: 1px solid #213038;
            display: flex;
            flex-direction: row;
            position: relative;
            color: #213038;
            border-radius: 35px;
            align-items: center;
            justify-content: center;
            .placeHolder {
                position: absolute;
                bottom: 40%;
                left: 15%;
                padding-left: 2%;
                width: 40%;
                background-color: #fff;
                font-weight: 300;
                font-size: 18px;
            }
            .values{
                font-weight: 700;
                font-size: 22px;
            }
        }
    }

    .categoryMetricsSection {
        width: 93%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        overflow-y: scroll;

        .incomeMetricCard {
            width: 100%;
            min-height: 465px;
            background-color: #fff;
            border-radius: 35px;
            border: solid 1px #213038;
            display: flex;
            flex-direction: column;
            color: #213038;
            align-items: center;
            font-weight: 700;
            font-size: 23px;
            margin-bottom: 1%;

            .metricNameSect {
                display: flex;
                justify-content: space-between;
                width: 97%;
                height: 5%;
                align-content: center;
                flex-direction: row;
                align-items: center;
                padding: 1%;

                .totalAmountAndDropdown {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .metricCategoriesSect {
                width: 97%;
                height: 75%;
                padding: 1%;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                align-items: center;

                .subCatsContainer {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 75%;
                    overflow-y: scroll !important;
                }

                .categoryHeaderSect {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 15%;
                    align-items: center;
                    justify-content: space-between;

                    .totalAmountAndDropdown {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }

            .subCategoryContainer {
                width: 100%;
                display: flex;
                flex-direction: rosw;
                align-items: center;
                height: 15%;
                justify-content: space-between;

                input {
                    border: none;
                    border-bottom: 2px solid #898989;
                    color: #213038;
                    font-weight: 700;
                    font-size: 20px;
                    outline: none;
                }

                input::placeholder {
                    color: #213038;
                    font-weight: 700;
                    font-size: 20px;
                }

                .totalAmountAndDropdown {
                    width: 17%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            button {
                background-color: #D4912D;
                width: 20%;
                height: 15%;
                border: none;
                border-radius: 35px;
                color: #ffffff;
                cursor: pointer;
                font-size: 20px;
                font-weight: 700;
            }
        }
    }

    .btnSection {
        width: 100%;
        height: 8%;
        display: flex;
        align-items: center;
        justify-content: center;

        .saveBtn {
            background-color: #D4912D;
            width: 20%;
            height: 90%;
            border: none;
            border-radius: 35px;
            color: #ffffff;
            cursor: pointer;
            font-size: 25px;
            font-weight: 700;
        }
    }
}