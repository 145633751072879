.alkemiaBriefingHomepage {
  width: 98%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 1%;
  .investeeHeader {
    margin-top: 2%;
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .addNewTaskBtn {
      background-color: #d5922d;
      color: white;
      border-radius: 35px;
      min-width: 410px;
      border: none;
      height: 80%;
      cursor: pointer;
      margin-top: 0%;
      margin-bottom: 0px;
      font-size: 35px;
    }
    .investeeCreditsSection {
      display: flex;
      flex-direction: row;
      width: 30%;
      height: 80%;
      .remainingCreditsCard {
        width: 50%;
        margin-left: 1%;
        display: flex;
        border-radius: 35px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        padding-right: 3px;
        color: #213038;
        padding-left: 10px;
        font-weight: 300;
        font-size: 20px;
        line-height: 24.2;
        .remainingCreditsValue {
          display: flex;
          flex-direction: row;
          background-color: #d5922d;
          justify-content: center;
          align-content: center;
          align-items: center;
          height: 90%;
          aspect-ratio: 1 / 1;
          border-radius: 35px;
          color: #213038;
          font-weight: 700;
          font-size: 25px;
          line-height: 30.26px;
        }
      }
    }
  }
  .investeeTabsSection {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .investeeStatusCard {
      width: 20%;
      height: 80%;
      align-items: center;
      display: flex;
      margin-left: 1%;
      border-radius: 35px;
      background-color: #ffffff;
      justify-content: space-between;
      color: #213038;
      font-size: 25px;
      font-weight: 300;
      padding-left: 1%;
      padding-right: 1%;
      line-height: 30.26px;
      cursor:pointer;
    }
    .selectedInvesteeStatusCard {
      width: 20%;
      height: 80%;
      align-items: center;
      display: flex;
      margin-left: 1%;
      border-radius: 35px;
      background-color: #213038;
      justify-content: space-between;
      color: #ffffff;
      font-size: 25px;
      font-weight: 300;
      padding-left: 1%;
      padding-right: 1%;
      line-height: 30.26px;
      cursor: pointer;
    }
  }
  .investeeTaskHeaders {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    flex-direction: row;
    .taskTitleHeader {
      line-height: 24.2px;
      font-size: 20px;
      font-weight: 700;
      color: #213038;
      width: 28%;
      margin-left: 2%;
    }
    .taskDescHeader {
      line-height: 24.2px;
      font-size: 20px;
      font-weight: 700;
      color: #213038;
      width: 60%;
    }
    .taskCredsHeader {
      line-height: 24.2px;
      font-size: 20px;
      font-weight: 700;
      color: #213038;
      width: 10%;
    }
  }
  .investeeAvailableTasks {
    width: 100%;
    height: 64%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
    .availableTasks {
      height: 12%;
      width: 100%;
      display: flex;
      background-color: #ffffff;
      padding: 0%;
      border-radius: 35px;
      margin-top: 1%;
      position: unset;
      color: #213038;
      cursor: pointer;
      align-items: center;
      .taskTitle {
        margin-left: 2%;
        display: flex;
        font-size: 25px;
        line-height: 30.26px;
        font-weight: 700;
        width: 28%;
        color: #213038;
      }
      .taskDescription {
        display: flex;
        font-size: 25px;
        line-height: 30.26px;
        font-weight: 300;
        width: 60%;
        color: #213038;
      }
      .taskUsedCreds {
        display: flex;
        font-size: 25px;
        line-height: 30.26px;
        font-weight: 300;
        width: 5%;
        color: #213038;
        justify-content: center;
      }
    }
  }
  .AddNewTAskCard {
    position: absolute;
    width: 90%;
    height: 93%;
    left: 7.5%;
    display: flex;
    flex-direction: column;
    color: #213038;
    align-items: center;
    justify-content: center;
    .topBarSection {
      width: 100%;
      height: 5%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-start;
    }
    .taskNameSection {
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: row;
      .taskNameInput {
        position: relative;
        width: 40%;
        border: 1px solid #213038;
        height: 40px;
        justify-content: center;
        border-radius: 35px;
        margin-top: 1%;
        display: flex;
      }
      .departmentInput {
        position: relative;
        min-width: 268.22px;
        border: 1px solid #213038;
        height: 50px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 35px;
        flex-direction: column;
        margin-top: 1%;
        margin-left: 1%;
        margin-right: 1%;
      }
      .dateSection {
        width: 35%;
        height: 50px;
        margin-top: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
      }
    }
    .collateralsSection {
      width: 100%;
      height: 85%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .departmentCategoriesCard {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 4%;
        .taskCategorySection {
          width: 100%;
          height: 10%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 8px;
          .plartformSelects {
            position: relative;
            width: 50%;
            border: 1px solid #213038;
            height: 50px;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 35px;
            flex-direction: column;
            margin-left: 1%;
            margin-right: 1%;
          }
        }
        .taskDescSection {
          width: 100%;
          height: 65%;
          display: flex;
          align-items: center;
          flex-direction: column;
          overflow: scroll;
          padding-top: 2%;
          .descriptionTextB {
            width: 95%;
          }
          .projectObjectiveText {
            width: 95%;
            min-width: unset;
            margin-top: 3%;
          }
          .submitTask {
            background-color: #d9d9d9;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 50px;
            cursor: pointer;
            font-size: 35px;
          }
          .requirementsMetBtn {
            background-color: #d5922d;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 50px;
            cursor: pointer;
            font-size: 35px;
          }
        }
        .submitTaskSection {
          width: 100%;
          height: 15%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .submitTask {
            background-color: #d9d9d9;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 80%;
            cursor: pointer;
            font-size: 35px;
          }
          .requirementsMetBtn {
            background-color: #d5922d;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 80%;
            cursor: pointer;
            font-size: 35px;
          }
        }
      }
      .actionSectionCard {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .categoryHeader {
          width: 100%;
          height: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          font-weight: 700;
          color: #213038;
        }
        .collateralDet {
          width: 100%;
          height: 90%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: unset;
          overflow: scroll;
          .colateralCards {
            width: 90%;
            display: flex;
            margin-top: 1%;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
  .viewTask {
    position: absolute;
    width: 90%;
    height: 93%;
    left: 7.5%;
    display: flex;
    flex-direction: column;
    color: #213038;
    align-items: center;
    justify-content: center;
    .headerSection {
      width: 100%;
      height: 7%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .taskNameTxt {
        font-size: 25px;
        color: #213038;
        line-height: 30.26px;
        align-self: center;
        display: flex;
        font-weight: 700;
      }
      .closeTaskImg {
        position: absolute;
        top: 2%;
        right: 1%;
      }
    }
    .TaskInfoSection {
      width: 100%;
      height: 15%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .viewedTaskInputs {
        position: relative;
        min-width: unset;
        width: 25%;
        border: 1px solid #213038;
        height: 45px;
        justify-content: center;
        border-radius: 35px;
        display: flex;
        margin-right: 1%;
      }
    }
    .descriptionBar {
      width: 100%;
      height: 55%;
      display: flex;
      overflow: scroll;
      flex-direction: column;
      .descriptionHeader {
        margin-left: 1%;
        font-weight: 700;
        font-size: 15px;
        color: #213038;
      }

      .descriptionValue {
        margin-left: 4%;
        margin-top: 1px;
        font-weight: 300;
        font-size: 20px;
        color: #213038;
      }
    }
    .viewTAskCredsCreditsSection {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .viewEstimatedOrassignedCeds {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .estCredsCard {
          width: 40%;
          height: 98%;
          margin-right: 1%;
          background-color: #d9d9d9;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: unset;
          padding-left: 10px;
          border-radius: 35px;
          .remCreditsCard {
            display: flex;
            flex-direction: row;
            height: 90%;
            background-color: #d5922d;
            justify-content: center;
            align-content: center;
            align-items: center;
            border-radius: 35px;
            margin-right: 1%;
            aspect-ratio: 1 / 1;

            b {
              font-weight: 700;
              color: #213038;
              font-size: 23px;
            }
          }
          .deliveryDate {
            display: flex;
            flex-direction: row;
            min-width: 50px;
            height: 90%;
            background-color: #d5922d;
            justify-content: center;
            align-content: center;
            align-items: center;
            border-radius: 35px;
            margin-right: 1%;
            b {
              font-weight: 700;
              color: #213038;
              font-size: 23px;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }
      .acceptOrRejctSection {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .abandonTask {
          line-height: 24.2px;
          font-size: 20px;
          font-weight: 300;
          color: #213038;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .estimatedOrUsedCreds {
      }
      .investeeDecisionSection {
        display: flex;
        width: 100%;
        height: 70%;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        .acceptEstimatedCredsBtn {
          width: 20%;
          height: 98%;
          margin-right: 1%;
          border: none;
          border-radius: 35px;
          margin-top: 10px;
          background-color: #d5922d;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            color: #ffffff;
            font-weight: 700;
            font-size: 25px;
          }
        }
        .rejectEstimatedCredsBtn {
          width: 20%;
          height: 98%;
          margin-right: 1%;
          border: none;
          border-radius: 35px;
          margin-top: 10px;
          background-color: #d9d9d9;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            color: #ffffff;
            font-weight: 700;
            font-size: 25px;
          }
        }
      }
    }
  }
}
.customOptions {
  width: 95%;
  background-color: #eaeaea;
  margin-top: 10px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.selectActive {
  width: 95%;
  background-color: #d5922d;
  margin-top: 10px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
