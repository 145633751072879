.InvesteeSidebar {
    height: 100%;
    width: 7%;
    background: #FFFFFF;
    background-blend-mode: normal, color-burn;
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    gap: 10%;
    z-index: 15;
    overflow: scroll;

    img {
        margin-top: 20px;
    }

    .pagesSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 80%;
        gap: 8%;
        flex: 1 1;
        padding: 1%;
        position: absolute;
        top: 23%;

        .availablePages {
            display: flex;
            width: 76px;
            height: 76px;

            p {
                margin: 0px;
                color: #213038;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 45px;
                overflow: hidden;
                background-color: #ffffff;
                width: 100%;
            }
        }

        .availablePagesActive {
            display: flex;
            width: 76px;
            height: 76px;
            margin-top: 0%;
            cursor: pointer;

            p {
                margin-top: 3px;
                background-color: #D5922D;
                color: white;
                min-height: 100%;
                aspect-ratio: 1/1 !important;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 50px;
            }

            .availablePageSelector {
                width: 4vw;
                height: 10vh;
                position: absolute;
                left: -53%;
                border-radius: 15px;
                background-color: #D5922D;
            }
        }

    }
}

.selectedPageFont {
    color: rgb(26, 24, 24);
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 45px;
}



//ACTIVE SECTION  
.InvesteeSidebarActive {
    width: 17%;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 40;
    background-color: rgb(255, 255, 255);
    overflow: scroll;
    img {
        margin-top:-6.5px;
    }

    .pagesSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 8%;
        flex: 1 1;
        padding: 1%;
        cursor: pointer;

        // background: rebeccapurple;

        .availablePages {
            display: flex;
            width: 65%;
            height: 70px;
            border-style: double;
            // border-color: rgb(55, 255, 0);
            // margin: 2%;

            p {
                margin: 0px;
                color: #213038;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 45px;
                overflow: hidden;
                // background-color: purple;
                width: 100%;
                animation: Inc_width 0.7s;
                font-size: 35px;
                padding: 2%;
            }
        }

        .availablePagesActive {
            //background-color: #D5922D;
            display: flex;
            width: 65%;
            height: 70px;
            cursor: pointer;

            p {
                margin: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 45px;
                overflow: hidden;
                //background-color: rgb(229, 255, 0);
                color: white;
                font-size: 35px;
                animation: Inc_width 0.9s;
            }

            .availablePageSelector {
                height: 70px;
                width: 70px;
                position: absolute;
                left: -60px;
                border-radius: 15px;
                background-color: #D5922D;
            }
        }

        @keyframes Inc_width {
            0% {
                width: 45%;
                text-align: left;
                aspect-ratio: 1/1;
            }

            100% {
                width: 100%;
                text-align: center;
            }
        }
    }
}