.clickedCompanyName{
    /* width: 10%; */
    height: 10%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}
.dashboardIcons{
    margin-left: 10%;
    height: 23px;
    margin-top: 5%;
    width: 23px;
}
.dashBoardBackGround{
    width: 100%;
    height: 99.9vh;
    background: rgb(13,15,16);
    background: radial-gradient(circle, rgba(13,15,16,1) 0%, rgba(84,91,101,1) 0%, rgba(13,15,16,1) 100%);
    background-size: cover;
    background-repeat: no-repeat;
}
.companyIconCard{
    width: 60px;
    display: flex;
    height: 60px;
    align-items: center;
    margin-right: 1%;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #000;
  }