.department-report-section{
    width: 100%;
    height: 97%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    color: #213038;
    justify-content: center;
    align-items: center;
    .top-sectionDepartment-report{
        width: 99.3%;
        height: 7%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        .department-report-title{
            font-size: 25px;
            font-weight: 700;
            color: #213038;
        }
        .department-report-filter{
            width: 20%;
            height: 90%;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            border-radius: 35px;
            position: relative;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            font-weight: 700;
            font-size: 20px;
            color: #213038;
            .filter-from-date{
                width: 48%;
                height: 100%;
                display: flex;
                flex-direction: row;
                background-color: #EAEAEA;
                align-items: center;
                justify-content: center;
                border-radius: 35px;
                position: relative; 
                .filter-placeHolder{
                    position: absolute;
                    bottom: 30%;
                    font-weight: 300;
                    font-size: 15px;
                    color: #213038;
                }
            }
            .view-calendar{
                width: 500px;
                height: 280px;
                display: flex;
                flex-direction: row;
                background-color: #ffffff;
                border-radius: 35px;
                position: absolute;
                top: 0%;
                right: -3px;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .task-details-container{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        overflow: scroll;
        color: #213038;
        justify-content: space-between;
        align-items: center;
        .comp-name-filter{
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 25px;
            font-weight: 700;
            color: #213038;
        }
        .date-selector-section{
            width: 25%;
            height: 70%;
            display: flex;
            align-items: center;
            font-size: 25px;
            font-weight: 700;
            color: #213038;
            background-color: #ffffff;
            border-radius: 35px;
            justify-content: space-around;
            padding: 5px;
            div{
                width: 49%;
                height: 100%;
                display: flex;
                background-color: #EAEAEA;
                border-radius: 35px;
                justify-content: center;
                align-items: center;
                position: relative;
                .placeholder{
                    position: absolute;
                    bottom: 15%;
                    font-size: 20px;
                    font-weight: 300;
                }
                .calender-open{
                    position: absolute;
                    width: 300px;
                    height: 200px;
                    background-color: blue;
                    display: flex;
                    flex-direction: column;
                    border-radius: 35px;
                    top: 50%;
                    z-index: 50;
                }
            }
        }
    }
    .taskComp-overview-screen{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        .companiesOverview{
            width: 99.5%;
            height: 9%;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            border-radius: 35px;
            align-items: center;
            justify-content: space-between;
            margin: 5px;
            .taskOverview-chart-legend{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .chart-legends-section{
                    width: 20%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    border-radius: 35px;
                    .dev-legend-section{
                        width: 100%;
                        height: 50%;
                        display: flex;
                        flex-direction: row;
                        font-size: 20px;
                        font-weight: 300;
                        justify-content: flex-start;
                        align-items: center;
                        margin-left: 15px;
                        div{
                            width: 30%;
                            height: 40%;
                            border-radius: 35px;
                            background-color: #D4912D;
                            margin-right: 15px;
                        }
                    }
                    .design-legend-section{
                        width: 100%;
                        height: 50%;
                        display: flex;
                        flex-direction: row;
                        font-size: 20px;
                        font-weight: 300;
                        justify-content: flex-start;
                        align-items: center;
                        margin-left: 15px;
                        div{
                            width: 30%;
                            height: 40%;
                            border-radius: 35px;
                            background-color: #213038;
                            margin-right: 15px;
                        }
                    }
                }
                .overview-tab{
                    width: 20%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    border-radius: 35px;
                    justify-content: center;
                    align-items: center;
                }
                .total-tasks-section{
                    width: 15%;
                    height: 75%;
                    display: flex;
                    flex-direction: row;
                    border-radius: 35px;
                    padding: 5px;
                    justify-content: space-between;
                    align-items: center;
                    .dev-totals-section{
                        width: 49%;
                        height: 100%;
                        display: flex;
                        background-color: #D4912D;
                        border-radius: 35px;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                        color: #ffffff;
                        font-weight: 300;
                    }
                    .design-totals-section{
                        width: 49%;
                        height: 100%;
                        display: flex;
                        background-color: #213038;
                        border-radius: 35px;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                        color: #ffffff;
                        font-weight: 300;
                    }
                }
                .assigned-creds-section{
                    width: 25%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    height: 65%;
                    background-color: #EAEAEA;
                    border-radius: 35px;
                    margin-right: 5px;
                    padding: 5px;                
                    .departments-remaing-section{
                        width: 25%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        .assigned-Dev-creds-conatainer{
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            background-color: #D4912D;
                            aspect-ratio: 1 / 1;
                            border-radius: 35px;
                            color: #ffffff;
                            font-weight: 700;
                            font-size: 20px;
                        }
                        .assigned-Design-creds-container{
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            background-color: #213038;
                            aspect-ratio: 1 / 1;
                            border-radius: 35px;
                            color: #ffffff;
                            font-weight: 700;
                            font-size: 20px;
                        }
                    }
                }
                .comp-name-filter{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding-left: 10px;
                }
            }
            .taskOverview-chart-section{
                width: 100%;
                height: 85%;
                margin-bottom: 15px;
                .credits-tasks-section{
                    width: 100%;
                    height: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .category-section{
                        height: 27%;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;
                        font-size: 20px;
                        font-weight: 300;
                        width: 99%;
                        padding: 5px;
                        .categories-containers{
                            width: 50%;
                            margin: 5px;
                            height: 90%;
                            display: flex;
                            border-radius: 35px;
                            align-items: center;
                            justify-content: space-between;
                            background-color: #EAEAEA;
                            padding: 5px;
                            div{
                                height: 100%;
                                background-color: #EFB86F;
                                aspect-ratio: 1 / 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: row;
                                border-radius: 35px;
                                font-weight: 700;
                            }
                        }
                    }
                }
                .remainingCategory-section{
                    width: 100%;
                    height: 18%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    font-size: 20px;
                    font-weight: 300;
                    padding: 5px;
                    .remaingCreds-container{
                        width: 97.8%;
                        height: 90%;
                        background-color: #EAEAEA;
                        display: flex;
                        margin-right: 1%;
                        flex-direction: row;
                        border-radius: 35px;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px;
                        div{
                            height: 100%;
                            background-color: #EFB86F;
                            aspect-ratio: 1 / 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            border-radius: 35px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .available-comps-section{
            width: 99.5%;
            height: 10%;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            border-radius: 35px;
            align-items: center;
            margin: 5px;
            .comp-info-section{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            .comp-info-isOpened{
                display: flex;
                flex-direction: column;
                width: 100%;
                min-height: 70%;
                justify-content: center;
                align-items: center; 
            }
        }
    }
}
