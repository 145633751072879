.handlerMainPage {
  width: 100%;
  height: 97%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  color: #213038;
  .companiesSections {
    border-radius: 35px;
    color: #213038;
    background: #ffffff;
    justify-content: center;
    padding: unset;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .compName {
      font-weight: 700;
      color: #213038;
      line-height: 30.26px;
      size: 25px;
      width: 60%;
    }
    .creditsSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 15%;
      border-radius: 35px;
      background: #eaeaea;
      padding-left: 1%;
      height: 75%;
      margin-right: 1%;
      .credsNum {
        background-color: #efb86f;
        border-radius: 35px;
        height: 90%;
        aspect-ratio: 1 / 1;
        justify-content: center;
        color: #213038;
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }
  }
  .availableCompanies {
    width: 96%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 35px;
    color: #213038;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 1%;
    z-index: 5;
    .companyCredsDetails {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .compAvailableCreds {
        width: 30%;
        display: flex;
        flex-direction: row;
        .credsDIv {
          width: 44%;
          background-color: #eaeaea;
          font-weight: 300;
          font-size: 20px;
          display: flex;
          line-height: 24.2px;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          border-radius: 35px;
          padding-left: 2.5%;
          padding-right: 1.5%;
          height: 50px;
          margin-top: 1%;
          margin-bottom: 1%;
          margin-right: 2%;
          .availableCredsValue {
            background-color: #efb86f;
            font-size: 25px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            height: 85%;
            aspect-ratio: 1 / 1;
            border-radius: 35px;
          }
        }
      }
    }
    .compTaskDetails {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: scroll;
      .titlesSection {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        line-height: 24.2px;
        color: #213038;
        .taskTitle {
          width: 40%;
          cursor: pointer;
        }
        .taskDepartment {
          width: 20%;
          cursor: pointer;
        }
        .taskDeliveryDate {
          width: 20%;
        }
        .taskStatus {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 20%;
          cursor: pointer;
          justify-content: center;
          position: relative;
          .filterContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 50%;
            .filterPointer {
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-bottom: 20px solid #ffffff;
              z-index: 7;
            }
            .filterContainer {
              width: 220px;
              height: 275px;
              right: 0.5%;
              display: flex;
              border-radius: 25px;
              background-color: #ffffff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
              flex-direction: column;
              justify-content: center;
              align-items: center;
              z-index: 5;
              .filterOptions {
                width: 95%;
                display: flex;
                height: 19%;
                margin-top: 2%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 35px;
                background-color: #eaeaea;
                font-weight: 300;
                font-size: 20px;
                line-height: 24.2px;
                color: #213038;
                cursor: pointer;
              }
              .clearFilter {
                font-size: 15px;
                font-weight: 300;
                line-height: 15.73px;
                color: #d5922d;
                cursor: pointer;
              }
            }
          }
        }
      }
      .availableCompTasksSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1%;
        .compTaskCard {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: #eaeaea;
          border-radius: 35px;
          color: #213038;
          font-size: 20px;
          font-weight: 300;
          margin-top: 1%;
          .Title {
            width: 40%;
            margin-left: 1%;
          }
          .Department {
            width: 20%;
          }
          .DeliveryDate {
            width: 20%;
          }
          .Status {
            display: flex;
            align-items: center;
            width: 18%;
            height: 50px;
            border-radius: 35px;
            flex-direction: row;
            justify-content: center;
            background-color: #efb86f;
            color: black;
            font-size: 25px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .viewCompTasksActive {
    margin-top: 1%;
    display: flex;
    padding: unset;
    flex-direction: column;
    border-radius: 35px;
    justify-content: unset;
    align-items: center;
    background-color: #ffffff;

    .compInfo {
      width: 100%;
      color: #213038;
      background: #ffffff;
      justify-content: center;
      padding: unset;
      display: flex;
      flex-direction: row;
      border-top-left-radius: 35px;
      border-top-right-radius: 35px;
      margin-top: 5px;

      .compName {
        font-weight: 700;
        color: #213038;
        line-height: 30.26px;
        size: 25px;
        width: 62%;
      }

      .creditsSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 15%;
        border-radius: 35px;
        background: #eaeaea;
        padding-left: 1%;
        height: 75%;
        margin-right: 1%;

        .credsNum {
          background-color: #efb86f;
          border-radius: 35px;
          height: 90%;
          aspect-ratio: 1 / 1;
          justify-content: center;
          color: #213038;
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
      }
    }

    .taskLabels {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      .titleColumn {
        margin-left: 5%;
        width: 40%;
        display: flex;
        align-items: center;
        .label {
          font-weight: 700;
          font-size: 20px;
          display: flex;
          line-height: 24.2px;
          color: #213038;
          align-items: center;
          flex-direction: row;
        }
      }
      .departmentColumn {
        width: 25%;
        display: flex;
        align-items: center;

        .label {
          font-weight: 700;
          font-size: 20px;
          line-height: 24.2px;
          display: flex;
          color: #213038;
          flex-direction: row;
        }
      }
      .deliveryColumn {
        width: 25%;
        display: flex;
        align-items: center;

        .label {
          font-weight: 700;
          font-size: 20px;
          line-height: 24.2px;
          color: #213038;
        }
      }
      .statusColumn {
        width: 10%;
        display: flex;
        align-items: center;

        .label {
          font-weight: 700;
          font-size: 20px;
          line-height: 24.2px;
          color: #213038;
        }
      }
      .filterPointer {
        position: absolute;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #ffffff;
        z-index: 7;
        top: 50%;
        right: 5%;
      }
      .filterContainer {
        width: 220px;
        height: 275px;
        position: absolute;
        right: 0.5%;
        display: flex;
        border-radius: 25px;
        background-color: #ffffff;
        top: 67%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 5;
        .filterOptions {
          width: 95%;
          display: flex;
          height: 19%;
          margin-top: 2%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 35px;
          background-color: #eaeaea;
          font-weight: 300;
          font-size: 20px;
          line-height: 24.2px;
          color: #213038;
          cursor: pointer;
        }
        .clearFilter {
          font-size: 15px;
          font-weight: 300;
          line-height: 15.73px;
          color: #d5922d;
          cursor: pointer;
        }
      }
    }

    .SelectedCompanyTask {
      width: 98%;
      margin-top: 1%;
      background-color: #eaeaea;
      border-radius: 35px;
      display: flex;
      height: 62px;
      align-items: center;

      .titleColumn {
        margin-left: 4%;
        width: 40%;
        display: flex;
        align-items: center;
        height: 100%;

        .label {
          font-weight: 700;
          font-size: 20px;
          display: flex;
          line-height: 24.2px;
          color: #213038;
        }
      }

      .departmentColumn {
        width: 25%;
        display: flex;
        align-items: center;
        height: 100%;

        .label {
          font-weight: 300;
          font-size: 25px;
          line-height: 32.26px;
          color: #213038;
        }
      }

      .deliveryColumn {
        width: 20%;
        display: flex;
        align-items: center;
        height: 100%;

        .label {
          font-weight: 300;
          font-size: 25px;
          line-height: 32.26px;
          color: #213038;
        }
      }

      .statusColumn {
        width: 15%;
        display: flex;
        align-items: center;
        background-color: #efb86f;
        height: 85%;
        justify-content: center;
        border-radius: 35px;
        margin-right: 5px;

        .label {
          font-weight: 700;
          font-size: 25px;
          line-height: 24.2px;
          color: #213038;
        }
      }
    }

    .archiveBtn {
      width: 20%;
      height: 90%;
      background-color: #d5922d;
      color: #ffffff;
      border: none;
      border-radius: 35px;

      .btnText {
        font-weight: 700;
        color: #ffffff;
        font-size: 35px;
        text-align: center;
        line-height: 42.36px;
      }
    }
  }
  .viewTasksCard {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 90%;
    left: 7.5%;
    border-radius: 35px;
    top: 5%;
    overflow: scroll;
    color: #213038;
    height: 90%;
    .closeViewTaskCArd {
      position: absolute;
      top: 3%;
      right: 2%;
      cursor: pointer;
      z-index: 5;
    }
    .companyDetails {
      width: 97%;
      height: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: start;
      justify-content: space-between;

      .selectedCompName {
        margin-left: 1%;
        font-weight: 700;
        color: #213038;
        font-size: 30px;
        line-height: 36.31px;
      }

      .remCredsContainer {
        background-color: #eaeaea;
        min-width: 20%;
        border-radius: 35px;
        padding-left: 15px;
        padding-right: 5px;
        display: flex;
        height: 90%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .remCredsTitle {
          font-weight: 300;
          color: #213038;
          font-size: 20px;
          line-height: 24.2px;
        }
        .credsContainer {
          background-color: #efb86f;
          display: flex;
          border-radius: 35px;
          aspect-ratio: 1 / 1;
          height: 90%;
          justify-content: center;
          align-content: center;
          align-items: center;
        }
      }
    }

    .taskDetails {
      width: 100%;
      display: flex;
      margin-top: 1%;
      flex-direction: row;
      .taskNameInput {
        width: 50%;
        display: flex;
        margin-left: 1%;
        margin-right: 1%;
      }
    }

    .taskCategoryDetails {
      width: 100%;
      display: flex;
      margin-top: 1%;
      flex-direction: row;
      .platFormSectionsInputs {
        width: 33%;
        margin-left: 1%;
        margin-right: auto;
      }
    }

    .taskDescription {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 60%;

      .descriptionSection {
        width: 50%;

        .titleLabel {
          margin-left: 2%;
          color: #213938;
          line-height: 18.15px;
          font-weight: 900;
          font-size: 15px;
          margin-bottom: 0%;
        }

        .titlleValue {
          margin-left: 6%;
          width: 90%;
          font-size: 20px;
          font-weight: 300;
          margin-top: 0px;
          line-height: 24.2px;
        }
      }

      .creditsAndDeliverySection {
        width: 50%;

        .totalCredsCard {
          background-color: #eaeaea;
          border-radius: 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: unset;
          height: 15%;
          padding-left: 15px;
          margin-top: 1%;
          padding-right: 5px;
          width: 96%;
          color: #213038;

          .cardLabel {
            font-weight: 300;
            font-size: 20px;
            line-height: 30.26px;
            color: #000000;
          }
          .TotalCredsContainer {
            background-color: #efb86f;
            border-radius: 35px;
            display: flex;
            aspect-ratio: 1 / 1;
            height: 85%;
            justify-content: center;
            align-items: center;
            .statusValue {
              color: #213038;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
              align-self: center;
            }
          }
          .cardValue {
            background-color: #efb86f;
            border-radius: 35px;
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
            height: 90%;
            width: 25%;
            justify-content: center;
            align-items: center;
            .statusValue {
              color: #213038;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
              align-self: center;
            }
          }
        }
        .archiveBtnSection {
          display: flex;
          height: 50%;
          justify-content: center;
          align-items: flex-end;
          .archiveBtn {
            width: 35%;
            height: 25%;
            border: none;
            cursor: pointer;
            border-radius: 35px;
            margin-bottom: 5%;
            color: #ffffff;
            background-color: #d5922d;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnValue {
              font-weight: 700;
              color: #ffffff;
              line-height: 42.36px;
              font-size: 35px;
            }
          }
        }
      }
    }
  }
  .archivedTasksContainer {
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #213038;
    .archiveHeader {
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .companyNameArchs {
        margin-left: 2%;
        font-weight: 700;
        font-size: 30px;
        line-height: 36.31px;
        color: #213038;
      }
    }
    .archiveTerms {
      width: 100%;
      height: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .titleValue {
        width: 35%;
        margin-left: 5%;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
      }
      .departmentValue {
        width: 25%;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
      }
      .deliveryDateValue {
        width: 15%;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
      }
    }
    .archivedTasks {
      width: 100%;
      height: 85%;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      align-items: center;
      margin-top: 1%;
      .archievedTasks {
        width: 95%;
        height: 10%;
        display: flex;
        flex-direction: row;
        border-radius: 35px;
        margin-top: 1%;
        color: #213038;
        padding: unset;
        .taskTitleValue {
          width: 40%;
          margin-left: 2%;
          font-weight: 300;
          font-size: 25px;
          display: flex;
          line-height: 30.26;
        }
        .taskDepartmentValue {
          width: 25%;
          font-weight: 300;
          font-size: 25px;
          line-height: 30.26;
        }
        .taskDateValue {
          width: 20%;
          font-weight: 300;
          height: 100%;
          font-size: 25px;
          display: flex;
          align-self: center;
          align-items: center;
          justify-content: flex-start;
        }
        .unArchiveSection {
          width: 15%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  button {
    width: 30%;
    background-color: #d5922d;
    color: #ffffff;
    border: none;
    border-radius: 35px;
    margin-top: 25px;
    height: 50px;
    margin-bottom: 15px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    .btnText {
      font-weight: 700;
      color: #ffffff;
      font-size: 35px;
      text-align: center;
      line-height: 42.36px;
    }
  }
  .ViewSelectedCard {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 91%;
    left: 7.5%;
    border-radius: 35px;
    top: 2%;
    overflow: scroll;
    color: #213038;
    height: 95%;
    background-color: #ffffff;
    padding: 1%;
    z-index: 10;
    .closeTask {
      position: absolute;
      right: 1.5%;
      top: 1.5%;
    }
    .selectedTaskCompName {
      width: 100%;
      height: 10%;
      display: flex;
      line-height: 36.31px;
      justify-content: space-between;
      font-size: 30px;
      font-weight: 700;
      color: #213038;
      flex-direction: row;
      .selectedCompCreds {
        width: 25%;
        height: 40px;
        // height: 65%;
        background-color: #eaeaea;
        margin-right: 0.5%;
        border-radius: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: flex-end;
        font-weight: 300;
        font-size: 20px;
        line-height: 24.2px;
        padding: 5px;
        .selectedCredsValue {
          background-color: #efb86f;
          height: 100%;
          aspect-ratio: 1 / 1;
          border-radius: 35px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          font-weight: 700;
        }
      }
    }
    .selectedTaskDetails {
      width: 100%;
      height: 20%;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      .taskNameDetails {
        width: 100%;
        height: 49%;
        display: flex;
        margin-top: 1%;
        flex-direction: row;
        .takAvailDetails {
          width: 33%;
          margin-right: 1%;
          height: 40px;
          min-width: unset;
        }
      }
      .dateDetails {
        width: 100%;
        height: 49%;
        display: flex;
        flex-direction: row;
        margin-top: 1%;
        .platformDetails {
          width: 33%;
          margin-right: 1%;
          height: 40px;
          min-width: unset;
          margin-top: 0.5%;
        }
      }
    }
    .selectedTaskDescription {
      width: 100%;
      height: 68%;
      display: flex;
      flex-direction: row;
      .selectedTaskDescriptionSection {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .titleLabel {
          margin-left: 2%;
          color: #213938;
          line-height: 18.15px;
          font-weight: 900;
          font-size: 15px;
          margin-bottom: 0%;
        }

        .titlleValue {
          margin-left: 6%;
          width: 90%;
          font-size: 20px;
          font-weight: 300;
          margin-top: 0px;
          line-height: 24.2px;
        }
      }
      .selectedTAskActionsSection {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .creditsAvailableSecti {
          height: 65%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .creditsBreakDown {
            background-color: #eaeaea;
            width: 99%;
            height: 45px;
            /* height: 20%; */
            border-radius: 35px;
            display: flex;
            flex-direction: row;
            margin-top: 1%;
            font-weight: 300;
            font-size: 25px;
            line-height: 30.26px;
            color: #000000;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            .monthlyCredsSection {
              font-weight: 700;
              font-size: 25px;
              background-color: #efb86f;
              height: 100%;
              border-radius: 35px;
              aspect-ratio: 1 / 1;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              .monthlyCredtsContainer {
                width: 350px;
    height: 265px;
    background-color: #ffffff;
    z-index: 4;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-weight: 300;
    position: absolute;
    top: 0px;
    right: 0px;
                .monthlyCredsHeader {
                  width: 94%;
                  height: 20%;
                  border-radius: 25px;
                  display: flex;
                  flex-direction: row;
                  margin-left: 10px;
                  align-items: center;
                  justify-content: space-between;
                }
                .allocateCredsSection {
                  width: 100%;
                  height: 60%;
                  position: relative;
                  display: flex;
                  overflow: scroll;
                  flex-direction: column;
                  .availableMonthlyCreds {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 92%;
                    height: 25%;
                    margin-left: 10px;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 18.15px;
                  }
                }
                .totalSection {
                  width: 94%;
                  height: 20%;
                  border-radius: 25px;
                  display: flex;
                  flex-direction: row;
                  margin-left: 10px;
                  align-items: center;
                  justify-content: space-between;
                }
              }
            }
          }
          .deliveryDateSect {
            background-color: #eaeaea;
            width: 99%;
            /* height: 20%; */
            height: 45px;
            border-radius: 35px;
            display: flex;
            flex-direction: row;
            margin-top: 1%;
            font-weight: 300;
            font-size: 25px;
            line-height: 30.26px;
            color: #000000;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            .dateValue {
              border-radius: 35px;
              background-color: #efb86f;
              height: 100%;
              width: 35%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
            }
          }
          .statusSect {
            background-color: #eaeaea;
            width: 99%;
            height: 45px;
            /* height: 20%; */
            border-radius: 35px;
            display: flex;
            flex-direction: row;
            margin-top: 1%;
            font-weight: 300;
            font-size: 25px;
            line-height: 30.26px;
            color: #000000;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            .statusValue {
              border-radius: 35px;
              background-color: #efb86f;
              height: 100%;
              width: 35%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 25px;
              font-weight: 700;
              line-height: 30.26px;
            }
          }
        }
        .archiveBtnSect {
          height: 35%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .archBtn {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #ffffff;
            font-size: 35px;
            line-height: 42.36px;
            font-weight: 700;
            height: 40%;
          }
        }
      }
    }
  }
  .admin-add-task{
    position: absolute;
    width: 10%;
    height: 8%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #efb86f;
    top: 3%;
    border-radius: 34px;
    right: 1%;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    color: #213038;
    img{
      width: 100%;
    }
  }
  .admin-newTask{
    width: 90.5%;
    height: 95%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    position: absolute;
    padding: 10px;
    z-index: 10;
    .company-credits-section{
      width: 100%;
      display: flex;
      height: 10%;
      flex-direction: row;
      align-items: center;
      .admin-closeTask{
        position: absolute;
        top: 2.2%;
        right: 1%;
      }
      .selected-comp-Creds{
        border-radius: 35px;
        width: 30%;
        height: 40px;
        display: flex;
        margin-top: 1%;
        background-color: #EAEAEA;
        margin-left: 1%;
        padding: 5px;
        align-items: center;
        justify-content: space-between;
        color: #213038;
        font-size: 20px;
        font-weight: 300;
        .avail-Cred-container{
          width: 50%;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 35px;
          background-color: #ffffff;
          padding: 5px;
          .dev-Rem-creds{
            display: flex;
            flex-direction: row;
            background-color: #d5922d;
            height: 100%;
            aspect-ratio: 1/1;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 700;
            border-radius: 35px;
          }
          .design-rem-creds{
            display: flex;
            flex-direction: row;
            background-color: #d5922d;
            height: 100%;
            aspect-ratio: 1/1;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 700;
            border-radius: 35px;
          }
        }
        
      }  
    }
    .task-name-date-section{
      width: 100%;
      display: flex;
      height: 10%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .department-metrics{
      width: 100%;
      display: flex;
      height: 78%;
      flex-direction: row;
      align-items: center;
      .department-collaterals{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .taskCategorySection {
          width: 100%;
          height: 10%;
          display: flex;
          margin-top: 1%;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 8px;
          .plartformSelects {
            position: relative;
            width: 50%;
            border: 1px solid #213038;
            height: 50px;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 35px;
            flex-direction: column;
            margin-left: 1%;
            margin-right: 1%;
          }
        }
        .taskDescSection {
          width: 100%;
          height: 86%;
          display: flex;
          align-items: center;
          flex-direction: column;
          overflow: scroll;
          padding-top: 2%;
          .descriptionTextB {
            width: 95%;
          }
          .projectObjectiveText {
            width: 95%;
            min-width: unset;
            margin-top: 3%;
          }
          .submitTask {
            background-color: #d9d9d9;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 50px;
            cursor: pointer;
            font-size: 35px;
          }
          .requirementsMetBtn {
            background-color: #d5922d;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 50px;
            cursor: pointer;
            font-size: 35px;
          }
        }
        .submitTaskSection {
          width: 100%;
          height: 15%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .submitTask {
            background-color: #d9d9d9;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 80%;
            cursor: pointer;
            font-size: 35px;
          }
          .requirementsMetBtn {
            background-color: #d5922d;
            color: white;
            border-radius: 35px;
            width: 40%;
            border: none;
            height: 80%;
            cursor: pointer;
            font-size: 35px;
          }
        }
      }
      .collateral-details{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: scroll;
        .categoryHeader {
          width: 100%;
          height: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          font-weight: 700;
          color: #213038;
        }
        .collateralDet {
          width: 100%;
          height: 90%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: unset;
          overflow: scroll;
          .colateralCards {
            width: 90%;
            display: flex;
            margin-top: 1%;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
.select-Comp-section{
  width: 95%;
  background-color: #EAEAEA;
  margin-top: 10px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #213038;
}
.selected-comp-active{
  width: 95%;
  background-color: #d5922d;
  margin-top: 10px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.adminAcceptRejectSect{
  width: 100%;
  height: 20%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  .acceptCredsbtn{
    font-weight: 700;
    font-size: 25px;
    color: #213038;
    cursor: pointer;
  }
  .rejectCredsbtn{
    font-weight: 700;
    font-size: 25px;
    color: #213038;
    background-color: #EAEAEA;
    cursor: pointer;
  }
}
