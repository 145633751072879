.parentSelect{
    border: 1px solid #404952;
    border-radius: 25px;
    width: 50%;
    margin-left: 1%;
    height: 30px;
    margin-top: 2%;
}

.descriptionField{
    resize: none;
    border-radius: 15px;
    width: 80%;
    height: 80px;
    padding: 1%;
}