.closedWonCards{
    background-color: rgb(143, 202, 114);
    max-width: 45%;
    min-height: 10%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-left: 2%;
    border-radius: 10px;
    padding: 15px;
    color: black;
}
.likelyToCloseCards{
    background-color: rgb(255, 223, 0, 0.6);
    max-width: 50%;
    min-height: 10%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-left: 2%;
    border-radius: 10px;
    padding: 15px;
    color: black;
}