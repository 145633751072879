.handlerHomePage{
    display: flex;
    flex-direction: row;
    background-color: #E4E4E4;
    .handlerSidebar{
        display: flex;
        flex-direction: column;
        width: 7%;
        height: 100%;
    }
    .handlerBody{
        display: flex;
        flex-direction: column;
        width: 93%;
        height: 100%;
        padding: 10px;
    }
}