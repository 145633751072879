$primaryColor: #213038;
$secondaryColor: rgba(213, 146, 45, 1);
$buttonBackground: #d4912d;
$fontWeightBold: 700;
$fontWeightMedium: 500;
$cardBackground: #ffffff;
$paddingBase: 1%;
$marginBase: 0.5%;

.mainLogin {

    .LoginCredentialsCard {

        justify-content: flex-start;

        .welcometxt {
            color: $primaryColor;
            font-size: 35px;
            font-weight: 700;
            margin: 0% 0% 5% 0%;
        }

        .textBox {
            position: relative;
            min-width: 90%;
            border: 1px solid #213038;
            height: 45px;
            justify-content: center;
            border-radius: 35px;
            /* margin: 5% 0% 5% 0%; */
            display: flex;
        }

        .span {
            background: $buttonBackground;
            color: white;
            font-size: 36px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2%;
            border-radius: 33px;
            cursor: pointer;
        }
    }
}
.pagenotactive{
    background:white ;
    height: 100vh;
    width: 100vw;
    color: $primaryColor;
    display: flex;  
    align-items: center;
    justify-content: center;
    h1{
        font-weight: 700;
        font-weight: 700;
        text-align: center;
        line-height: 1.5;
        background:lightgray;
        padding: 1%;
        border-radius: 15px;
        color:red;
    }
}