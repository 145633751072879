.clientsCards {
    width: 95%;
    margin-left: 2%;
    height: 99%;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    color: white;
    background-color: #404952;
    padding: 1%;
}
.searchClient {
    height: 50%;
    width: 70%;
    border-radius: 10px;
    margin-bottom: 1%;
    border: 1px solid white;
    color: white;
    background-color: transparent;
    margin-left: 1%;
}
.allClientsList{
    height: 85%;
    width: 95%;
    margin-left: 1%;
    padding: 1%;
    overflow: scroll;
}
.addNewClient{
    height: 15%;
    display: flex;
    flex-direction: row;
    width: 98%;
    padding: 1%;
}
.AddNewClientBtn{
    width: 20%;
    background-color: rgb(146, 140, 195);
    margin-right: 1%;
    margin-left: 5%;
    height: 50%;
    border-radius: 10px;
    padding: 15px;
    color: white !important;
    cursor: pointer;
}

.addClientCard {
    display: flex;
    overflow-y: scroll;
    background-color: #404952;
    position: absolute;
    top: 10%;
    width: 40%;
    max-height: 80%;
    color: white;
    left: 36%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.createUserbtn{
    background-color: #81d6f1;
    color: white;
    border-radius: 10px;
    width: 30%;
    border: none;
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: 50%;
}
.clientInfoInputs{
    border-radius: 10px;
    width: 80%;
    height: 40px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.clientsMappedCards {
    display: flex;
    width: 99%;
    height: 5%;
    border-radius: 5px;
    background-color: #373f46;
    color: white;
    margin-bottom: 1%;
    justify-content: left;
}
.clientDetailsForm{
    background-color: transparent;
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
}
.clientDetailsBody{
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}
.clientTabs{
    width: 30%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.clientTabsBody {
    width: 80%;
    justify-content: center;
    height: 100%;
    background-color: transparent;
    padding: 5px;
}

.clientDetailsButtons{
    height: 10%;
    border-radius: 10px;
    width: 80%;
    margin-left: 9%;
}
.backButton {
    width: 10%;
    border-radius: 10px;
    height: 30%;
    margin-top: 5%;
    margin-left: 2%;
    background-color: rgb(227, 54, 126);
}
.addNewQuoteSla{
    width: 15%;
    height: 30%;
    margin-top: 5%;
    border-radius: 10px;
}
.newQuoteInputs{
    width: 90%;
    height: 45px;
    border-radius: 10px;
}