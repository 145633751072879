.TotalSalesPieChartCard{
    width: 39%;
    margin-left: 2%;
    display: flex;
    color: white;
    background-color: #404952;
    flex-direction: column;
}
.AionsDoughChart{
    width: 27%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    background-color: #404952;
}
.AionsBottomDiv{
    margin-top: 3%;
    display: flex;
    width: 98%;
    margin-left: 1.5%;
}
.syncAionsData {
    background-color: rgba(227,54,126,1);
    color: white;
    border-radius: 10px;
    width: 15%;
    border: none;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.addAionsForeCast {
    width: 15%;
    color: white;
    background-color: rgba(146,140,195,1);
    margin-left: 2%;
}
.addNewActualsCard{
    width: 15%;
    color: white;
    background-color: rgba(227,54,126,1);
    cursor: pointer;
    margin-left: 2%;
}
.aionsDataInputs{
    border-radius: 10px;
    width: 80%;
    height: 30px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 10px;
    margin-bottom: px;
}

.categoryInputs {
    border-radius: 320px;
    width: 50%;
    height: 25px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btnCategory{
    border-radius: 5px;
    width: 50%;
    height: 25px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.updateForecast {
    width: 50%;
    color: white;
    margin-bottom: 1%;
    background-color: rgba(49,192,209,1);
    margin-left: 2%;
    height: 35%;
}
.updateActualsCard{
    width: 50%;
    color: white;
    background-color: rgba(143,202,114,1);
    cursor: pointer;
    margin-left: 2%;
    height: 35%;
}
.itemCard {
    width: 100%;
    background-color: #33c1d179;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1%;
    border-radius: 9px;
}
.removeForeOrActualButton {
    background-color: rgba(227,54,126,1);
    color: white;
    border-radius: 10px;
    margin-left: 5%;
    width: 30%;
    border: none;
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.updateForecastbtn{
    display: flex;
    position: relative;
    background-color: rgba(49,192,209,1);
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    width: 7%;
    justify-content: center;
    padding: 10px;
    color: white;
}
.COSCard{
    width: 10%;
    max-height: 15%;
    margin-left: 1%;
    background-color: rgba(146,140,195,1);
    border-radius: 14px;
    cursor: pointer;
    color: #fff;
}
.TelkomCard{
    width: 10%;
    max-height: 15%;
    margin-left: 1%;
    background-color: rgba(49,192,209,1);
    border-radius: 14px;
    cursor: pointer;
    color: #fff;
}
.OPEXCard{
    width: 10%;
    max-height: 15%;
    margin-left: 1%;
    background-color: rgba(227,54,126,1);
    border-radius: 14px;
    cursor: pointer;
    color: #fff;
}
.incomeCard{
    width: 10%;
    max-height: 15%;
    margin-left: 1%;
    background-color: rgba(143,202,114,1);
    border-radius: 14px;
    cursor: pointer;
    color: #fff;
}
