.DateDiv{
    margin-top: 2%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.AddMappedActualsDataDiv{

    margin-top: 2%;
    width: 100%;
    height: 70%;
    overflow-y: scroll;
}
.AddDataButton {
    background-color: #81d6f1;
    color: white;
    border-radius: 10px;
    width: 30%;
    border: none;
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.syncCompData {
  width: 15%;
  height: 10%;
  color: white;
  background-color: rgba(227,54,126,1);
  cursor: pointer;
  border-radius: 10px;
  margin-left: 3%;
}
.iconsRange{
  width: 50%;
  display: flex;
  flex-direction: row;
}
.configInputValues{
display: flex;
flex-direction: column;
width: 100%;
}
.configItemsDiv{
display: flex;
flex-direction: row;
width: 100%;
}

.metric-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Adjust spacing between metric sections */
  }
  
  .metric-header {
    /* Add styles for metric header */
  }
  
  .metric-title {
    /* Add styles for metric title */
  }
  
  .config-values {
    display: flex;
    flex-direction: column;
    /* Add spacing and other styles as needed */
  }
  
  .config-item {
    display: flex;
    align-items: center;
    /* Add styles for config item */
  }
  
  .config-input {
    /* Add styles for input element */
  }
  
  .button-key-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Add styles for button and key container */
  }
  
  .config-key {
    /* Add styles for key */
  }
  
  .config-button {
    /* Add styles for button */
  }
  