.funnel_container{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: lightgray;
    height:80% ;
    width: 80%;
    position: relative ;
    color: black;    
}
.talking{
    background-color: rgb(227, 54, 126);
    width : 100%;
}
.proposal{
    background-color:rgb(146, 140, 195) ;
    width: 80%;
};
.meeting{
    background-color:rgb(49, 192, 209) ;
    width: 40%;
}
.likely{
    background-color:rgb(255, 223, 0) ;
    width: 40%;
}
.closed{
    background-color:rgb(143, 202, 114) ;
    width: 20%;
}
.testP{
    width: 60%;
    background-color: rgb(49, 192, 209);
}
.proposal,.talking,.meeting,.likely,.closed,.testP{
    height: 15%;
    display: flex;
    text-align: center !important;
    align-items: center !important;
    color : black !important;
    font-size: 16px;
    font-weight: 300;
    margin: 3px !important;
}