.adminMobilePage{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 144%;
    position: absolute;
    top: 5% !important;
}
.homeCardsCompanyOrangeMobile{
    display: flex;
    width: 60%;
    position: relative;
    height: 5%;
    top: 5px;
    margin-bottom: 10px;
    border-radius: 25px;
    background-color: #ffffff;
    align-items: center;
    padding: 15px;
    color: black;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    box-shadow: 0 2px 3px 1px orange;
    transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
}
.homeCardsCompanyRedMobile {
    display: flex;
    width: 60%;
    position: relative;
    height: 5%;
    top: 5px;
    margin-bottom: 10px;
    border-radius: 25px;
    background-color: #ffffff;
    align-items: center;
    padding: 15px;
    color: black;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    box-shadow: 0 2px 3px 1px red;
    transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
}
.homeCardsCompanyGreenMobile {
    display: flex;
    width: 60%;
    position: relative;
    height: 5%;
    top: 5px;
    margin-bottom: 10px;
    border-radius: 25px;
    background-color: #ffffff;
    align-items: center;
    padding: 15px;
    color: black;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    box-shadow: 0 2px 3px 1px green;
    transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
}
.homeCardsCompanyBlueMobile {
    display: flex;
    width: 60%;
    position: relative;
    height: 5%;
    top: 5px;
    margin-bottom: 10px;
    border-radius: 25px;
    background-color: #ffffff;
    align-items: center;
    padding: 15px;
    color: black;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    box-shadow: 0 2px 3px 1px rgb(3 45 90);
    transition: 0 4px 8px 0 rgba(134, 10, 229, 0.2) 0.3s ease-in-out;
}