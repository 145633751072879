.overviewIndicatorCard{
    background-color: #eff4f4;
    width: 72%;
    height: 200px;
    margin-top: 10px;
    margin-left: 13%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.overviewCardTitle{
    color: black;
    font-weight: bold;
    font-size: 20px;
}

.OverviewContentDiv{
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 21px;
}