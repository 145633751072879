.aionsGraph{
    width: 100%;
}

.totalSalesSliderCard{
    width: 10%;
    margin-left: 1%;
    background-color: rgba(143,202,114,1);
    border-radius: 14px;
    padding: 15px;
    cursor: pointer;
    color: #fff;
}
.opexSliderCard {
    width: 10%;
    margin-left: 1%;
    background-color: rgba(227,54,126,1);
    border-radius: 14px;
    padding: 15px;
    cursor: pointer;
    color: #fff;
}
.COSSliderCard {
    width: 10%;
    margin-left: 1%;
    background-color: rgba(146,140,195,1);
    border-radius: 14px;
    padding: 15px;
    cursor: pointer;
    color: #fff;
}
.telkomSliderCard {
    width: 10%;
    margin-left: 1%;
    background-color: rgba(49,192,209,1);
    border-radius: 14px;
    padding: 15px;
    cursor: pointer;
    color: #fff;
}
.sliderStyle{
    accent-color: white;
    width: 100%;
}