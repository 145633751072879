.companiesReportPage{
    width: 99%;
    height: 97%;
    display: flex;
    flex-direction: column;
    .compNameHeader{
        height: 10%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #213038;
        font-size: 25px;
        font-weight: 700;
        line-height: 30.26px;
    }
    .availComps{
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .compContainer{
            width: 99%;
            height: 8.5%;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            color: #213038;
            padding: 5px;
            border-radius: 35px;
            margin-bottom: 1%;
            align-items: center;
            justify-content: space-between;
            font-size: 25px;
            font-weight: 300;
            line-height: 30.26px;
            .MetricReportTabs{
                width: 25%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .profitSection{
                background-color: #EFB86F;
                display: flex;
                flex-direction: row;
                width: 45%;
                height: 80%;
                border-radius: 35px;
                justify-content: center;
                align-items: center;
                padding: 5px;
                font-size: 20px;
                .profitLossValue{
                    background-color: #EFB86F;
                    width: 55%;
                    border-radius: 35px;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24.2px;
                }
            }
            }
            
        }
    }
}