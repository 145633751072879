.OverViewPage {
    width: 99%;
    height: 97%;
    display: flex;
    flex-direction: column;
    color: #213038;
    margin-left: 1%;
    .income-loss-container {
        width: 99.7%;
        height: 7.5%;
        display: flex;
        flex-direction: row;
        padding: 5px;
        align-items: center;
        justify-content: space-between;

        .ProfitLossContrainer {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #213038;
            color: #fff;
            border-radius: 35px;
            font-size: 20px;
            font-weight: 300;
            position: relative;

            .labelPlaceholder {
                position: absolute;
                left: 105%;
                background-color: #fff;
                height: 95%;
                width: 70%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-radius: 35px;
                color: #213038;
            }
        }

        .profit-lossSection {
            width: 25%;
            height: 80%;
            padding: 5px;
            display: flex;
            flex-direction: row;
            background-color: #ffffff;
            margin: 5px;
            justify-content: space-between;
            align-items: center;
            border-radius: 35px;
            font-size: 20px;
            font-weight: 300;

            .profit-loss-value {
                height: 100%;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #EFB86F;
                border-radius: 35px;
                font-weight: 700;
                font-size: 20px;
                line-height: 24.2px;
                color: #213038;
            }
        }

        .period-section {
            width: 15%;
            height: 80%;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px;
            padding: 5px;
            border-radius: 35px;
            color: #213038;
            font-weight: 300;
            font-size: 20px;
            position: relative;
            bottom: 0px;

            .period-pointer {
                position: absolute;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid #ffffff;
                z-index: 7;
                top: 65%;
                right: 40%;
            }

            .period-container {
                width: 200px;
                height: 140px;
                position: absolute;
                right: 0.5%;
                display: flex;
                border-radius: 25px;
                background-color: #ffffff;
                top: 85%;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                z-index: 5;

                .period-container-options {
                    width: 95%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 35px;
                    background-color: #EAEAEA;
                    font-weight: 300;
                    font-size: 20px;
                    height: 30%;
                    line-height: 24.2px;
                    color: #213038;
                    cursor: pointer;
                }
            }
        }
    }
    .ViewPredictedDataSect{
        width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .overviewCharts {
        width: 100%;
        height: 92.5%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        overflow: scroll;
        padding: 5px;

        .overviewLinechart {
            width: 99%;
            height: 75%;
            background-color: #ffffff;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
            margin: 5px;

            .legend-gauge-container {
                width: 100%;
                height: 20%;
                display: flex;
                flex-direction: row;

                .gauge-container {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    svg {
                        width: 200px;
                        height: 140px;
                    }

                    .runway-gauge-descrition {
                        font-size: 20px;
                        font-weight: 400;

                        .x-month-values {
                            margin: 0px;
                            font-weight: 700;
                            font-size: 25px;
                        }
                    }
                }

                .line-chart-legend {
                    width: 40%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .income-expense-container {
                        width: 100%;
                        height: 50%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                    }

                    .cashflow-burnrate-container {
                        width: 100%;
                        height: 50%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        p {
                            margin-right: 20px;
                        }

                    }
                }
            }

            .line-ChartSect {
                width: 100%;
                height: 80%;
                display: flex;
                flex-direction: row;
            }
        }

        .newOverViewcard {
            width: 99%;
            height: 80%;
            background-color: #ffffff;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
            margin: 5px;

            .runwaySection {
                width: 100%;
                height: 15%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-size: 20px;
                font-weight: 300;

                .gaugeContainer {
                    width: 27%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 400;

                    svg {
                        width: 180px;
                        margin-top: 5%;
                    }
                }

                .forecastToogleSwitch {
                    width: 15%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                }
            }

            .chartContainer {
                width: 100%;
                height: 75%;
                display: flex;
                flex-direction: row;
            }

            .availLegendsContainer {
                width: 100%;
                height: 10%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-size: 20px;
                font-weight: 300;
                align-items: center;

                p {
                    margin-right: 35px;
                }

                .income-Label {
                    height: 15%;
                    width: 5%;
                    background-color: #D4912D;
                    border-radius: 35px;
                    margin-right: 8px;
                }

                .expense-Label {
                    height: 15%;
                    width: 5%;
                    background-color: #A62626;
                    border-radius: 35px;
                    margin-right: 8px;
                }

                .burnrate-Label {
                    height: 15%;
                    width: 5%;
                    background-color: #7A8F9D;
                    border-radius: 35px;
                    margin-right: 8px;

                }

                .cashflow-Label {
                    height: 15%;
                    width: 5%;
                    background-color: #213038;
                    border-radius: 35px;
                    margin-right: 8px;
                }
            }
        }

        .newHorizontalChartSection {
            width: 49%;
            height: 80%;
            background-color: #ffffff;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
            margin: 5px;
            align-items: center;

            .filteredDateandTotalsSect {
                width: 96%;
                height: 10%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-weight: 700;
            }

            .horizontalChartSect {
                width: 100%;
                height: 80%;
                display: flex;
            }

            .legendsSection {
                width: 100%;
                height: 10%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                p {
                    margin-right: 15px;
                }

                .actual-Label {
                    height: 15%;
                    width: 10%;
                    background-color: #D4912D;
                    border-radius: 35px;
                    margin-right: 8px;
                }

                .forecast-Label {
                    height: 15%;
                    width: 10%;
                    background-color: #213038;
                    border-radius: 35px;
                    margin-right: 8px;
                }

                .difference-Label {
                    height: 15%;
                    width: 10%;
                    background-color: #7A8F9D;
                    border-radius: 35px;
                    margin-right: 8px;

                }
            }
        }

        .overview-hozirontal-barcharts {
            width: 49%;
            height: 75%;
            background-color: #ffffff;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
            margin: 5px;
            align-items: center;

            .periodselected-container {
                width: 97%;
                height: 10%;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 25px;
                justify-content: space-between;
                font-weight: 700;
            }

            .chart-legends {
                width: 100%;
                height: 6%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-weight: 300;
                font-size: 18px;

                p {
                    margin-right: 20px;
                }

                .actual-Label {
                    height: 30%;
                    width: 13%;
                    background-color: #D4912D;
                    border-radius: 35px;
                    margin-right: 8px;
                }

                .forecast-Label {
                    height: 30%;
                    width: 13%;
                    background-color: #213038;
                    border-radius: 35px;
                    margin-right: 8px;
                }

                .profit-loss-Label {
                    height: 30%;
                    width: 13%;
                    background-color: #7A8F9D;
                    border-radius: 35px;
                    margin-right: 8px;
                }
            }

            .chart-section {
                width: 100%;
                height: 75%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

            .catchup-totalValue-container {
                height: 9%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                font-weight: 700;
            }
        }

        .overview-vertical-barcharts {
            width: 49%;
            height: 75%;
            background-color: #ffffff;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
            margin: 5px;

            .chart-header-section {
                width: 100%;
                height: 10%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 25px;
            }

            .chart-legends {
                width: 100%;
                height: 7%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .actual-Label {
                    height: 30%;
                    width: 13%;
                    background-color: #D4912D;
                    border-radius: 35px;
                    margin-right: 8px;
                }

                .forecast-Label {
                    height: 30%;
                    width: 13%;
                    background-color: #213038;
                    border-radius: 35px;
                    margin-right: 8px;
                }
            }

            .chart-canvas {
                width: 100%;
                height: 83%;
                display: flex;
                justify-content: center;

                svg {
                    width: 95%;
                }
            }
        }
    }
    .viewMetricsScreen{
        width: 100%;
        height: 92.5%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;    
        .tabSection{
            width: 100%;
            height: 10%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .forecastActSect{
                width: 25%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .actualTab{
                    width: 48%;
                    height: 75%;
                    display: flex;
                    background-color: #fff;
                    color: #213038;
                    border-radius: 35px;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                    cursor: pointer;
                    font-weight: 300;
                }
                .forecastTab{
                    width: 48%;
                    height: 75%;
                    display: flex;
                    background-color: #fff;
                    color: #213038;
                    border-radius: 35px;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                    font-weight: 300;
                    cursor: pointer;
                }
            }
        }
        .metricSection{
            width: 100%;
            height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;     
            .metricTopSect{
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 10%;
            }
            .mappedMetricsSect{
                width: 100%;
                height: 80%;
                display: flex;
                flex-direction: column;
                overflow: scroll;
                .availMetricCArd{
                    min-height: 10%;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 35px;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 300;
                    justify-content: flex-start;
                    margin-bottom: 1%;
                }
            }
        }
    }
   
}
.ActAddMetric{
    width: 90%;
    height: 85%;
    position: fixed;
    display: flex;
    top: 11%;
    left: 8%;
    align-self: center;
    border-radius: 35px;
    background-color: #fff;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    .actTopSct{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        p{
            font-size: 22px;
            font-weight: 700;
        }
    }
    .divisionAvailMetrics{
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: scroll;
        .availActualCard{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 35px;
            min-height: 10%;
            background-color: #E4E4E4;
            margin-bottom: 5px;        
            .actionSect{
                height: 61%;
                display: flex;
                align-items: center;
                flex-direction: row;
                align-content: center;
                justify-content: space-evenly;
                width: 10%;
            }
        }
    }
    .actionSection{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .cancelButton{
            width: 15%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: none;
            font-size: 20px;
            border-radius: 35px;    
            background-color: rgb(166, 38, 38);
            color: #213038;
            cursor: pointer;
            margin-right: 1%;    
        }
        .saveButton{
            width: 15%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: none;
            font-size: 20px;
            border-radius: 35px;
            background-color:rgb(212, 145, 45);
            color: #213;
            cursor: pointer;
        }
    }
    .closeCardSect {
        position: absolute;
        right: 1.5%;
        top: 2%;
    }
    .dateAndTypeSect {
        width: 95%;
        height: 15%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        color: #213038;
        .changeStatusHover {
            width: 15%;
            display: flex;
            height: 45%;
            justify-content: center;
            border-radius: 35px;
            align-items: center;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: 1%;
            font-weight: 700;
            color: #213038;
            font-size: 20px;
            border: solid 1px #213038;
            position: relative;
            .placeHolder {
                position: absolute;
                bottom: 30%;
                left: 20%;
                width: 55%;
                background-color: #fff;
                padding-left: 5px;
                font-weight: 300;
            }
            .estimatedCredsCard {
                border: none;
                background-color: transparent;
                font-size: 25px;
                width: 25%;
                display: flex;
                font-weight: 700;
            }
            .updateDeliveryDate {
                border: none;
                background-color: transparent;
                font-size: 18px;
                font-weight: 700;
                line-height: 30.26px;
                outline: none;
            }
            .statusChangePointer {
                position: absolute;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 22px solid #ffffff;
                z-index: 7;
                top: 65%;
                right: 40%;
            }
            .statusChanageContainer {
                position: absolute;
                background-color: #ffffff;
                width: 200px;
                height: 150px;
                top: 115%;
                right: -5%;
                display: flex;
                border-radius: 25px;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                z-index: 5;
                .statusChangeOptions {
                    width: 90%;
                    border-radius: 25px;
                    background-color: #eaeaea;
                    color: #213038;
                    height: 28%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    justify-content: center;
                    .statusValue {
                        font-size: 20px;
                        font-weight: 300;
                        color: #213038;
                        line-height: 24.2px;
                    }
                }
            }
        }
        .departmentInput {
            position: relative;
            min-width: 268.22px;
            border: 1px solid #213038;
            height: 40px;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 35px;
            flex-direction: column;
            margin-top: 1%;
            margin-left: 1%;
            margin-right: 1%;
        }
        .monthTypeContainer {
            width: 20%;
            height: 50%;
            border: 1px solid #213038;
            display: flex;
            flex-direction: row;
            position: relative;
            color: #213038;
            border-radius: 35px;
            align-items: center;
            justify-content: center;
            .placeHolder {
                position: absolute;
                bottom: 40%;
                left: 15%;
                padding-left: 2%;
                width: 40%;
                background-color: #fff;
                font-weight: 300;
                font-size: 18px;
            }
            .values{
                font-weight: 700;
                font-size: 22px;
                color:#213038;
            }
        }
    }
    .categoryMetricsSection {
        width: 93%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        overflow-y: scroll;
        .incomeMetricCard {
            width: 100%;
            min-height: 465px;
            background-color: #fff;
            border-radius: 35px;
            border: solid 1px #213038;
            display: flex;
            flex-direction: column;
            color: #213038;
            align-items: center;
            font-weight: 700;
            font-size: 23px;
            margin-bottom: 1%;
            .metricNameSect {
                display: flex;
                justify-content: space-between;
                width: 97%;
                height: 5%;
                align-content: center;
                flex-direction: row;
                align-items: center;
                padding: 1%;
                .totalAmountAndDropdown {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            .metricCategoriesSect {
                width: 97%;
                height: 75%;
                padding: 1%;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                align-items: center;
                .subCatsContainer {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 75%;
                    overflow-y: scroll !important;
                }
                .categoryHeaderSect {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 15%;
                    align-items: center;
                    justify-content: space-between;
                    .totalAmountAndDropdown {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
            .subCategoryContainer {
                width: 100%;
                display: flex;
                flex-direction: rosw;
                align-items: center;
                height: 15%;
                justify-content: space-between;
                input {
                    border: none;
                    border-bottom: 2px solid #898989;
                    color: #213038;
                    font-weight: 700;
                    font-size: 20px;
                    outline: none;
                }
                input::placeholder {
                    color: #213038;
                    font-weight: 700;
                    font-size: 20px;
                }
                .totalAmountAndDropdown {
                    width: 17%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            button {
                background-color: #D4912D;
                width: 20%;
                height: 15%;
                border: none;
                border-radius: 35px;
                color: #ffffff;
                cursor: pointer;
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
    .btnSection {
        width: 100%;
        height: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
        .saveBtn {
            background-color: #D4912D;
            width: 20%;
            height: 90%;
            border: none;
            border-radius: 35px;
            color: #ffffff;
            cursor: pointer;
            font-size: 25px;
            font-weight: 700;
        }
    }
};

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2.5px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #D4912D;
}

/* Move the slider/circle when checked */
input:checked+.slider:before {
    transform: translateX(22px);
}

// .switch-container {
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     width: 410%;
//   }

//   .switch {
//     width: 40px;
//     height: 20px;
//     background-color: #ccc;
//     border-radius: 10px;
//     position: relative;
//     cursor: pointer;
//   }

//   .toggle {
//     width: 16px;
//     height: 16px;
//     background-color: #fff;
//     border-radius: 50%;
//     position: absolute;
//     transition: transform 0.2s ease-in-out;
//   }

//   .switch.on {
//     background-color: #D4912D;
//   }

//   .switch.off {
//     background-color: #ccc;
//   }

//   .switch.on .toggle {
//     transform: translateX(20px);
//   }

//   .switch.off .toggle {
//     transform: translateX(0);
//   }
.forecastTogg {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 10%;
    height: 100%;
    font-weight: 300;
    font-size: 20px;
}

/////// New Overview Style.
.OverViewPage2 {
    display: flex;
    flex-direction: column;
    height: 97%;
    width: 100%;
    color: #213038;

    .overViewTopSect {
        width: 96.5%;
        height: 8%;
        display: flex;
        flex-direction: row;
        padding: 10px;
        align-self: center;
        align-items: center;
        justify-content: space-between;
        .divisionSelector {
            width: 15%;
            height: 90%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: #fff;
            border-radius: 35px;
            color: #213038;

            .divisionOptions {
                position: absolute;
                width: 100%;
                height: 350px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 35px;
                top: 102%;
                align-items: center;
                justify-content: space-evenly;

                .divisionOption {
                    width: 90%;
                    height: 15%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 109px;
                    background: #eaeaea;
                    cursor: pointer;
                }
            }
        }
        .newDateFilterSelector{
            width: 15%;
            height: 90%;
            display: flex;
            align-items: center;
            background-color: #fff;
            justify-content: center;
            border-radius: 35px;
            cursor: pointer;
            position: relative;
            .calebderSect{
                position: absolute;
                background-color: #fff;
                width: 200px;
                height: 230px;
                border-radius: 25px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
                top: 103%;
                display: flex;
                padding: 5px;
                align-items: center;
                justify-content: center;              
            }
        }
        .dateFilterSelector {
            width: 15%;
            height: 90%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: #fff;
            border-radius: 35px;
            color: #213038;

            .dateSelectorOptions {
                position: absolute;
                width: 100%;
                height: 185px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 35px;
                top: 102%;
                align-items: center;
                justify-content: space-evenly;

                .divisionOption {
                    width: 90%;
                    height: 28%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 109px;
                    background: #eaeaea;
                    cursor: pointer;
                }
            }
        }
    }

    .availDivisionsChartSect {
        width: 98.5%;
        height: 87%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        // background-color: pink;
        overflow-y: scroll;

        .availDivisionCard {
            width: 98.5%;
            min-height: 1000px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            // background-color: #fff;
            padding: 10px;

            // border:2px solid #213038;
            .divisionName {
                font-weight: 700;
                color: #213038;
                font-size: 25px;
                margin: unset;
                width: 98%;
            }

            .incomeExpenseChartContainer {
                width: 100%;
                height: 45%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .expensesIncChartSect {
                    width: 49%;
                    margin: 7px;
                    height: 98%;
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    border-radius: 35px;
                    align-items: center;
                    justify-content: center;

                    .chartTopSect {
                        width: 95%;
                        height: 10%;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;
                        font-size: 25px;
                        font-weight: 700;
                    }

                    .chart {
                        width: 100%;
                        height: 80%;
                        display: flex;
                    }

                    .legendsSection {
                        width: 100%;
                        height: 10%;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: center;
                        color: #213038;
                        font-weight: 300;
                        font-size: 18px;

                        .actual-Label {
                            width: 10%;
                            height: 20%;
                            background-color: #D4912D;
                            margin-right: 1%;
                            border-radius: 35px;
                        }

                        .forecast-Label {
                            width: 10%;
                            height: 20%;
                            margin-right: 1%;
                            background-color: #213038;
                            border-radius: 35px;
                        }

                        .difference-Label {
                            width: 10%;
                            height: 20%;
                            margin-right: 1%;
                            background-color: rgb(122, 143, 157);
                            border-radius: 35px;
                        }
                    }
                }
            }

            .overViewChartContainer {
                width: 98%;
                height: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 1%;

                .overViewChartSect {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #fff;
                    border-radius: 35px;

                    .runwayAndOverviewChartSect {
                        width: 98%;
                        height: 15%;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;

                        .gaugeContainer {
                            width: 27%;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            font-weight: 400;

                            svg {
                                width: 180px;
                                margin-top: 5%;
                            }
                        }

                        .foreSect {
                            width: 10%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-direction: row;
                        }
                    }

                    .overviewGraphCard {
                        width: 100%;
                        height: 80%;
                        display: flex;
                    }

                    .overviewChartLegends {
                        width: 100%;
                        height: 10%;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: center;
                    
                        .actual-Label {
                            width: 5%;
                            height: 20%;
                            background-color: #EFB86F;
                            margin-right: 1%;
                            border-radius: 35px;
                        }

                        .forecast-Label {
                            width: 5%;
                            height: 20%;
                            margin-right: 1%;
                            background-color: #A62626;
                            border-radius: 35px;
                        }

                        .difference-Label {
                            width: 5%;
                            height: 20%;
                            margin-right: 1%;
                            background-color: pink;
                        }
                    }
                }
            }
        }
        .overallPHeader{
            font-weight: 700;
            width: 95%;
            display: flex;
            align-self: center;
            font-size: 22px;
        }
        .aionsOverviewChart{
            width: 96.5%;
            min-height: 85%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            border-radius: 35px;
            align-self: center;
            .aionsRunwayChart{
                width: 100%;
                height: 20%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-weight: 300;
                font-size: 20px;
                .runwaySectChart{
                    width: 35%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                }
                .forecastSect{
                    width: 10%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-direction: row;
                }
            }
            .aionsOverviewCanvas{
                width: 100%;
                height: 73%;
                display: flex;
                align-items: center;
            }
            .aionsOverviewLegends{
                width: 100%;
                height: 7%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                p {
                    margin-right: 15px;
                }
                .actual-Label {
                    height: 30%;
                    width: 5%;
                    background-color: #D4912D;
                    border-radius: 35px;
                    margin-right: 8px;
                }
                .forecast-Label {
                    height: 30%;
                    width: 5%;
                    background-color: #A62626;
                    border-radius: 35px;
                    margin-right: 8px;
                }
            }
        }
    }
}
.syncAVDataBtn{
    width: 15%;
    height: 90%;
    border-radius: 35px;
    border: none;
    background-color: #D4912D;
    color: #213038;
    font-weight: 300;
    font-size: 18px;
    cursor: pointer;
}