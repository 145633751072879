.compDataDiv{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.companyFigureCards{
    margin-top: 82px !important;
    width: 20% !important;
    background-color: rgb(40, 44, 52);
    box-shadow: 0 2px 3px 1px rgb(7 122 37);
}
.companyFigureCardsNoData{
    display: flex;
    border-radius: 17px;
    width: 72%;
    height: 40%;
    margin-top: 5%;
    margin-left: 1%;
    padding-right: 23%;
    margin-bottom: 2%;
    color: rgb(255 0 0)!important;
    background-color: rgb(40, 44, 52);
    box-shadow: 0 2px 3px 1px rgb(255 0 0)!important;
    justify-content: flex-start;
}
.companyFigureCardsNoDataCreditsRed{
    margin-left: 11px!important;
    height: 50%!important;
    display: flex;
    margin-right: 1%;
    flex-direction: row;
    border-radius: 17px!important;
    width: 66.5% !important;
    margin-top: 2%!important;
    margin-bottom: 0%!important;
    color: rgb(255 0 0)!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px rgb(255 0 0)!important;
}
.companyFigureCardsNoDataCreditsRedMoible{
    overflow-x: scroll;
    display: flex;
    border-radius: 17px;
    width: 8%;
    margin-left: -1%;
    height: 29%;
    margin-top: 8%;
    padding-right: 23%;
    margin-bottom: 2%;
    color: rgb(255 0 0)!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px rgb(255 0 0)!important;
}
.companyFigureCardsNoDataCreditsff8c00{
    margin-left: 11px!important;
    height: 50%!important;
    display: flex;
    margin-right: 1%;
    flex-direction: row;
    border-radius: 17px!important;
    width: 66.5% !important;
    margin-top: 2%!important;
    margin-bottom: 0%!important;
    color: #ff5900!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px #ff5900!important;;
}
.moreInfo{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.CommentsComps{
    width: 60%;
display: flex; 
margin: 2%;
}
.companyFigureCardsNoDataCreditsff8c00Mobile{
    overflow-x: scroll;
    display: flex;
    border-radius: 17px;
    width: 8%;
    margin-left: -1%;
    height: 29%;
    margin-top: 8%;
    padding-right: 23%;
    margin-bottom: 2%;
    color: #ff5900!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px #ff5900!important;;
}
.companyFigureCardsNoDataCreditsOrange{
    margin-left: 11px!important;
    height: 50%!important;
    display: flex;
    margin-right: 1%;
    flex-direction: row;
    border-radius: 17px!important;
    width: 66.5% !important;
    margin-top: 2%!important;
    margin-bottom: 0%!important;
    color: #ffb700!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px #ffb700!important;
}
.companyFigureCardsNoDataCreditsOrangeMobile{
    overflow-x: scroll;
    display: flex;
    border-radius: 17px;
    width: 8%;
    margin-left: -1%;
    height: 29%;
    margin-top: 8%;
    padding-right: 23%;
    margin-bottom: 2%;
    color: #ffb700!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px #ffb700!important;
}
.companyFigureCardsNoDataCredits50{
    margin-left: 11px!important;
    height: 50%!important;
    display: flex;
    margin-right: 1%;
    flex-direction: row;
    border-radius: 17px!important;
    width: 66.5% !important;
    margin-top: 2%!important;
    margin-bottom: 0%!important;
    color: #aebe02!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px #aebe02!important;
}
.companyFigureCardsNoDataCredits50Mobile{
    overflow-x: scroll;
    display: flex;
    border-radius: 17px;
    width: 8%;
    margin-left: -1%;
    height: 29%;
    margin-top: 8%;
    padding-right: 23%;
    margin-bottom: 2%;
    color: #aebe02!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px #aebe02!important;
}
.companyFigureCardsNoDataCreditsGreen{
    margin-left: 11px!important;
    height: 50%!important;
    display: flex;
    margin-right: 1%;
    flex-direction: row;
    border-radius: 17px!important;
    width: 66.5% !important;
    margin-top: 2%!important;
    margin-bottom: 0%!important;
    color: rgb(60, 165, 3)!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px rgb(60, 165, 3)!important;
}
.companyFigureCardsNoDataCreditsGreenMobile{
    overflow-x: scroll;
    display: flex;
    border-radius: 17px;
    width: 8%;
    margin-left: -1%;
    height: 29%;
    margin-top: 8%;
    padding-right: 23%;
    margin-bottom: 2%;
    color: rgb(60, 165, 3)!important;
    align-items: center!important;
    background-color: rgb(40, 44, 52)!important;
    box-shadow: 0 2px 3px 1px rgb(60, 165, 3)!important;
}
.compDataInnerDiv{
    display: flex;
    justify-content: space-between;
    height: 86%;
    width: 100%;
    margin-top: -2%;
}
.compDataInnerDivMobile{
    display: flex;
    justify-content: space-between;
    height: 11%;
    width: 100%;
    margin-top: -7%;
    overflow-x: scroll !important;
}
.bottomTabDiv{
display: flex;
border-radius: 0%;
border-color: aqua;
border: 5px;
}

.mappedCredits{
    display: flex;
    flex-direction: column;
}

.creditsCard{
    display: flex;
    position: relative;
    height: 55%;
    width: 28%;
    border-radius: 25px;
    background-color: rgb(40, 44, 52);
    justify-content: center;
    padding: 15px;
    margin-top: 2%;
    color: white;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 2px 3px 1px rgb(3, 165, 162)!important;
}
.creditsP{
    height: 0%;
    margin-top: 5%;
}
.jobCards{
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    align-items: stretch;
    margin: 2%;
    margin-top: 0%;
}
.jobCardsMobile{
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: space-between;
    align-items: stretch;
    margin: 2%;
    margin-top: 0%;
}
.viewCompanyJobMobile{
    position: absolute;
    top: 7%;
    right: 2%;
    width: 90%;
    overflow-y: scroll;
    display: flex;
    color: white;
    background-color: rgb(40, 44, 52);
    box-shadow: 1px 3px 4px 3px rgba(0, 0, 0, 0.604);
    flex-direction: column;
}
.CommentCardsMobile {
    width: 100%;
    height: 70%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.CommentsCompsMobile {
    width: 99%;
    display: flex;
    margin: 2%;
}
