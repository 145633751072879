.mobileTabBarItem{
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.tabBarDesc{
    margin: 0px;
    padding: 0px;
}

.tabBarItemActive{
    color: #33C0D1 !important;
}