.dashboardContent
.permissions{
    flex-direction: column;

}
.selectUpdateDateDiv{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.selectElemen{
  border-radius: 320px;
    width: 30%;
    height: 25px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.closeEdit {
  position: absolute;
  right: 0px; top: 0px; width:20px; color: black;
  margin-top:-15px; margin-right:-15px; 
  padding-left: 3px; padding-top: 1px;
  cursor:pointer; z-index: -1;
  font-size:16px; font-weight:bold;

}
.totalStyle{
  width: 50%;
}

.dropdownCreditsDepartment{
  border: 1px solid #404952;
  border-radius: 25px;
  padding: 5px;
  width: 36%;
}

.customMappedMetricsSettings{
  background-color: #282C34;
    color: white !important;
    width: 100%;
    height: 15%;
    display: flex !important;
    border-color: 1px solid #33C0D1;
}
.metricsDiv{
  width: 99%;
    margin-top: 1%;
    margin-left: 1%;
}
.customSelectedMetricsSettings{
  background-color: #282C34;
  color: white !important;
  display: flex !important;
  margin-top: 1%;
  width: 77%;
  margin-left: 10%;
  border-radius: 8px !important;
  flex-direction: column;
}
.customAvailableMetricsSettings{
  background-color: #282C34;
  color: white !important;
  display: flex !important;
  margin-top: 1%;
  height: 350px !important;
  width: 77%;
  margin-left: 10%;
  border-radius: 8px !important;
  flex-direction: column;
}
.addMetricsIconDelete{
  width: 4%;
  margin-top: 1%;
  margin-left: -5%;
  display: flex;
}
.addMetricsIcon{
  width: 4%;
  margin-top: 2%;
  margin-left: 1%;
  display: flex;
}

.tableDiv{
  width: 100%;
  background-color: #80808040;
  color: #d4dfe0fa;
}
.tablePtag{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.updateInputsCard{
  position: absolute !important;
  height: 107% !important;
  background-color: #e1f4f6;
  width: 95% !important;
  top: 1% !important;
  left: 1%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.Tabs{
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.updateTargetsPage{
  margin-left:18%;
  display: flex;
  width: 65%;
  flex-direction: column;
}
.buttonForCompanyPages {
  background-color: #81d6f1;
  color: white;
  border-radius: 120px;
  width: 39%;
  border: none;
  height: 30px;
  margin-top: 9px;
  margin-bottom: 15px;
  cursor: pointer;
}
.tabCardsSelectedForCompanies {
  color: #fff;
    width: 11%;
    display: flex;
    background-color: transparent;
    border-radius: 50px;
    height: 14px;
    border: 1px solid #33C0D1;
    margin-bottom: -4px;
    cursor: pointer;
    background-color: #33c1d184;
}
.addCard{
  width: 30%;

}
.addCustomConfigurations{
  width: 70%
}

.customMetricsCard{
  display: flex;
    position: relative;
    border-radius: 30px;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 0%;
    margin-top: 2%;
    color: black;
}

.addCustomMetricsButton{
  background-color: #81d6f1;
  color: white;
  border-radius: 120px;
  width: 20%;
  border: none;
  height: 30px;
  margin-left: 80%;
  margin-top: 2%;
  margin-top: 7px;
  margin-bottom: 15px;
  cursor: pointer;
}
.customConfigSettings {
  background-color: transparent;
    color: white !important;
    display: flex !important;
    flex-wrap: nowrap;
    margin-top: -1%;
    height: 10%;
    justify-content: flex-start;
    height: 44px !important;
    margin-left: 75%;
    width: 43%;
    border-radius: 8px !important;
}
.customMetricsSettings {
  background-color: #282C34;
  color: white !important;
  display: flex !important;
  flex-wrap: nowrap;
  margin-top: 2%;
  height: 10%;
  justify-content: flex-start;
  height: 44px !important;
  width: 79%;
  margin-left: 10%;
  border-radius: 8px !important;
}
.metricsName{
  width: 90%;
}
.configButton{
  width: 30%;
  display: flex;
}

.Icons{
  height: 85%;
  margin-top: 12%;
  margin-left: 60%;
}
.IconsToggle{
  height: 85%;
  width: 31%;
  margin-top: 12%;
  margin-left: 60%;
}

.toggleIcon{
  width: 10%;
  height: 50%;
}
.tabCardsForCompanies {
  color: #fff;
  width: 125px;
  display: flex;
  margin: 1%;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid #33C0D1;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.targets {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.targetsMobile{
  width: 31%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.updateTargetsTab{
  display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
}
.newUserCard{
    display: flex;
    flex-direction: column;
    /* border: 1px solid #33C0D1; */
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.closeEdit{
  position: absolute;
  top: 15px;
}
.setPStyle{
  margin-bottom: 0px;
  width: 100%;
}
.setPStyleMobile{
  margin-bottom: -26px;
  width: 58%;
  margin-left: -41%;
}
.editDiv {
  display: flex;
  flex-direction: row;

  top: 25px;
  right: 30%;
}
 .selectEditOption{
  color: #000000;
  width: 150px;
  height: 15px;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid #33C0D1;
  margin-bottom:15px;
  cursor: pointer;
} 
.selectEditOptionMobile{
  color: #000000;
  width: 50px;
  height: 15px;
  margin-left: 45px;
  background-color: #fff;
  margin-right: 25px;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid #33C0D1;
  margin-top: 10px;
  margin-bottom:-15px;
  cursor: pointer;
} 
.selectTrgetEditOptionMobile{
  color: #39bde5;
  display: flex;
  background-color: transparent;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: -15px;
  cursor: pointer;
} 

.selectTrgetEditOption{
  color: #39bde5;
  width: 50px;
  height: 15px;
  margin-left: -14px;
  margin-right: 29vw;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: -15px;
  cursor: pointer;
}
.chooseTarOptionMobile{
  display: flex;
  flex-direction: row;
  margin-top: -8%;
  width: 0%;
  margin-bottom: 11%;
  height: 6%;
}
.editCardCenter{
    width: 39%;
}
.cancelButton{
  width : 100%;

}

.nameOfCompany{
  display: flex;
  flex-direction: row;
  width: 470px;
}
.editDiv{
  display: flex;
  flex-direction: row;
}

.firstNameInput,.lastNameInput,.emailInput,.PasswordInput,.confirmationInput{
    width: 80%;
    display:flex;
    flex-direction: row;
}
.input-container{
    margin-bottom: 100;
}
.newUser{
    width: 40vw;
    display:flex;
    flex-direction: row;
}
.existingUser{
    width: 40vw;
    display:flex;
    top: 0;
  
}
.targetName{
  justify-content: left;
  width: 200%;
}
.inputDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
}
.closeDiv{
  right: 5px;
  top: 0%;
}
.closeEditCard{
  position: absolute;
  top: 1px;
  right: 20px;
  font-size: large;
  font: bold;
  cursor: pointer;
}
.editCard{
  position: absolute !important;
  height: 80% !important;
  width: 80% !important;
  top: 18% !important;
  display: flex !important;
  align-items: flex-start;
  justify-content: space-around;
}
.labelCheckbox{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.PermissionRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.companiesRow{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.uploadCvsLebelMobile{
width: 10px !important;
margin-right: 80px !important;
}


.btnCreate-hover {
    width: 160px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 35px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btnCreate-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btnCreate-hover:focus {
    outline: none;
  }
  
  .btnCreate-hover.btn {
    background-image: linear-gradient(
      to right,
      #33C0D1,
      #33C0D1,
      #ffff,
      #33C0D1
    );
    box-shadow: 0 4px 15px 0 rgba(44, 81, 97, 0.75);
  }

  /* Shay added */
  .existingUserDiv{
    display: flex;
    justify-content: space-between;
    background-color: #e1f4f6;
    color: black;
    border-radius: 9px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
  }
  
  .existingUserDiv Button{
    width: 200px;
  }

  .userCardHeaderDiv{
    width: 100%;
  }

  .userCardHeader{
    font-size: 20px;
    font-weight: bold;
  }

  .newUserForm{
    display: flex;
    flex-direction: row;
  }

  .newUserInputs{
    width: 55%;
  }

  .newUserPermissions{
    width: 45%;
  }

 
    .mobileInputCompName {
      border-radius: 320px;
      width: 78%;
      margin-left: 10%;
      height: 25px;
      margin-left: 0px;
      padding-left: 5px;
      border: 1px solid #404952;
      margin-top: 15px;
      margin-bottom: -7px;
  }

  .updateTargetsMobile {
    position: absolute !important;
    height: 81% !important;
    background-color: #404952;
    border: 1px solid #33C0D1;
    color: #fff;
    width: 93% !important;
    top: 6% !important;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll !important;
    margin-left: -1%;
}
  
  .createNewCompanyCardMobile {
    display: flex;
    width: 100%;
    height: 90% !important;
    flex-direction: column;
    margin-top: -5%;
    margin-left: 10%;
    overflow: scroll;
}

  .newUserCheckboxRow{
    display: flex;
    flex-direction: row;
  }
  .checkboxRowHeader{
    font-weight: bold;
    margin-right: 10px;
  }

  .newUserCheckboxDiv{
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    margin-left: 5px;
  }
  .updateDiv{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
}
.newUserCardMobile {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #33C0D1; */
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 1s ease !important;
  overflow: scroll !important;
}

  .dropdownSelect{
    border: 1px solid #404952;
    border-radius: 10px;
    width: 17%;
    margin-left: 1%;
  }
  .newUserButton{
    position: absolute;
    right: 10%;
    width: 20%;
  }

  .dateInput {
    width: 25%;
    display: flex;
    margin-left: 70%;
    height: 28px;
    padding-right:3%;
    margin-top: -7%;
  }
  .dateInputMobileNewTar {
    width: 25%;
    display: flex;
    margin-left: 70%;
    height: 28px;
    padding-right: 3%;
    margin-top: -12%;
  }
  .creditsTable{
    width: 100%;
  }

  .creditsTableHeader{
    width: 100%;
  }
  .creditsTableCol{
    width: 43% !important;
  }
  .creditsTableContentHeader{
    width: 100%;
    background-color: #40596187;
  }
  .creditsTableContent{
    width: 40%;
    display: flex;
    flex-direction: row;
  }
  .creditsTableContent1{
    width:50%;
  }
  .divTagsCreditsData{
    display: flex;
    margin-top: -2%;
    margin-left: 6%;
    margin-bottom: -2%;
    flex-direction: row;
    align-items: center;
  }
  .ptagCreditMonth{
    width:80%;
    margin-left: 5%;
  }
  .creditSelect{
    width: 90%;
    border-radius: 15px;
    height: 25px;
  }
  .csvFileInput{
    border-radius: 5px;
    width: 52%;
    height: 19px;
    border: 1px solid #404952;
    margin-top: 0px;
    background-color: #93b5b584;
    margin-left: 48% !important;
  }
  .csvFileInputMobile{
    border-radius: 5px;
    width: 170px;
    height: 19px;
    color: #000000;
    border: 1px solid #404952;
    margin-top: 0px;
    background-color: #fff;
  }
  .selectAcualDate{
    border-radius: 5px;
    height: 25px;
    border: 1px solid #404952;
    background-color: #93b5b584;
    width: 81%;

  }
  .selectAcualDateMobile{
    border-radius: 5px;
    height: 25px;
    border: 1px solid #404952;
    background-color: #93b5b584;
    width: 36%;
    margin-right: 0%;
    margin-top: 29%;
  }
  .selectTargetDateMobile{
    margin-right: -26%;
    border-radius: 5px;
    width: 87%;
    height: 19px;
    border: 1px solid #404952;
    margin-top: 0px;
    background-color: #93b5b584;
    margin-left: -17% !important;
  }
 .submitButton {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    background-color: #a1e9ee;
    display: flex;
    margin-left: 92px;
}
.updateInputsCardMobile {
  position: absolute !important;
    height: 71% !important;
    background-color: #e1f4f6;
    width: 90% !important;
    top: 6% !important;
    left: 1%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll !important;
}
.buttonMobile {
  background-color: #81d6f1;
  color: white;
  border-radius: 120px;
  width: 74%;
  border: none;
  height: 40px;
  margin-top: 9px;
  margin-bottom: 15px;
  cursor: pointer;
}
.editDivMobile {
  display: flex;
    width: 4%;
    flex-direction: row !important;
    margin-top: -20px;
    right: 81%;
}
.updateInputsCardMobile {
  position: absolute !important;
     height: 77% !important;
     background-color: #e1f4f6;
     width: 93% !important;
     top: 6% !important;
     display: flex !important;
     flex-direction: column;
     justify-content: flex-start;
     overflow: scroll !important;
 }
 .editCardCenterMobile {
  width: 90%;
}
.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.toggle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.2s ease-in-out;
}

.switch.on {
  background-color: #81d6f1;
    margin-top: -72%;
    margin-left: -59%;
}

.switch.off {
  background-color: #ccc;
    margin-top: -72%;
    margin-left: -59%;
}

.switch.on .toggle {
  transform: translateX(20px);
}

.switch.off .toggle {
  transform: translateX(0);
}
.FaAngleDownDiv{
  width: 18%;
  display: flex;
  flex-direction: row-reverse;
}

.departmentInputs{
  width: 45%;
  display: flex;
  flex-direction: column;
}