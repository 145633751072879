
.BtnCardNew{
    display: flex;
    flex-direction: column;
    width: 87%;
    height: 94%;
    position: absolute;
    z-index: 5;
    margin-left: 1.5%;
    margin-top: -6%;
    .ContentBtnCard{
        position: relative;
        display: flex;
        width: 102%;
        height:65vh;
        .leftCardAdd{

            width:50%;
            height:40%;

        }
        .RightContent{
            width: 50%;
            .btnSubmit{
                background-color: #D5922D;
                border: none;
                cursor: pointer;
                border-radius: 35px;
                color: white;
                font-size: 30px;
                font-weight: 700px;
                margin-top: 5%;
                width: 32%;
                height: 12%;
                margin-left: 35%;
                };
            }
            .creditsTextBox{
                width: 100%;  
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 54%;
                margin-top: 4%;
            }
        }
   
            .TextBoxName{
                width: 84%;
                margin-left: 9%;
                margin-top: 4%;
                font-size: 12px;
                display: flex;
                flex-direction: row;
            }
            .TextHeading{ 
                color: #213038;
                font-size: 25px;
                margin-left:9.4%;
                width:50%;
            }
        

    }
    .closeBtn{
        position: absolute;
        top:1%;
        right:1%;
        font-size: 40px;
        width: 2%;
        height: 4%;
        border: none;
        background-color: #ffffff;
        cursor: pointer;
    }
    .NavbarBtncard{
        display: flex;
        justify-content: space-between;
        width: 96%;
        height: 29%;
        margin-top: 2%;
}
    
    .TextBoxNavBar {
        margin-top: 4%;
        width: 43%;
        margin: 2%;
        background-color: #ffffff;
        }
.displayCredits{
    background-color: #D5922D;
    height: 120px;
    width:120px;
}

.displayCards{
    height: 100vh;
    overflow-y: scroll;
    margin-bottom: 5%;
}
.CompanyNamesHeading{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    width: 96%;
    .btnNew{
        margin-left: 2%;
        background-color:#D5922D;
        border:none;
        cursor: pointer;
        border-radius: 35px;
        color :white;
        font-size: 25px;
        width: 20%;
        }
    .TextCompanyNames{
      color:black;
      margin-left:3%;
    }
}
.CardName{
    margin-top: 1%;
    display: flex;
    padding: unset;
    width: 94%;
    flex-direction: column;
    border-radius: 35px;
    margin-left: 2%;
    text-align: left;
    align-items: flex-start;
}
.textCard{
    display: flex;
    color: #213038;
    font-size: 20px;
    margin-left: 5%;
}



.rightTextBox{
    width: 43%;
    margin-top: 3%;
}
.horizontLine{
    width:97%;
    height: 0.1%;
    background-color: #213038;
}
.runfor{
    display: flex;
}
.CardPop{
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 88%;
    height: 92%;
    z-index: 5;
    margin-left: 1.5%;
    top:1%;
    .contentCard{
        position: relative;
        display: flex;
        width: 100%;
        height: 50%;
        .leftCard{
            width: 50%;
            height: 102%;
            overflow-y: scroll;
            .littleCard{
    display: flex;
    justify-content: space-between;
    margin-left: 8%;
    height: 10%;
    width: 93%;
    border-radius: 35px;
    background: #EAEAEA;
    color: #213038;

         .smallCreditsCard{
                  background-color: #213038;

         }
           }
        }
    }
    .headerCard{
        height: 40%;
        width: 100%;
        justify-content: space-between;
        display: flex;
        .textBoxer{
            width:40%;
            margin-left:4%;
            margin-right:4%;
        
        }
    }
    .btnCloseCard{
        position: absolute;
        top:1%;
        right:1%;
        font-size: 40px;
        width: 2%;
        height: 4%;
        border: none;
        cursor: pointer;
        background-color: #ffffff;
    }

}




.rightCard{
    display: flex;
    width: 50%;
    height: 97%;
    align-items: center;
    flex-direction: column;
    .btnCredits{
        background-color: #D5922D;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 35px;
    color: white;
    font-size: 28px;
    width: 34%;
    height: 18%;
    align-items: center;
    /* margin-left: 6%; */
    text-align: center;
    margin-top: 3%;
        }
    .TextCredits{
     
    /* background-color: #D5922D; */
    width: 51%;
    margin-top: 3%;

    }
    .inputCredits{
        display: flex;
        /* background-color: #D5922D; */
        /* border: none; */
        cursor: pointer;
        border-radius: 35px;
        color: white;
        font-size: 25px;
        width: 51%;
        height: 15%;
        color: white;
        align-items: center;
        justify-content: center;
        color: #213038;
        text-align: center;
        background-color: #ffffff;
    }
}


.creditsSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    border-radius: 35px;
    background-color: white;
    padding-left: 6%;
    height: 150%;
    font-size: 25px;
    color: #213038;
    // margin-right: -26%;

}
.creditsSectionTwo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    border-radius: 35px;
    background-color: white;
    height: 150%;
    font-size: 25px;
    color: #213038;
    padding-left: 5%;
    // margin-left: 38%;

}
.compInfoContent{
    width: 100%;
    color: #213038;
    background-color: #dabb8c;
    justify-content: space-between;
    padding: unset;
    display: flex;
    border-radius: 15%;
    height:20%;    
}
.credsNum{
    background-color: #D5922D;
    border-radius: 35px;
    height: 80%;
    aspect-ratio: 1 / 1;
    justify-content: center;
    color: #213038;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.cardContainer{

    width:50%;
    display: flex;
                flex-direction: row;

}
.creditsMonthCard{

    margin-left: 4%;
    height: 11%;
    width: 85%;
    margin-top: 1%;
    border-radius: 35px;
    background: #EAEAEA;
    color: #213038;
    display: flex;
    justify-content: space-between;

}
.creditsRadiusBox{

    width: 55%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    /* align-content: center; */
    align-items: center;
}
.creditsDetails{

    height: 90%;
    overflow: scroll;
}

// .ClientPage{
//     width: 99%;
//     height: 97%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     .headerSec{
//         width: 100%;
//         height: 10%;
//         display: flex;
//         justify-content: space-between;
//         flex-direction: row;
//         align-items: center;
//         color: #213038;
//         font-weight: 700;
//         font-size: 25px;
//         .btnAddCompany{
//             color: #ffffff;
//             border: none;
//             background-color: #D5922D;
//             height: 80%;
//             width: 20%;
//             border-radius: 35px;
//             font-size: 35px;
//             font-weight: 700;
//             line-height: 42.36px;
//         }
//     }
//     .compsSec{
//         width: 100%;
//         height: 90%;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         overflow: scroll;
//         .availableCompsX{
//             width: 100%;
//             background-color: #ffffff;
//             height: 15%;
//             display: flex;
//             margin-top: 1%;
//             border-radius: 35px;
//             justify-content: flex-start;
//             align-items: center;
//             font-size: 25px;
//             color: #213038;
//             font-weight: 300;
//             line-height: 30.26px;
//         }
//     }
//     .AddNewCompActive{
//         position: absolute;
//         width: 92%;
//         height: 97%;
//         display: flex;
//         background-color: #ffffff;
//         flex-direction: column;
//         border-radius: 35px;
//         .closeAddNewComp{
//             position: absolute;
//             right: 1%;
//             top: 2.5%;
//             cursor: pointer;
//         }
//     }
//     .viewCompanyDetails{
//         position: absolute;
//         width: 92%;
//         height: 97%;
//         display: flex;
//         background-color: #ffffff;
//         flex-direction: column;
//         border-radius: 35px;
//         .closeViewComp{
//             position: absolute;
//             right: 1%;
//             top: 2.5%;
//             cursor: pointer;
//         }
//         .compsInfoDdiv{
//             height: 20%;
//             width: 100%;
//             border-radius: 35px;
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: space-around;
//             .nameInput{
//                 min-width: unset;
//                 width: 47%;
//                 position: relative;
//                 border: 1px solid #213038;
//                 height: 64px;
//                 justify-content: center;
//                 border-radius: 35px;
//                 margin-top: 1%;
//                 display: flex;
//             }
//         }
//         .credsInfoDiv{
//             height: 80%;
//             width: 100%;
//             background-color: pink;
//             border-radius: 35px;
//         }

//     }
// }