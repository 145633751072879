.UnArchiveHover {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40px !important;
    justify-content: center;
    align-items: center;

    .triangle {
        width: 0;
        top: 69%;
        height: 0;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid #ffffff;
        z-index: 6;
    }

    .unArchiveTask {
        position: absolute;
        width: 145px;
        right: -20%;
        border-radius: 35px;
        height: 40px;
        height: 90%;
        display: flex;
        padding: 5px;
        top: 100%;
        background-color: #ffffff;
        z-index: 5;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        .unarchivecontainer{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #D5922D;
            position: relative;
            cursor:pointer;
            .unArchiveValue {
                font-size: 20px;
                font-weight: 300;
                color: #213038;
                line-height: 24.2px;
            }
        }
    }

}