.teaxtArea {
    position: relative;
    min-width: 200px;
    border: 1px solid #213038;
    height: 131px;
    justify-content: center;
    border-radius: 35px;
    margin-top: 2%;
    margin-bottom: 1%;
    .placeHolder{
        position: absolute;
        left: 45px;
        background-color: #ffffff;
        top: -36px;
        padding-left: 1%;
        padding-right: 1%;
        font-weight: 300;
        font-size: 20px;
        z-index: 10;
      }
    textarea{
      width: 96%;
      height: 73%;
      margin-left: 2%;
      margin-top: 3%;
      font-weight: 700;
      font-size: 25px;
      background-color: transparent !important;
      border: none;
      color: #213038;
      outline: none;
      resize: none;
      margin-bottom: 5px;
    }
  }
  