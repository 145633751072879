/* .addForeCast {
    width: 10%;
    color: white;
    background-color: rgba(146,140,195,1);
    margin-left: 2%;
} */
.addAionsActuals{
    display: flex;
    overflow-y: scroll;
    background-color: #404952;
    position: absolute;
    top: 10%;
    width: 40%;
    color: white;
    left: 36%;
    height: 80%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.closeConfigIcon{
    display: flex;
    flex-direction: row-reverse;
    width: 5%;
    margin-left: 92%;
    cursor: pointer;
    position: absolute;
    top: 1%;
}
.addForecast {
    display: flex;
    overflow-y: scroll;
    background-color: #404952;
    position: absolute;
    top: 10%;
    width: 40%;
    max-height: 80%;
    color: white;
    left: 36%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.addCategoryButtons{
    background-color: #81d6f1;
    color: white;
    border-radius: 7px;
    width: 20%;
    border: none;
    margin-left: 29%;
    height: 40px;
    cursor: pointer;
}
.removeCustomMetricsArr{
    background-color: rgba(227,54,126,1);
    color: white;
    border-radius: 7px;
    width: 34%;
    border: none;
    margin-left: 29%;
    margin-top: 3%;
    height: 40px;
    cursor: pointer;
}
.removeMetricValueButton{
    background-color: rgba(227,54,126,1);
    color: white;
    width: 10%;
}
.addCategoryInputs{
    border-radius: 9px;
    width: 50%;
    height: 38px;
    padding-left: 5px;
    border: 1px solid #404952;
}
.AionsGraph{
    margin-left: -9%;
    width: 100%;
    height: 100%;
}
.AddCreditsCard {
    width: 10%;
    color: white;
    background-color: rgb(227, 54, 126);
    margin-left: 2%;
}

.NewCustomersCard{
    width: 20%;
    color: white;
    background-color: rgba(146,140,195,1);
    margin-left: 2%;
}

.addCreditsPage {
    display: flex;
    overflow-y: scroll;
    background-color: #404952;
    position: absolute;
    width: 40%;
    top: 10%;
    color: white;
    left: 30%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.CreditsInputBox {
    border-radius: 9px;
    margin-top: 2%;
    width: 67%;
    height: 33px;
    border: 1px solid #404952;
}
.CompanyCreditsCoin {
    height: 48%;
    margin-top: 4%;
    width: 29px;
}

.addCreditsButton{
    width: 20%;
    margin-left: 1%;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    color: #fff;
}