// Define common variables
$primaryColor: #213038;
$secondaryColor: rgba(213, 146, 45, 1);
$buttonBackground: #d4912d;
$fontWeightBold: 700;
$fontWeightMedium: 500;
$cardBackground: #ffffff;
$paddingBase: 1%;
$marginBase: 0.5%;


.userContainer {
    height: 94vh;
    width: 90vw;
    padding: 0%;


    .userbody {
        height: 100%;
        min-width: 99%;
        padding: $marginBase;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;

        .usertop {
            height: 10%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $primaryColor;

            p,
            span {
                flex: 1 0 20%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 25px;
                font-weight: $fontWeightBold;

                &:first-child {
                    padding-left: $paddingBase;
                    color: $primaryColor;
                }

                &:last-child {
                    background: $secondaryColor;
                    border-radius: 50px;
                    justify-content: center;
                    cursor: pointer;
                    color: white;
                    margin-right: $marginBase;
                    height: 70%;
                }
            }
        }

        .mappeduser {
            width: 98%;
            margin-top: $marginBase;
            border-radius: 35px;
            color: $primaryColor;
            background: $cardBackground;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.7%;
            gap: 0.2%;

            p {
                font-weight: $fontWeightBold;
                flex: 1 0 45%;
            }

            span {
                flex: 1 0 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 90%;
                border-radius: 35px;
                background-color: $buttonBackground;
                font-weight: $fontWeightMedium;
                cursor: pointer;
                color: white;
            }
        }

        .new_update_usercard {
            background-color: $cardBackground;
            border-radius: 15px;
            padding: $marginBase;
            display: flex;
            flex-direction: column;
            height: 96.5%;
            width: 99%;

            .messabox {
                background-color: #d4912d;
                height: 20vh;
                width: 50vw;
            }

            .primaryInfo {
                display: flex;
                justify-content: space-evenly;
                padding-top: $marginBase;
                align-items: flex-end;
                height: 10%;
                position: relative;

                .close {
                    color: $primaryColor;
                    position: absolute;
                    left: 99%;
                    top: -17%;
                    font-size: 12px;
                    font-weight: 900;
                    cursor: pointer;
                }

                .textBox {
                    position: relative;
                    width: 25%;
                    border: 1px solid $primaryColor;
                    height: 85%;
                    border-radius: 35px;

                    .placeHolder {
                        position: absolute;
                        left: 45px;
                        background-color: $cardBackground;
                        top: -36px;
                        padding: 0 $paddingBase;
                        font-weight: 300;
                        font-size: 20px;
                        z-index: 10;
                        color: $primaryColor;
                    }

                    input {
                        width: 90%;
                        padding-left: 2px;
                        font-weight: 700;
                        font-size: 25px;
                        background-color: transparent;
                        border: none;
                        margin-top: $marginBase;
                        color: $primaryColor;
                        outline: none;
                        margin-bottom: 5px;
                    }
                }
            }

            hr {
                width: 98%;
                height: 1px;
                background: $primaryColor;
                margin: $marginBase auto;
            }

            .userbody_sub {
                height: 90%;
                width: 99%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .body_right,
                .body_left {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    height: 100%;
                    width: 48%;
                    color: $primaryColor;

                    .customSelect,
                    .customSelectActive {
                        position: relative;
                        min-width: 55%;
                        border: 1px solid $primaryColor;
                        height: 64px;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        border-radius: 35px;
                        flex-direction: column;
                        margin-top: 3%;

                        &.customSelect {}

                        &.customSelectActive {
                            // background-color: yellow;

                            .customSelectOptions {
                                transition: all 0.5s;
                                z-index: 5;
                                border: 1px solid $primaryColor;
                                display: flex;
                                min-height: 0vh !important;
                                opacity: 1;
                                border-radius: 25px;
                                // background-color: blue;
                                width: 87%;
                                color: $primaryColor;

                                span {
                                    border: 1px solid $primaryColor;
                                    width: 80%;
                                    margin-top: 5%;
                                    height: 50px;
                                    font-size: 25px;
                                    font-weight: 700;
                                    border-radius: 35px;
                                    padding-left: 30px;
                                    display: flex;
                                    background: rgba(234, 234, 234, 1);
                                    align-items: center;
                                    cursor: pointer;

                                    &.selectActive {
                                        background-color: #D5922D;
                                    }
                                }
                            }
                        }
                    }

                    .thebutton {
                        background-color: #d4912d;
                        height: 7%;
                        width: 20%;
                        margin-top: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 20px;
                        cursor: pointer;
                    }
                }

                .body_left {
                    //   background-color: red;
                }

                .body_right {
                    //  background-color: blue;

                    h2 {
                        font-size: 24px;
                        font-weight: 300;
                    }

                    h3 {
                        font-size: 25px;
                        font-weight: 700;
                        line-height: 30.26px;
                        text-align: center;
                    }

                    .maparea {
                        //  background-color: red;
                        width: 90%;
                        height: 86%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        overflow: scroll;

                        p {
                            border-radius: 30px;
                            background: rgb(234, 234, 234);
                            min-height: 20%;
                            width: 80%;
                            display: flex;
                            margin: 2% 0 0;
                            align-items: center;
                            justify-content: center;
                            color: #213038;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}