.textBox {
  position: relative;
  min-width: unset;
  border: 1px solid #213038;
  padding: 5px;
  height: 35px;
  width: 25%;
  margin-right: 1%;
  display: flex;
  align-items: center;
  border-radius: 35px;
  .placeHolder{
    position: absolute;
    left: 45px;
    background-color: #ffffff;
    top: -36px;
    padding-left: 1%;
    padding-right: 1%;
    font-weight: 300;
    font-size: 20px;
    z-index: 10;
    color: #213038;
  }
  input{
    width: 90%;
    font-weight: 700;
    display: flex;
    font-size: 25px;
    background-color: transparent !important;
    border: none;
    color: #213038;
    outline: none;
  }
}
