.clientContainer {
    height: 98vh;
    width: 99%;
    color: #213038;

    .topbar {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .comptxt,
        .newspan {
            display: flex;
            align-items: center;
            font-size: 32px;
            font-weight: 600;
        }

        .comptxt {
            width: 21%;
        }

        .newspan {
            width: 20%;
            height: 75%;
            margin-right: 0.5%;
            justify-content: center;
            border-radius: 40px;
            cursor: pointer;
            background: #D4912D;
            color: #ffffff;
        }
    }

    .mapsection {
        max-height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;

        .comcard {
            background: white;
            min-height: 6vh;
            margin: 2.5px;
            padding-left: 0.5%;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 600;
            border-radius: 50px;
            cursor: pointer;
        }
    }

    .comcardActive {
        height: 99%;
        width: 91.5%;
        position: absolute;
        top: 0.5%;
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 15px;

        .closecard {
            height: 4%;
            position: absolute;
            width: 2%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-weight: 400;
            left: 98%;
            top: -2.5%;
            cursor: pointer;
        }

        .activecardtop {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .textBox {
                min-width: 36.5%;
                border: 1px solid #213038;
                height: 64%;
                justify-content: center;
                border-radius: 35px;
                margin-top: 1%;
                display: flex;
                margin-right: 1%;
                margin-left: 0.5%;
            }
        }

        .activecardbody {
            height: 90%;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: flex-end;

            .editCreds,
            .mappedCredsdiv {
                width: 48%;
                height: 90%;
            }

            .mappedCredsdiv {
                .mappedCred {
                    background: #EAEAEA;
                    height: 8%;
                    width: 98%;
                    margin: 1%;
                    border-radius: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .p_date {
                        margin: 0% 0% 0% 4%;
                        display: flex;
                        align-items: center;
                        width: 30%;
                        font-size: 18px;
                        font-weight: 400;
                        height: 80%;
                    }

                    .credsDetails {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        width: 60%;
                        font-weight: 400;
                        padding-right: 1%;
                        height: 100%;
                        justify-content: space-between;

                        .creds {
                            background: rgb(255, 255, 255);
                            height: 80%;
                            width: 40%;
                            border-radius: 45px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .pcreddesc {
                                margin-left: 6%;
                            }

                            .pcrednum {
                                background: #D4912D;
                                height: 85%;
                                border-radius: 30px;
                                margin-right: 2%;
                                aspect-ratio: 1 / 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
                .user{
                    display: flex;
                    height: 35% !important;
                    flex-direction: column;
                    justify-content: space-around;
                };
            };

            .editCreds {
                display: flex;
                align-items: center;
                flex-direction: column;

                .date {
                    display: flex;
                    cursor: pointer;
                    border-radius: 35px;
                    font-size: 24px;
                    width: 51%;
                    height: 8%;
                    align-items: center;
                    justify-content: center;
                    color: #213038;
                    text-align: center;
                    border-style: solid;
                }

                .textBox {
                    min-width: 50%;
                    border: 1px solid #213038;
                    height: 8%;
                    justify-content: center;
                    border-radius: 35px;
                    margin-top: 4%;
                    display: flex;
                    margin-right: 1%;
                    margin-left: 0.5%;
                }

                .newspan {
                    width: 25%;
                    height: 7%;
                    margin-top: 2.5%;
                    justify-content: center;
                    border-radius: 40px;
                    background: #D4912D;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }
}