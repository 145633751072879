.overViewHomePage {
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 1%;

    .profitLossSection {
        width: 92%;
        height: 10%;
        display: flex;
        flex-direction: row;
        margin-top: 1%;
        margin-right: 1%;
        align-items: center;
        justify-content: space-between;

        .profitContanier {
            width: 30%;
            height: 65%;
            border-radius: 35px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            color: #213038;
            padding: 5px;
            background-color: #ffffff;
            font-weight: 300;
            font-size: 20px;

            .valueContainer {
                height: 100%;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #EFB86F;
                border-radius: 35px;
                font-weight: 700;
                font-size: 20px;
                line-height: 24.2px;
                color: #213038;
            }
        }

        .sortByMonthSelector {
            width: 15%;
            height: 80%;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1%;
            border-radius: 35px;
            color: #213038;

            .salesViewPointer {
                position: absolute;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid #ffffff;
                z-index: 7;
                top: 11%;
                right: 6%;
            }
            .salesViewContainer {
                width: 200px;
                height: 140px;
                position: absolute;
                right: 0.5%;
                display: flex;
                border-radius: 25px;
                background-color: #ffffff;
                top: 13%;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                z-index: 5;
                .salesViewValues {
                    width: 95%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 35px;
                    background-color: #EAEAEA;
                    font-weight: 300;
                    font-size: 20px;
                    height: 30%;
                    line-height: 24.2px;
                    color: #213038;
                    cursor: pointer;
                }
            }
            .filterDropdown {
                font-size: 20px;
                line-height: 24.2px;
                font-weight: 300;
                color: #213038;
            }
        }
    }

    .overViewSection {
        width: 92%;
        height: 86%;
        display: flex;
        margin-right: 1%;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: scroll;
        align-items: flex-start;
        font-size: 25px;
        font-weight: 700;
        line-height: 30.26px;

        .otherMetrics {
            width: 97.5%;
            height: 8%;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            border-radius: 35px;
            font-size: 25px;
            font-weight: 300;
            line-height: 36.31px;
            color: #213038;
            margin: 0.5%;

            .metricValue {
                width: 20%;
                height: 100%;
                display: flex;
                background-color: #EAEAEA;
                font-weight: 700;
                border-radius: 35px;
                font-size: 20px;
                align-items: center;
                justify-content: center;
            }
        }

        .TempGraphCard {
            width: 48%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            background: #ffffff;
            // padding: 5px;
            margin: 0.4%;

            .dateSection {
                width: 95%;
                height: 10%;
                display: flex;
                flex-direction: row;
                border-radius: 35px;
                align-items: center;
                justify-content: space-between;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
            }

            .graphLegends {
                width: 100%;
                height: 5%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: 300;
                color: #213038;
                font-size: 15px;

                .forecastLabel {
                    width: 15%;
                    background-color: #213038;
                    padding: 5px;
                    margin-left: 3%;
                    margin-right: 1%;
                    border-radius: 35px;
                }

                .actualLabel {
                    width: 15%;
                    background-color: #D4912D;
                    padding: 5px;
                    margin-right: 1%;
                    border-radius: 35px;
                }
            }

            .graphSection {
                width: 100%;
                height: 75%;
                display: flex;
                svg {
                    width: 100%;
                }
            }

            .totalSection {
                width: 100%;
                display: flex;
                height: 10%;
                border-radius: 35px;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                font-weight: 700;
                color: #213038
            }
        }

        .VerticalGraphCard {
            width: 48%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            background: #ffffff;
            // padding: 5px;
            margin: 0.4%;

            .dateSection {
                width: 95%;
                height: 10%;
                display: flex;
                flex-direction: row;
                border-radius: 35px;
                align-items: center;
                justify-content: space-between;
                font-weight: 700;
                color: #213038;
                line-height: 30.26px;
            }

            .graphLegends {
                width: 100%;
                height: 5%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-weight: 300;
                color: #213038;
                font-size: 15px;

                .forecastLabel {
                    width: 15%;
                    background-color: #213038;
                    padding: 5px;
                    margin-left: 3%;
                    margin-right: 1%;
                    border-radius: 35px;
                }

                .actualLabel {
                    width: 15%;
                    background-color: #D4912D;
                    padding: 5px;
                    margin-right: 1%;
                    border-radius: 35px;
                }
            }

            .graphSection {
                width: 80%;
                height: 75%;
                display: flex;
                svg {
                    // margin: 20px;
                    width: 100%;
                }
            }

            .totalSection {
                width: 100%;
                display: flex;
                height: 10%;
                border-radius: 35px;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                font-weight: 700;
                color: #213038
            }
        }

        .summaryLineChart {
            width: 97.5%;
            height: 85%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 35px;
            background: #ffffff;
            padding: 5px;
            margin: 0.4%;
            font-size: 20px;
            font-weight: 700;
            color: #213038;

            .chartLegend {
                width: 100%;
                height: 20%;
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 24.2px;
                justify-content: center;
                font-weight: 300;
                font-size: 20px;
                .GaugesSection {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .gaugeConatiner{
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        justify-content: center;
                    }
                    .runwayGaugeValues{
                        width: 50%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        font-size: 20px;
                        line-height: 0px;
                        text-align: left;
                        justify-content: center;
                        align-items: flex-start;
                        p.runwayHeader {
                            font-weight: 700;
                            font-size: 25px;
                            color: #213038;
                        }
                    }
                }
                .legends {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .income-expenseSection {
                        height: 45%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .incomeLegend {
                            background-color: #EFB86F;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                        .expenseLegend {
                            background-color: #A62626;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                    }
                    .burn-cashSection {
                        height: 45%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .cashflowLegend {
                            background-color: #213038;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                        .burnrateLegend {
                            background-color: #7A8F9D;
                            width: 15%;
                            height: 30%;
                            display: flex;
                            border-radius: 35px;
                            align-self: center;
                            margin-right: 2%;
                        }
                    }
                }

            }

            .chartContainer {
                width: 98%;
                height: 75%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 35px;
                background-color: #EAEAEA;
                margin-bottom: 0.2%;
            }
        }
    }

}