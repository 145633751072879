.ClientToDiv{
    margin-top: -15%;
    height: 25.8%;
    width: 100%;
    box-shadow: 0 4px 15px 0 rgba(44, 81, 97, 0.5);
    display: flex;
    flex-direction: row;
}
.quotesCards{
    display: flex;
    width: 90%;
    height: 5%;
    margin-top: 1%;
    border-radius: 10px;
    color: white;
    padding: 15px;
    background-color: #373f46;
    align-items: center;
    justify-content: left;
}

.createQuoteBtn{
    margin-left: 49%;
    width: 40%;
    height: 45px;
    border-radius: 10px;
}
.NewQuoteDateInputs{
    width: 35%;
    height: 45px;
    margin-left: 2%;
    border-radius: 10px;
}
.updateQuotesAndSlaCard{
    display: flex;
    overflow-y: scroll;
    background-color: #404952;
    position: absolute;
    top: 10%;
    width: 60%;
    max-height: 80%;
    color: white;
    left: 25%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.closeViewQuoteCard{
    display: flex;
    flex-direction: row-reverse;
    width: 3%;
    margin-left: 95%;
    cursor: pointer;
    position: absolute;
    top: 1%;
}