.button{
    background-color: #81d6f1;
    color: white;
    border-radius: 120px;
    width: 30%;
    border: none;
    height: 30px;
    margin-top:15px ;
    margin-bottom: 15px;
    cursor: pointer;
}

.button:hover{
    background-image: linear-gradient(
        to right,
        #33C0D1,
        #33C0D1,
        #ffffff,
        #33C0D1,
        
      );
      box-shadow: 0 4px 15px 0 rgba(44, 81, 97, 0.75);
}


