.updateActualsDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
    max-height: 75%;
}
.updateActualsInputs {
    border-radius: 10px;
    width: 25%;
    height: 33px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5%;
}
.removePrevActuals {
    background-color: rgba(227,54,126,1);
    color: white;
    border-radius: 5px;
    width: 30%;
    margin-left: 5%;
    border: none;
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}