/* Satrt Course Admin */

.createSession{
    background-color: #282C34;
    color: white !important;
    display: flex !important;
    flex-direction: column;
    width: 40%;
    margin-top: 5%;
    height: 60% !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 3px 1px rgb(47, 135, 130);
    margin-left: 30%;
}
.inputContent{
    display: flex;
    flex-direction: row;
}
.sessionCardStyling{
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.descriptionCard{
    background-color: #282C34;
    color: white !important;
    display: flex !important;
    flex-wrap: nowrap;
    margin-top: 1%;
    justify-content: flex-start;
    height: 20px !important;
    width: 97%;
    border-radius: 2px !important;
}
.descriptionCardLbalel{
    width: 28.5% !important;
    size: 16px !important;
}
.descriptionCardInfo{
    width: 28.5% !important;
    size: 16px !important;
}
.courseEditButtonDiv{
    width: 10%;
    display: flex;
    flex-direction: row;
}
.createQuizContentDiv{
    width: 50% !important;
    display: flex;
    flex-direction: column;
}
.addQuizContent{
    display: flex !important;
    flex-direction: column !important;
    background-color: #282C34;
    color: white !important;
}
.addQuizContentMobile{ 
    display: flex !important;
    width: 91%;
    flex-direction: column !important;
    background-color: #282C34;
    color: white !important;

}
.switch.onMobile {
    background-color: #81d6f1;
    margin-top: -25%;
    margin-left: -88%;
}
.switch.offMobile {
    background-color: #ccc;
    margin-top: -26%;
    margin-left: -90%;
}
.buttonSaveMobile {
    background-color: #81d6f1;
    color: white;
    border-radius: 120px;
    width: 71%;
    border: none;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.addMetricsIconDeleteMobile {
    width: 4%;
    margin-top: 3%;
    margin-left: -10%;
    display: flex;
}
.viewQuizContent {
    display: flex !important;
    flex-direction: column !important;
    background-color: #282C34;
    color: white !important;
    height: 85%;
    overflow-y: scroll;
    margin-left: 3%;
  }
  .quizInputs{
    border-radius: 320px;
    width: 90%;
    height: 25px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
  }
.courseEditButton{
    width: 50%;
}
.newCourseButton{
    width: 7%;
    margin-left: 92%;
}
.newCourseButtonMobile{
    margin-left: 70%;
}
.tableOfCourse{
    background-color: #282C34;
}
.newCourseDiv{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
}

.courseInputName{
    width: 30%;
}
.courseInputSelect{
    border-radius: 320px;
    width: 20.5%;
    height: 25px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.createCourseInputs{
    display: flex;
    flex-direction: row;
}
.courseInputSelectContent{
    border-radius: 9px;
    width: 30.5%;
    resize: none;
    height: 78px;
    border: 1px solid #404952;
    margin-top: 20px;
    margin-left: 19.3%;
    margin-bottom: -30px;
}
.newCourseCancelBtn{
    background-color: #81d6f1;
    color: white;
    border-radius: 120px;
    width: 13%;
    border: none;
    height: 30px;
    margin-top: 15px;
    margin-left: 85%;
    margin-bottom: 15px;
    cursor: pointer;
}

.newCourseAddBtn{
    background-color: #81d6f1;
    color: white;
    border-radius: 120px;
    width: 11%;
    border: none;
    height: 30px;
    margin-top: 15px;
    margin-left: 70%;
    margin-bottom: 15px;
    cursor: pointer;
}
.newCourseBtnAdd{
    background-color: #81d6f1;
    color: white;
    border-radius: 120px;
    width: 10%;
    border: none;
    height: 30px;
    margin-top: -34px;
    margin-left: 89%;
    margin-bottom: 36px;
    cursor: pointer;
}
.contentDiv{
    width: 50%;
}
.typeDiv{
    width:50%
}

.Quiz{
    display: flex !important;
    width:100% !important;
    flex-direction: row !important;
    height: 50%!important;
}
.QuizMobile {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
    height: 50%!important;
}
.descriptionCardNoData{
    margin-top: 1%;
    background-color: #282C34;
    color: white !important;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: center;
    height: 20px !important;
    width: 97%;
    border-radius: 2px !important;
    box-shadow: 0 2px 5px 0 rgb(255, 2, 2);

}

.dropDownOption{
    width: 20%;
}
.captionTextArea{
    width: 20%;
}
.requiredField{
    width: 10%;
    margin-left: 40%;
    display: flex;
    flex-direction: row;
    margin-top: -4%;
}
.contentField{
    width: 100%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
}
.currentPageNewCourse{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.courseContent{
    width: 30%;
}
.newPage{
    margin-top: 14%;
    margin-left: 42%;

}
.nameDiv{
width: 35%;
}
.newCourseMapMainCard{
    width: 100%;
    display: flex;
    background-color: #404952;
    border-radius: 0px;
    margin-top: 1%;
    flex-direction: column;
}
.newCourseMapInnerCard{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #282C34;
    color: white !important;
    margin-top: 1%;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: 20px !important;
    border-radius: 0px;
}
.sidebarItemMobile{
    color: #fff;
    width: 184%;
    margin: 1%;
    height: 3px;
    display: flex;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #33C0D1;
    margin-bottom: 15px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.courseProgressCards{
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 50%;
}
.completedCard{
    display: flex;
    height: 50%;
    flex-direction: column;
    position: relative;
    border-radius: 9px;
    background-color: #282C34;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 30%;
    color: white;
    margin: 1%;
}

.btnPreview{
    background-color: #81d6f1;
    color: white;
    border-radius: 120px;
    width: 15%;
    border: none;
    height: 30px;
    margin-top: 15px;
    margin-left: 85%;
    margin-bottom: 15px;
    cursor: pointer;
}
.tabs{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
}

.courseButtons{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.btnHome{
    margin: 2%;
}
.ViewCourseCard{
    display: flex;
    flex-direction: column;
    background-color: #282C34;
    color: white;
    width: 50%;
    margin-top: 8%;
    margin-left: 20%;
}
/* .ViewCourseCard{
    display: flex;
    flex-direction: row;
    width: 100%;
    
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: 20px !important;
    border-radius: 0px;
} */

.viewUserCourse{
    display: flex;
    width: 50%;
    margin-top: 5%;
   color: #fff;
    margin-left: 25%;
    background-color: #282C34;
    flex-direction: column;
}

/* End Course Admin */

.ViewCourse{
    width: 10%;
    margin-left: 60%;
}

.ViewCoursePage{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #282C34;
    color: white !important;
    margin-top: -10%;
    display: flex !important;
    flex-wrap: nowrap;
    /* justify-content: flex-start; */
    height: 690px !important;
    border-radius: 0px;
}

.viewCourseCard

.Buttons{
    margin-left: 55%;
    width: 100%;
}
/* Add CSS styles for the brain animations */
.result-animation {
    text-align: center;
    position: relative;
}

.animated-brain {
    width: 100px;
    height: 100px;
    background-color: #a0c3ff; /* You can change the color as per your preference */
    border-radius: 50%;
}

.dancing {
    /* Dancing brain animation */
    animation: dancingBrainAnimation 2s ease-in-out infinite;
}

.busted {
    /* Busted brain animation */
    animation: bustedBrainAnimation 2s ease-in-out infinite;
}

/* Dancing brain animation */
@keyframes dancingBrainAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

/* Busted brain animation */
@keyframes bustedBrainAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

/* .Quiz {
    width: 50%;
    height: 90%; 
  }
  
  .questionCard {
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .viewCourseDetails{
    max-height: 100%;
    overflow-y: scroll;
    display:flex ;
    flex-direction: column;
  } */
