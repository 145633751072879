.statusBtn {
    width: 65%;
    height: 15%;
    margin-left: 5%;
    border-radius: 14px;
    cursor: pointer;
    color: #fff;
}
.CredsCard {
    display: flex;
    border-radius: 9px;
    background-color: #404952;
    display: flex;
    width: 80%;
    flex-direction: column;
    height: 75%;
    color: white;
    justify-content: center;
    padding: 15px;
}

.RequestedTasksBtn {
    display: flex;
    position: relative;
    width: 15%;
    border-radius: 10px;
    margin-left: 1%;
    align-items: center;
    background-color: rgb(143, 202, 114);
    justify-content: center;
    padding: 15px;
    color: white;
}
.addNewTask {
    width: 95%;
    margin-left: 2%;
    height: 15%;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #404952;
    padding: 1%;
}
.InProgressTasksBtn {
    display: flex;
    position: relative;
    width: 15%;
    border-radius: 10px;
    background-color: #ffffff;
    margin-left: 1%;
    align-items: center;
    background-color: rgb(49, 192, 209);
    justify-content: center;
    padding: 15px;
    color: white;
}
.CompletedTasksBtn {
    display: flex;
    position: relative;
    width: 15%;
    border-radius: 10px;
    background-color: #ffffff;
    margin-left: 1%;
    align-items: center;
    background-color: rgba(146,140,195,1);
    justify-content: center;
    padding: 15px;
    color: white;
}
.DeclinedTasksBtn {
    display: flex;
    position: relative;
    width: 15%;
    border-radius: 10px;
    background-color: #ffffff;
    margin-left: 1%;
    align-items: center;
    background-color: rgb(227, 54, 126);
    justify-content: center;
    padding: 15px;
    color: white;
}

.DeclinedTasks {
    display: flex;
    width: 90%;
    border-radius: 15px;
    height: 30px;
    background-color: #373f46;
    margin-left: 1%;
    margin-bottom: 1%;
    color: white;
}
.viewTaskBtn {
    background-color: #81d6f1;
    color: white;
    border-radius: 9px;
    width: 15%;
    border: none;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.addNewTaskbtn {
    display: flex;
    position: relative;
    width: 15%;
    border-radius: 10px;
    background-color: #ffffff;
    margin-left: 1%;
    height: 80%;
    align-items: center;
    background-color: rgb(49, 192, 209);
    justify-content: center;
    padding: 15px;
    color: white;
}
.CreditsCoin {
    width: 7%;
    margin-top: 3%;
    height: 0%;
}
.addNewTaskCredsContainer {
    width: 91%;
    margin-left: 2%;
    height: 15%;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #404952;
    padding: 1%;
}
.newDesignDevTaskcard {
    display: flex;
    overflow-y: scroll;
    position: absolute;
    top: 10%;
    background-color: #404952;
    width: 50%;
    left: 30%;
    color: white;
    height: 85%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: row;
}
.designTaskDimensions {
    border-radius: 10px;
    width: 98%;
    height: 38px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.editTaskBtn {
    background-color: rgb(227, 54, 126);
    color: white;
    border-radius: 9px;
    width: 15%;
    border: none;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.newCloseCard{
    display: flex;
    flex-direction: row-reverse;
    width: 2%;
    margin-left: 97%;
    cursor: pointer;
    position: absolute;
    top: 1%;
}
.addNewTaskWideCard {
    display: flex;
    position: absolute;
    top: 10%;
    background-color: #404952;
    width: 75%; 
    left: 21%;
    color: white;
    height: 85%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: row;
}