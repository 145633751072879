.overView-display{
    width: 99.5%;
    height: 97%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 5px;
    color: #213038;
    .profit-loss-ActChart{
        width: 99%;
        margin: 5px;
        min-height: 50%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 35px;
        justify-content: center;
        align-items: center;
        .title-legend-container{
            width: 98%;
            height: 12%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            .chartLegends-section{
                width: 20%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #213038;
                .profit-divisions-section{
                    width: 100%;
                    height: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 18px;
                    font-weight: 300;
                    .profit-legend-constainer{
                        width: 30%;
                        height: 50%;
                        border-radius: 35px;
                        background-color: #D4912D;
                        margin-right: 15px;
                    }
                }
                .loss-Divisions-section{
                    width: 100%;
                    height: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 18px;
                    font-weight: 300;
                    .loss-legend-constainer{
                        width: 30%;
                        height: 50%;
                        border-radius: 35px;
                        background-color: #213038;
                        margin-right: 15px;
                    }
                }
            }
            .chartTitle-section{
                width: 20%;
                height: 100%;
                display: flex;
                font-weight: 700;
                font-size: 25px;
                color: #213038;
                justify-content: center;
                align-items: center;
            }
            .chartPercentage-section{
                width: 20%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                .percent-value{
                    width: 35%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #D5922D;
                    aspect-ratio: 1 / 1;
                    border-radius: 35px;
                    height:100%;
                    p{
                        font-weight: 700;
                        font-size: 20px;
                        color: #ffffff;
                        margin: 0;
                    }
                }
            }
        }
        .profitloss-chart-container{
            width: 98%;
            height: 80%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .runway-ActChart{
        width: 99%;
        margin: 5px;
        min-height: 50%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 35px;
        justify-content: center;
        align-items: center;
        .chart-title-Section{
            width: 98;
            height: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 25px;
        }
        .runway-chart-container{
            width: 98%;
            height: 80%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .profit-loss-InvesteeChart{
        width: 99%;
        margin: 5px;
        min-height: 80%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 35px;
        justify-content: center;
        align-items: center;
        .title-legend-container{
            width: 98%;
            height: 8%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            .chartLegends-section{
                width: 20%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #213038;
                .profit-divisions-section{
                    width: 100%;
                    height: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 18px;
                    font-weight: 300;
                    .profit-legend-constainer{
                        width: 30%;
                        height: 50%;
                        border-radius: 35px;
                        background-color: #D4912D;
                        margin-right: 15px;
                    }
                }
                .loss-Divisions-section{
                    width: 100%;
                    height: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 18px;
                    font-weight: 300;
                    .loss-legend-constainer{
                        width: 30%;
                        height: 50%;
                        border-radius: 35px;
                        background-color: #213038;
                        margin-right: 15px;
                    }
                }
            }
            .chartTitle-section{
                width: 20%;
                height: 100%;
                display: flex;
                font-weight: 700;
                font-size: 25px;
                color: #213038;
                justify-content: center;
                align-items: center;
            }
            .chartPercentage-section{
                width: 20%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                .percent-value{
                    width: 35%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #D5922D;
                    aspect-ratio: 1 / 1;
                    border-radius: 35px;
                    p{
                        font-weight: 700;
                        font-size: 20px;
                        color: #ffffff;
                        margin: 0;
                    }
                }
            }
        }
        .profitloss-chart-container{
            width: 98%;
            height: 88%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .runway-InvesteeChart{
        width: 99%;
        margin: 5px;
        min-height: 80%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 35px;
        justify-content: center;
        align-items: center;
        .chart-title-Section{
            width: 98;
            height: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 25px;
        }
        .runway-chart-container{
            width: 98%;
            height: 90%;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}