.dashboardContent
.permissions{
    flex-direction: column;

}

.newUserCard{
    display: flex;
    flex-direction: column;
    /* border: 1px solid #33C0D1; */
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all 1s ease !important;
    overflow: scroll !important;
    
}

.cardCreateUser{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: scroll;
}
.firstNameInput,.lastNameInput,.emailInput,.PasswordInput,.confirmationInput{
    width: 80%;
    display:flex;
    flex-direction: row;
}
.input-container{
    margin-bottom: 100;
}
.newUser{
    width: 40vw;
    display:flex;
    flex-direction: row;
}
.existingUser{
    width: 40vw;
    display:flex;
    top: 0;
    
}
.labelCheckbox{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.PermissionRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.companiesRow{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.companyFigureCardsMobile{
  position: absolute;
  bottom: 1% !important;
  margin: 0px !important;
  width: 30% !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
  color: rgb(220, 218, 218);
  background-color: rgb(40, 44, 52);
  box-shadow: 0 2px 3px 1px rgb(47, 135, 130);
}

.bottomTabs{
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 7%;
}
.newUserInputsMobile{
  width: 120%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inputCompanyName {
  background-color: #81d6f1;
  color: white;
  border-radius: 120px;
  width: 80%;
  border: none;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.bottomTabDiv{
  display: flex;
  border-radius: 0%;
  border: 5px solid aqua;
  }

.userTabDivMobile{
  display: flex;
  flex-direction: row;
  justify-content: center
}
.userTabCards{
  color: #404952;
  border-radius: 50px;
  border: 1px solid #33C0D1;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.btnCreateUser{
    background-color: #81d6f1;
    color: white;
    border-radius: 120px;
    width: 80%;
    border: none;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.buttonUpdatePerms {
  width: 100%;
  font-size: 13px;
  font-weight: 100;
  color: #404952;
  cursor: pointer;
  margin: 20px;
  height: 35px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
  
  .btnCreate-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btnCreate-hover:focus {
    outline: none;
  }
  
  .btnCreate-hover.btn {
    background-image: linear-gradient(
      to right,
      #33C0D1,
      #33C0D1,
      #ffff,
      #33C0D1
    );
    box-shadow: 0 4px 15px 0 rgba(44, 81, 97, 0.75);
  }

  /* Shay added */
  .existingUserDiv{
    display: flex;
    justify-content: space-between;
    background-color: #D9D9D9;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    width: 95%;
  }

  .existingUserDivMobile {
    display: flex;
    justify-content: space-between;
    border: 1px solid #33C0D1;
    background-color: #404952;
    color: white;
    margin-top: 5px;
    margin-bottom: 1px;
    padding: 5px;
    width: 96%;
    border-radius: 10px;
  }
  
  
  .existingUserDiv Button{
    width: 200px;
  }

  .userCardHeaderDiv{
    width: 100%;
  }

  .userCardHeader{
    font-size: 20px;
    font-weight: bold;
  }

  .newUserForm{
    display: flex;
    flex-direction: row;
  }

  .newUserInputs{
    width: 55%;
    left : 0;
  }
  .updateButton{
    width: 60%;
    position: absolute;
  }

  .newUserPermissions {
    width: 45%;
    position: absolute;
    top: 5%;
    right: 25%;
    transform: translateX(50%);
}
.updateInputsCard{
  position: absolute !important;
    height: 80% !important;
    width: 80% !important;
    top: 18% !important;
    display: flex !important;
    align-items: flex-start;
    justify-content: center;
}
  .newUserCheckboxRow{
    display: flex;
    flex-direction: row;
  }
  .checkboxRowHeader{
    font-weight: bold;
    margin-right: 10px;
  }

  .newUserCheckboxDiv{
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    margin-left: 5px;
  }

  .dropdownSelect{
    border: 1px solid #404952;
    border-radius: 10px;
    padding: 5px;
    width: 60%;
  }
  .dropdownSelectMobile{
    border: 1px solid #404952;
    border-radius: 25px;
    padding: 5px;
    width: 100%;
  }
  .newUserButton{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20%;
  }

.showSectionChevron{
  cursor: pointer;
  transition: all .4s ease-in-out;
}

.showSectionChevronShow{
  transform: rotate(90deg) !important;
  transition: all .4s ease-in-out;
}

.mobileTabBarDiv{
  position: fixed;
  width: 100%;
  height: 7%;
  bottom: 0%;
  background-color: #404952;
}
.editUserIcon{
  width: 7%;
  height: 10%;
  margin-top: 3%;
}
.createNewUserCardMobile{
  display: flex;
  width: 80%;
  height: 90% !important;
  flex-direction: column;
  margin-top: -4%;
  margin-left: 10%;
  overflow: scroll;
}

.userAddDataCardMobile{
  position: absolute !important;
    height: 80% !important;
    width: 92% !important;
    background-color: #404952;
    color: #fff;
    border: 1px solid #33C0D1;
    overflow: scroll;
    top: 79px !important;
    display: flex !important;
    justify-content: center;
    transition: all 0.5s;
    overflow-y: scroll !important;
    align-items: flex-start;
}

.editRoleDropDown{
  width: 30%;
  border-radius: 30px;
}

.createUserBtnMobile{
  margin-left: 35%;
}