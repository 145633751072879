.container{
    /* background-color: red; */
    height: 88.5%;
    width:100%
}
.pendingTaskCard {
    min-width: 25%;
    margin-left: 1%;
    background-color: #404952;
    color: white;
    display: flex;
    box-shadow: 2px 6px 8px rgba(0, 0, 0, 3.5);
    flex-direction: column;
    height: 98%;
    margin-bottom: 3%;
}
.eventsCalendar{
    width: 80%;
    margin-left: -10%;
    background-color: #404952;
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
    height: 60%;
}
.newTaskHeader{
    display: flex;
    flex-direction: row;
    height: 20%;
    width: 100%;
}
.newTaskBody{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    align-items: center;
}

.mappedTasks{
    background-color: rgba(84,93,101,1);
    cursor: pointer;
    width: 84%;
    color: white !important;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    margin-bottom: 5%;
}
.creditsColorDate{
    padding-left: 2%;
    width: 90%;
    border-radius: 7px;
}
.creditsColor{
    background-color: rgba(227,54,126,1);
    width: 30%;
    height: 10%;
    margin-left: -60%;
}
.CreditsColorDiv{
    width: 100%;
    height: 5%;
}
.CreditsInfoDiv{
    width: 100%;
    display: flex;
    height: 95%;
    flex-direction: row;
}
.selectDepartmentDiv{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
}
.selectDueDateDiv{
    display: flex;
    width: 50%;
    flex-direction: row;
}
.newTaskDueDate {
    border-radius: 8px;
    width: 65%;
    height: 30px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-left: 10%;
    margin-bottom: 15px;
}


.newTaskName {
    border-radius: 8px;
    width: 40%;
    height: 30px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.selectDepartmentdiv{
    border: 1px solid #404952;
    border-radius: 8px;
    width: 17%;
    margin-left: 1%;
}
.moreInfoAboutDepartment{
    width: 100%;
    overflow-y: scroll;
}
.sendTaskRequestDiv{
    width: 100%;
    display: flex;
    height: 5%;
    flex-direction: row-reverse;
}
.TaskInformationCard{
    width: 100%;
    height: 40%;
}
.moreCollateralInfo{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5%;
    margin-bottom: 2%;
}

.newCategoryTaskInputs{
    border-radius: 8px;
    width: 50%;
    height: 50px;
    padding-left: 5px;
    border: 1px solid #404952;
}

.selectDepartmentBtn{
    margin-bottom: 10px;
    margin-top: 10px;
    width: 55%;
    height: 50px;
    border: 1px solid #404952;
    border-radius: 10px;
}
.descriptionBtn{
    border-radius: 8px;
    width: 99%;
    height: 40px;
    padding-left: 5px;
    border: 1px solid #404952;
}

.addNewTaskName{
    border-radius: 8px;
    width: 99%;
    height: 50px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-bottom: 1%;
}
.sendTAskBtn{
    width: 30%;
    height: 50px;
    color: white;
    background-color: #81d6f1;
    border-radius: 10px;
    border: none;
}
.show-second-div #secondDiv {
    display: block;
  }