.receivedJob{
    width: 90%;
    height: 10%;
}

.receivedJobMobile{
    width: 90%;
    height: 10%;
}
.jobInfoCard{
    position: absolute;
    width: 95%;
    flex-direction: column;
    top: 0px;
    height: 95%;
    justify-content: flex-start !important;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
}
.jobInfoCardMobile{
    position: absolute;
    width: 95%;
    flex-direction: column;
    top: 45px;
    height: 95%;
    justify-content: flex-start !important;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
}
.closeJobInfoCard{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.jobInfoCardMobile{
    position: absolute;
    width: 85%;
    right: 3%;
    flex-direction: column;
    top: 25%;
    height: 65%;
    justify-content: flex-start !important;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
}
.designHomePageCardsMobile{
    display: flex;
    width: 90%;
    border-radius: 25px;
    align-items: center;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
}
.designHomePageMobile{
    display:flex;
    flex-direction: row;
  }

.acceptIcon{
    width: 2vw;
    height: 67%;
    margin-left: 25%;
    margin-top: 1.5%;
}
.jobPreview{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.mappingJobDetails{
    margin-left: 10%;
}
.viewJobDetails{
    display: flex;
    width: 48%;
    height: 50%;
    border-radius: 25px;
    align-items: center;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    margin: 0.3%;
}
.viewJobDetailsMobile{
    display: flex;
    width: 90%;
    height: -26%;
    position: absolute;
    top: 15%;
    border-radius: 25px;
    align-items: center;
    flex-direction: column !important;
    color: rgb(220, 218, 218);
    background-color: rgb(40, 44, 52);
    /* margin: 0.3%; */
    margin-left: 1.3%;
}
.closeJobDetails{
    width: 5%;
    height: 5%;
    margin-left: 90%;
    margin-top: -8%;
}
.Details{
width: 100%;
}
.declineIcon{
    width: 2vw;
    height: 75%;
    margin-top: 1%;
} 
.submitIcon{
    width: 13%;
    margin: 0%;
}
.updateIcon{
    width: 2vw !important;
    height: 67%;
    margin-left: 25%;
    margin-top: 1.5%;
}
.jobCardsMobile{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 1%;
}
.jobCards{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1%;
}
.jobCardsMobile{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 1%;
}
.homeCardH1{
    margin-top: -92%;

}
.topTabsDiv{
    top: 8%;
    display: flex;
    position: absolute;
    width: 50%;
    right: 48%;
}

.jobInfoTitle{
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 5%;
}
.jobInfoCardMenu{
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    height: 10%;
}
.jobInfoCardMenuMobile{
    margin-top: 15px;
    width: 100%;
    display: flex;
    /* justify-content: space-evenly; */
    height: 7%;
}
.jobInfoCardContent{
    height: 100%;
    overflow: scroll;
    width: 50%;
}
.CommentCards{
    width: 100%;
    height: 50%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.CommentCardsMobile{
    width: 95%;
    height: 90%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.CommentCard {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px;
    height: 28%;
    overflow-y: scroll;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .CompanyCommentCard{
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px;
    height: 28%;
    color: black;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .CommentsComps {
    display: flex;
    align-items: center;
    gap: 10px;
  }

.DepartmentsComments{
    width: 50%;
    position: fixed;
    height: 100%;
    margin-left: 44%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.departmentCommentsMobile{
    width: 99%;
    /* position: fixed; */
    height: 100%;
    margin-left: 7%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.jobInfoCardContent select{
    width: 80%;
    border-radius: 15px;
}
.chat-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .chat-card {
    border-radius: 8px;
    padding: 8px 12px;
    margin: 8px;
    max-width: 70%;
    word-wrap: break-word;
  }
  
  .user-chat {
    align-self: flex-end;
    background-color: #dcf8c6;
  }
  
  .other-chat {
    align-self: flex-start;
    background-color: #f3f3f3;
  }