.companySvgCAnvas{
    /* width: 45%; */
    margin-left: 1%;
    background-color: #404952;
    color: white;
    box-shadow: 8px 8px 17px rgba(0, 0, 0, 3.5);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: center;
    padding: 15px;
}

.RangeInputs{
    border-radius: 9px;
    width: 50%;
    height: 34px;
    margin-left: 2%;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
}
.searchButton{
    background-color: #81d6f1;
    color: white;
    border-radius: 6px;
    width: 10%;
    border: none;
    height: 34px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.spiralDiv{
    display: flex;
    flex-wrap: wrap !important;
    overflow: scroll;
    width: 100% !important;
    height: 80% !important;
}