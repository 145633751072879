.UnArchiveHover {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40px !important;

    .archiveTask-triangle {
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid #ffffff;
        z-index: 7;
        top: 30%;
        right: 40%;
    }

    .archive-viewArchivesContainer {
        position: absolute;
        width: 500px;
        top: 60%;
        right: 1%;
        border-radius: 35px;
        height: 40px;
        display: flex;
        padding: 5px;
        background-color: #ffffff;
        z-index: 6;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        .archiveTask{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 600px;
            background-color: #D5922D;
            cursor:pointer;
            .unArchiveValue {
                font-size: 20px;
                font-weight: 300;
                color: #213038;
                line-height: 24.2px;
            }
        }
        .viewArchives{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 98%;
            margin-left: 1%;
            background-color: #D5922D;
            cursor: pointer;
            .unArchiveValue {
                font-size: 20px;
                font-weight: 300;
                color: #213038;
                line-height: 24.2px;
            }
        }
    }

}