.body {
    padding-Top: 35;
    padding-Bottom: 65;
    padding-left:35
  }
  .HeaderText{
    fontSize: 12;
  }
  .ClientHeaderText{
    fontSize: 12;
    textDecoration: underline;
  }
 .HeaderTextRight{
    fontSize :12;
    textAlign :right;
  }
  .SubHeaderText{
    fontSize: 10;
  }
  .logo {
    width :100;
  }
  .viewJobColorCard{
    padding-left: 2%;
    width: 90%;
    border-radius: 7px;
    background-color: rgba(227,54,126,1);
}
  .downloadCard{
    margin-left: 32%;
    width: 51%;
    position: absolute;
    bottom: 13%;
    height: 50%;
    text-shadow: 1px 1px 5px rgb(60, 165, 3);
    margin-top: 20vw !important;
  }
  .ColorCard{
    padding-left: 2%;
    width: 90%;
    height: 2%;
    border-radius: 7px;
    background-color: rgba(227,54,126,1);
}