.StatusChange {
    width: 25%;
    height: 90%;
    display: flex;
    border-radius: 35px;
    background-color: #D5922D;

    .statusTriangle {
        width: 0;
        top: 72%;
        right: 10%;
        height: 0;
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 20px solid #ffffff;
        z-index: 6;
    }

    .selectStatus {
        position: absolute;
        width: 25%;
        border-radius: 35px;
        aspect-ratio: 1 / 1;
        display: flex;
        padding: 5px;
        top: 100%;
        background-color: #ffffff;
        z-index: 5;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        flex-direction: column;

        .statusContainer{
            display: flex;
            align-items: center;
            height: 10%;
            justify-content: center;
            background-color: #EAEAEA;
            cursor: pointer;
            margin-top: 1%;
            .statusValue {
                font-size: 20px;
                font-weight: 300;
                color: #213038;
                line-height: 24.2px;
            }
        }
    }

}