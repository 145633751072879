.customSelect {
    position: relative;
    min-width: 268.22px;
    border: 1px solid #213038;
    height: 45px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 35px;
    flex-direction: column;
    margin-top: 1%;
    .DepartmentLabel{
        display: flex;
        align-items: center;
        color: #213038;
        font-weight: 700;
        font-size: 25px;
    }
    .placeHolder{
        position: absolute;
        left: 45px;
        background-color: #ffffff;
        top: -36px;
        padding-left: 1%;
        padding-right: 1%;
        font-weight: 300;
        font-size: 20px;
        z-index: 10;
        color:#213038;
      }
    select{
        width: 95%;
        margin-left: 4%;
        padding-left: 2px;
        font-weight: 700;
        font-size: 25px;
        background-color: transparent !important;
        border: none;
        margin-top: 1%;
        color: #213038;
        outline: none;
        margin-bottom: 5px;
    }

    .customSelectOptions{
        transition: all 0.5s;
        display: none;
        // display: flex;
        border-radius: 35px;
        flex-direction: column !important;
        background-color: white;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: absolute;
        flex-shrink: 0;
        top: 0px;
        width: 100%;
        opacity: 0;
    }

  }
  .customSelectActive{
    border: none;
    background-color: #ffffff;
    min-width: 268.22px;
    z-index: 20;
    // margin-right: 1%;
    .customSelectOptions{
        transition: all 0.5s;
        z-index: 5;
        border: 1px solid #213038;
        display: flex;
        opacity: 1;
        border-radius: 25px;
        padding-bottom: 15px;
        option{
            width: 80%;
            background-color: #EAEAEA;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-top: 5%;
            height: 50px;
            font-size: 25px;
            font-weight: 700;
            border-radius: 35px;
            padding-left: 30px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .selectActive{
            background-color: #D5922D;
        }
    }
  }