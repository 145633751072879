.background {
    width: 100%;
    height: 99.9vh;
    background-image: url("../../assets/images/rainbow.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.companyDashboardSideNav{
    width: 20%;
    height: 99.9vh;
    /* background-color: blue; */
    background-color: #404952;
}
.companyDashboardBody{
    width : 80%;
    height: 100%;
}
.iconsStyle{
    margin-top: 5%;
    height: 10%;
    margin-left: 5%;
    width: 10%;
}
.tasksCardsInProgress{
    width: 20%;
    color: white;
    background-color: rgba(146,140,195,1);
    /* height: 60%;  */
    margin-left: 2%;
}
.tasksCardsNew{
    width: 10%;
    color: white;
    background-color: rgba(227,54,126,1);
    cursor: pointer;
    margin-left: 2%;
}
.tasksCardsForeCast{
    width: 10%;
    color: white;
    background-color: rgba(239,227,12,1);
    /* height: 60%;  */
    margin-left: 2%;
}
.inProgressImg{
    height: 50%;
    margin-top: 15%;
    width: 55%;
    margin-left: 15%;
}
.Line_1_Class {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.dashPng{
    margin-left: 10%;
    margin-top: -10%;
}

.todopngstyle{
    width: 80%;
    margin-top: 35%;
}
.Combined_Shape {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    color: white;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
.tasksCardsCompleted{
    width: 20%;
    /* height: 60%;  */
    color: white;
    margin-left: 2%;
    background-color: rgba(143,202,114,1);
}
.tasksCardsTodo{
    width: 20%;
    /* height: 60%;  */
    color: white;
    background-color: rgba(49,192,209,1);
    margin-left: 2%;
}
.assets{
    width: 100%;
    height :10%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.companyDashboardTopNav{
    width: 100%;
    height: 10%;
}
.companyDashboardTasks{
    width: 95%;
    margin-left: 2%;
    /* height: 15%; */
    display: flex;
    flex-direction: column;
    color:white ;
    background-color: #404952;
    padding: 1%;
}
.DashBoardBottomDivCards{
    overflow-x: scroll;
    background-color: transparent;
}
.tasks{
    display: flex;
    margin-left: -90%;
    /* height: 30%; */
}
.myCards{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* height: 90%; */
}
.overViewChart{
    width: 60%;
    background-color: #404952;
    padding: 1%;
}
.bottomContent{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.creditsCard{
    width: 40%;
    background-color: #404952;
    padding: 1%;
}

.bottomContentDiv{
    margin-top: 3%;
    display: flex;
    width: 97%;
    margin-left: 1.5%;
}
.financeOverView{
    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: #404952;
}
.monthlyCreditsView{
    width: 39%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    background-color: #404952;
}
.AEXandAlkemiaPie{
    width: 21%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    background-color: #404952;
}
.cardCard{
    background-color: #ffffff;
    width: 2%;
    margin: 4%;
    height: 99%;
}

.svg-container {
    display: flex;
    align-items: center;
}

.summary {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
}

.summary-item {
    display: flex;
    margin-Left: 7%;
    align-items: center;
}

.color-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}
.moreInfoCard{
    margin-top: 10%;
    background-color: rgba(227,54,126,1);
    width: 70%;
    color: #ffffff;
}
.cardName{
    color: white;
}

.metricsCardCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #404952;
}

.mappedMetricsCard{
    display: flex;
    width: 100%;
    height: 10%;
    flex-direction: row;
}

.percentageLineContainer {
    position: relative;
    height: 10px;
    background-color: #ddd;
    margin-top: 10px;
}

.percentageLine {
    height: 100%;
    background-color: orange;
}

.percentageCircle {
    position: absolute;
    top: -5px;
    left: 50%; 
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    background-color: white;
    border: 2px solid #333;
    border-radius: 50%;
}
.mappedMetricsStyle{
    max-width: 65%;
    max-height: 15%;
    margin-left: 1%;
    border-radius: 14px;
    cursor: pointer;
    color: #fff;
}

.graphMetricDiv{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.MetricsOverviewGraph{
    box-sizing: border-box;
    display: block;
    height: 540px;
    width: 1179px;
}
.addActualDataCard{
    display: flex;
    overflow-y: scroll;
    position: absolute;
    background-color: #404952;
    width: 37%;
    left: 25%;
    top: 6%;
    height: 85%;
    color: white;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.requestNewTaskCardsForm{
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    position: absolute;
    top: 10%;
    background-color: #404952;
    width: 38%;
    left: 30%;
    color: white;
    height: 85%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.InputDateElement{
    border-radius: 10px;
    width: 30%;
    height: 45px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.AddDataCardHeaders{
    width: 100%;
    height: 5%;
}
.AddDataCardBody{
    overflow-y: scroll;
    width: 100%;
    height: 95%;
}
.closeAddActualsCard {
    display: flex;
    flex-direction: row-reverse;
    width: 5%;
    margin-left: 93%;
    cursor: pointer;
    position: absolute;
    top: 1%;
}

.headerDivUserInfo{
    width: 90%;
    margin-left: 8%;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1%;
}

.settingsContainer{
    width: 60px;
    height: 60px;
    height: -32%;
    border-radius: 78%;
    background-color: #fff;
  }
  
  .notificationsContainer{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 10px;
  }

  .Shape_cf_Class {
    opacity: 0.18;
    fill: rgba(252,252,252,1);
}

.CompanyIconsLogo{
    display: flex;
    position: relative;
    height: -7%;
    border-radius: 34px;
    margin-left: 1%;
    width: 2cap;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    color: black;
    margin-right: 1%;
}
.CompanyIconsLogoSettings{
    display: flex;
    position: relative;
    height: -7%;
    border-radius: 34px;
    margin-left: 1%;
    width: 3%;
    background-color: #333;
    align-items: center;
    justify-content: center;
    color: black;
}
.inprng{
    accent-color: white;
    width: 100%;
    margin-top: 10%;
}
.coins{
    margin-left: 1%;
    width: 8%;
    height: 0%;
}