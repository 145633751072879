/* Satrt Doc Company */
.heading{
    font-family: 'Arial';
    width: 20%;
}
.descriptionCard{
    background-color: #282C34;
    color: white !important;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: 20px !important;
    width: 97%;
    border-radius: 2px !important;
}
.descriptionCardData{
    margin-right: 20%;
    size: 16px;
}

.headinDiv{
    display: flex;
    flex-direction: column;

}
.tabDiv{
       width: 100%;
       height: 60px;
       display: flex;
       flex-direction: row;
}
.doctypeDiv{
    color: #fff;
    width: 20%;
    display: flex;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #33C0D1;
    margin-bottom: 15px;
    margin-right: 10%;
    cursor: pointer;
    box-shadow: 0 1px 4px 0 #33C0D1;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
}

.mapDocCard{
    background-color: #282C34;
    color: white !important;
    margin-top: 1%;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: 20px !important;
    width: 97%;
    border-radius: 2px !important;
}
.mapCardDocDiv{

}
.downloadIconDiv{
   
}
.downloadIcon{
    color: green;
}
.docHomeDiv{

}
.documetantionDiv{
    
}
.uploadDocIconDiv{
    color: rgb(62, 177, 244);
}
.DocNameIput{
    color: white !important;
    background-color: #282C34;
    border: #282C34;
    width: 55% !important;
}
.DocNameIputDiv{
    width: 99%;
}
.DocNameIput:focus{
    outline: none;
    border: 0px solid #282C34;
}

.DocNameIput::placeholder{
    color:rgba(255, 255, 255, 0.584);   
}
.uploadDocFile{
    display: contents;
}
.inputFile{
    border-radius: 320px;
    width: 18%;
    height: 25px;
    padding-top: 0.4%;
    padding-left: 8px;
    border: 1px solid #404952;
}
.mappedDocName{
    width: 56.5%;
}
.mappedDocType{
width: 45%;
}
.selcetDocType{
    color: white !important;
    background-color: #282C34;
    width: 10% !important;
    outline: none;
    border: 0px solid #282C34 !important;
}
/* End Doc Company */


