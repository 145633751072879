/* .signin {
    width: 100% ;
    height: 100vw ;
    background-image: url("../../assets/images/rainbow.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.signInDetails{
    margin-top: -15%;
    width: 50%;
}
.signInDetailsMobile {
    width: 80%;
}
.loginInputs{
    display: flex;
    margin-top: -10%;
    flex-direction: column;
}
.loginInputsMobile {
    width: 65%;
    display: flex;
    margin-top: -10%;
    flex-direction: column;
}
.signInButtonStyle {
    background-color: #81d6f1;
    width: 100%;
}
.signInInputs{
    border-radius: 78px;
    width: 80%;
    height: 42px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.signInInputsMobile {
    border-radius: 78px;
    width: 100%;
    height: 42px;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.rememberMe{
    display: flex;
} */

/** 
New Design \
*
*
*/
.mainLogin {
  background: #e4e4e4;
  position: relative;
  overflow: hidden;
  height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#topLeft {
  position: absolute;
  height: 700px;
  width: 1000px;
  left: -10%;
  top: -30%;
  border-radius: 50%;
  background: radial-gradient(circle, #d5922d, #d5922d);
  filter: blur(130px);
}
#topRight {
  position: absolute;
  right: -5%;
  top: 5%;
  height: 530px;
  width: 730px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #7a909e, #7a909e);
  filter: blur(120px);
}
#bottomRight {
  position: absolute;
  height: 180px;
  width: 320px;
  right: 3%;
  bottom: -10%;
  border-radius: 50%;
  background: radial-gradient(circle, #d5922d, #d5922d);
  filter: blur(50px);
}
#bottomLeft {
  position: absolute;
  height: 200px;
  width: 800px;
  bottom: 0%;
  left: 2%;
  border-radius: 50%;
  background: radial-gradient(circle, #7a909e, #7a909e);
  filter: blur(140px);
}
/* #down{
  position: absolute;
  right: 0;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #7A909E, #7A909E);
  filter: blur(80px);
  animation: up 30s infinite;
}
#left{
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background-image: linear-gradient(80deg, rgb(5, 124, 172), rgba(183, 253, 52, 0.8));
  filter: blur(80px);
  animation: left 30s 1s infinite;
}
#right{
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: radial-gradient(circle, #D5922D, #D5922D);
  filter: blur(80px);
  animation: right 40s .5s infinite;
} */
/* @keyframes animateBackground {
  0% {
    background-position: 100 100;
  }
  100% {
    background-position: 10% 10%;
  }
} */
.DashImage {
  width: 29%;
  margin: 2%;
  backdrop-filter: blur(5px);
}
.LoginContainer {
  border-radius: 50px;
  width: 35%;
  height:55%;
  padding-bottom: 1.4%;
  background-color: #f0f0f0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  text-align: center;
}
.LoginContainer h2 {
  
  margin-left: 23%;
  margin-right:23%;
  color: black;
  

}
.LoginContainer p {
  font-size: 13px;
  align-self: flex-end;
  margin-right: 13%;
  margin-top: 0%;
  margin-bottom: 7%;
  color: black;
}
.EmailDiv {
  height: 35px;
  width: 85%;
  border-radius: 20px;
  border: 1px solid #213038;
  margin-bottom: 6%;
}
.PasswordDiv {
  height: 35px;
  width: 85%;
  border-radius: 20px;
  border: 1px solid #213038;
}
.Loginbtn {
  width: 40%;
  border: none;
  height: 12%;
  border-radius: 35px;
  position: relative;
  background-color: #d4912d;
  color: white;
  text-align: center;
  font-size: 20px;
  bottom: 3%;
  font-weight: bold;
}


/*Kenny LoginPage

*/
.LoginCredentialsCard{
  width: 60vh;
  height: 55%;
  display: flex;
  flex-direction: column;
  border-radius: 85px;
}

.SignInBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 35px;
  height: 12%;
  box-shadow: unset;
  background-color: #D4912D;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.signInBtnValue{
  font-size: 35px;
  font-weight: 700;
  line-height: 42.36px;
  color: #ffffff;
  box-shadow: unset;
}
.welcomeMessageContainer{
  background-color: transparent !important;
  width: 100%;
  display: flex;
  height: 30%;
  color: white;
  flex-direction: column;
  align-items: center;
  border-radius: 35px;
  justify-content: flex-start;
}
.welcoomMessage{
  font-size: 35px;
  font-weight: 700;
  display: block;
  color: #213038;
}
.newLine {
  white-space: nowrap;
  display: block;
}
.inputsContainer{
  background-color: transparent;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: 1%;
}
.emailPasswordInputs{
  width: 90%;
    display: flex;
    align-items: center;
}