.allCompaniesCard{
    width: 90%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    color: white;
    background-color: #404952;
    padding: 1%;
}
.companiesAddActualsCard{
    width: 15%;
    color: white;
    background-color: rgb(227, 54, 126);
    margin-left: 55%;
}
.addNewCompanyInputs{
    border-radius: 10px;
    width: 50%;
    height: 30px;
    margin-left: 2%;
    padding-left: 5px;
    border: 1px solid #404952;
    margin-top: 15px;
    margin-bottom: 15px;
}
.dividerCard{
    background-color: #ffffff;
    width: 2%;
    margin: 4%;
    height: 99%;
}
.bottomMappedCompanies {
    margin-top: 3%;
    display: flex;
    width: 97%;
    margin-left: 1.5%;
    flex-direction: column;
}
.companyCardsWithConfig {
    width: 94%;
    margin-left: 1%;
    background-color: #404952;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1%;
    border-radius: 9px;
}
.companyConfigButtons{
    display: flex;
    position: relative;
    background-color: rgb(255, 223, 0);
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    width: 7%;
    justify-content: center;
    padding: 10px;
    color: white;
}
.configurationsCard {
    display: flex;
    overflow-y: scroll;
    margin-top: -35%;
    background-color: #404952;
    width: 65%;
    height: 80%;
    color: white;
    margin-left: 7%;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}
.saveConfigurationsButton{
    background-color: #81d6f1;
    color: white;
    border-radius: 5px;
    width: 15%;
    margin-left: 85%;
    border: none;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}
.companiesConfigurationTab{
    display: flex;
    overflow-y: scroll;
    background-color: #404952;
    position: absolute;
    width: 40%;
    top: 10%;
    left: 30%;
    height: 80%;
    color: white;
    border-radius: 9px;
    box-shadow: 12px 14px 17px rgba(0, 0, 0, 3.5);
    flex-direction: column;
}